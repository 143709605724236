import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import useAuth from '@/composables/useAuth';
import { captureException } from '@/errors';
import urls from '@/urls';

import { initStore } from './store-utils';

export const useFeatureFlagStore = defineStore('FeatureFlags', () => {
  type FeatureFlag = {
    name: string;
    variation?: string | boolean;
  };

  const featureFlags = ref<FeatureFlag[]>([]);

  const { currentOrg, currentUser } = useAuth();

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    if (!currentUser.value.id || !currentOrg.value.id) return;

    try {
      const { data: response } = await axios.get(urls.users.featureFlags);
      if (response) {
        const flags: FeatureFlag[] = response.items.map(
          (flag: FeatureFlag) => ({
            name: flag.name,
            variation: flag.variation || true,
          }),
        );
        setFlags({ flags });
      }
    } catch (err) {
      captureException(err);
    }
  });

  refresh({ useCache: true });

  function hasFeatureFlag(flag: string) {
    return featureFlags.value.some((o) => o.name === flag);
  }

  function getCoupons(type: string) {
    return featureFlags.value.filter((flag) =>
      flag.name.startsWith(`_xb_coupon_${type}`),
    );
  }

  // helpers
  function getUniqueObjectsFromArray(
    flags1: FeatureFlag[],
    flags2: FeatureFlag[],
  ) {
    const allFlags = [...flags1, ...flags2];
    const uniqueFlagMap = allFlags.reduce(
      (map: Record<string, FeatureFlag>, flag: FeatureFlag) => {
        map[flag.name] = flag;
        return map;
      },
      {},
    );

    return Object.values(uniqueFlagMap);
  }

  function setFlags({
    flags,
    override,
  }: {
    flags: FeatureFlag[];
    override?: boolean;
  }) {
    featureFlags.value = override
      ? getUniqueObjectsFromArray(featureFlags.value, flags)
      : getUniqueObjectsFromArray(flags, featureFlags.value);
  }

  return {
    error,
    getCoupons,
    ready,
    readySync,
    running,
    refreshFeatureFlagStore: refresh,
    featureFlags,
    hasFeatureFlag,
    setFlags,
  };
});
