<template>
  <div class="c-general-tab">
    <BittsCard :no-padding="true" class="px-24 py-0">
      <div class="c-general-tab__section">
        <h3> Integration Status </h3>
        <div
          :class="[feedStatusTyped.statusBgColor, feedStatusTyped.textColor]"
          class="c-general-tab__status"
        >
          {{ feedStatusTyped.text }}
        </div>
        <BittsAlert
          v-if="feedHasErrorMessage"
          :message="feed?.error_message || ''"
          color="error"
          class="mt-8 mx-24"
        />
      </div>
      <div class="c-general-tab__section">
        <h3>Sync Data from {{ feed?.integration?.friendly_name }}</h3>
        <BittsSwitch
          v-model="isSyncing"
          :disabled="!canManageDataSources"
          data-testid="c-datasource-settings-source-toggle"
        />
      </div>
      <div class="c-general-tab__section">
        <h3>Update Frequency</h3>
        <BittsSelect
          v-if="canManageDataSources"
          v-model="currentFrequency"
          :options="FREQUENCIES"
          :disabled="feed?.integration?.type === SNOWFLAKE_DATA_SOURCE_TYPE"
          :dropdown-match-select-width="false"
          :searchable="false"
          class="c-datasource-frequency-selector"
        />
        <div v-else>{{ frequencyFriendlyName }}</div>
      </div>
      <div class="c-general-tab__section gap-8">
        <div class="reauthorization-content">
          <div>
            <h3 class="mb-4">Connected</h3>
            <div class="reauthorization-content__text">
              <span>
                <span v-if="feedConnectedAt">
                  {{ feedConnectedAt }}
                </span>
                <span v-if="feedConnectedAt && connectorName">
                  by {{ connectorName }}
                </span>
              </span>
              <span v-if="feedConnectedAt && authorizingUser">
                {{ authorizingUser }}
              </span>
            </div>
          </div>
          <BittsButton
            :disabled="!canManageDataSources"
            text="Reconnect with another user"
            variant="outline"
            size="small"
            data-testid="c-datasource-settings-reauthorize"
            @click="handleReauthorize"
          />
        </div>
      </div>
    </BittsCard>
  </div>
</template>

<script setup lang="ts">
import {
  BittsAlert,
  BittsButton,
  BittsCard,
  BittsSelect,
  BittsSwitch,
} from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { ComputedRef, computed } from 'vue';
import { useRoute } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useDataSourceSettings from '@/composables/useDataSourceSettings';
import useFeedDetails from '@/composables/useFeedDetails';
import { SNOWFLAKE_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import { useTeamStore } from '@/stores';

const route = useRoute();
const teamStore = useTeamStore();

const { feed, currentFrequency, isSyncing, handleReauthorize } =
  useDataSourceSettings();

const feedId = Number(route.params.id);

const { hasPermission } = useAuth();
const canManageDataSources = computed(() =>
  hasPermission('manage:data-sources'),
);

// TODO: Convert useFeedDetails to Typescript
const { feedStatus, feedHasErrorMessage } = useFeedDetails({ feedId });
const feedStatusTyped = feedStatus as ComputedRef<{
  statusBgColor: string;
  textColor: string;
  text: string;
}>;

/* Change frequency */
const FREQUENCIES = [
  { label: '6 Hours', value: 360 },
  { label: '12 Hours', value: 720 },
  { label: '24 Hours', value: 1440 },
  { label: '1 Week', value: 10080 },
];

const frequencyFriendlyName = computed(
  () =>
    FREQUENCIES.find((freq) => freq.value === currentFrequency.value)?.label,
);

const feedConnectedAt = computed(() => {
  if (feed.value?.connected_at) {
    const time = DateTime.fromISO(feed.value?.connected_at);
    return `Connected on ${time.toFormat('M/d/yyyy')} at ${time.toFormat('h:m a')}`;
  }
  return null;
});

const connectorName = computed(() => {
  const connector = teamStore.authorizations.filter(
    (authorization) => authorization.user.email === feed.value?.connected_by,
  )[0];
  return connector
    ? `${connector.user.first_name} ${connector.user.last_name}`
    : feed.value?.connected_by;
});

const authorizingUser = computed(() => feed.value?.external_user_email);
</script>
<style lang="pcss" scoped>
.c-general-tab {
  .c-general-tab__status {
    @apply text-sm rounded-bts-sm flex items-center gap-4;
  }

  .c-datasource-frequency-selector {
    width: 200px;
  }

  .reauthorization-content {
    @apply flex justify-between w-full flex-col items-start gap-16 md:flex-row md:items-center;
    .reauthorization-content__text {
      @apply text-sm text-neutral-text flex flex-col;
    }
  }

  .c-general-tab__section {
    @apply flex justify-between items-center py-24;

    h3 {
      @apply font-bold text-neutral-text-strong text-base normal-case tracking-normal;
    }

    &:not(:last-of-type) {
      @apply border-b border-neutral-border;
    }
  }
}
</style>
