<template>
  <div class="business-definitions">
    <div class="fake-single-select">{{ title }}</div>
    <BittsSelect model-value="is" :disabled="true" class="middle-select" />
    <component
      class="right-hand-side-select"
      :is="isMultiSelect ? BittsMultiselect : BittsSelect"
      v-model="selectedOperands"
      :placeholder="`Select ${isMultiSelect ? 'values' : 'value'}`"
      :searchable="isMultiSelect"
      :mode="isMultiSelect ? 'multiple' : null"
      :disabled="operandOptions.length === 0"
      :options="operandOptions"
      :allow-clear="isMultiSelect"
    />
  </div>
</template>
<script setup lang="ts">
import { BittsMultiselect, BittsSelect } from '@crossbeam/bitts';

const { isMultiSelect = true, operandOptions } = defineProps<{
  isMultiSelect: boolean;
  operandOptions: { label: string; value: string }[];
  title: string;
}>();

const selectedOperands = defineModel<string[] | 'true' | 'false'>();
</script>

<style scoped lang="pcss">
.business-definitions {
  @apply flex;
}

.fake-single-select {
  @apply text-neutral-text px-12 py-8 border border-neutral-border 
  rounded-tl-lg rounded-bl-lg shadow-component border-r-0 cursor-default 
  text-base flex items-center w-[155px] shrink-0;
}

.right-hand-side-select {
  @apply min-w-300;
}

:deep(.bitts-multiselect) {
  &.right-hand-side-select .ant-select-selector {
    @apply !rounded-l-none !border-l-0;
  }
}

/* right select when in single select mode (BittsSelect) */
:deep(.bitts-select.right-hand-side-select .ant-select-selector) {
  @apply !rounded-l-none !border-l-0;
}

/* middle select "operator" (BittsSelect) */
:deep(.bitts-select.middle-select) {
  .ant-select {
    @apply !h-full;
  }

  .ant-select-single .ant-select-selector {
    @apply !h-full !rounded-none !border-l-0;
  }
}
</style>
