<template>
  <div class="export-limit-callout">
    <BittsCallout
      :icon="['fad', 'file-export']"
      :title="title"
      :subtitle="subtitle"
      :type="calloutType"
      size="x-small"
    >
      <template #right-item>
        <BittsButton
          class="bg-transparent ml-auto"
          @click="isRecordExportModalVisible = !isRecordExportModalVisible"
          type="primary"
          variant="outline"
          size="x-small"
          text="Learn more"
        />
        <BittsButton
          v-if="calloutType !== 'neutral' ? 'Contact us' : ''"
          class="px-8 my-2 ml-8"
          size="x-small"
          text="Contact us"
          @click="onAlertClicked"
          data-testid="contact-us-button"
        />
      </template>
    </BittsCallout>
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsCallout } from '@crossbeam/bitts';
import {
  EVENT_SITES,
  INTEGRATIONS_CTAS,
  PLAN_AND_BILLING_CTAS,
} from '@crossbeam/itly';

import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useBilling from '@/composables/useBilling';
import useRecordExportLimits from '@/composables/useRecordExportLimits';

const { exportLimitStatus, limitUsedPercent } = useRecordExportLimits();
const { handleBillingInteraction } = useBilling();
const route = useRoute();

const isRecordExportModalVisible = defineModel<boolean>();

const title = computed(
  () => `You’ve used ${limitUsedPercent.value} of your record export limit`,
);
const statusMessages = {
  'over-90': {
    subtitle: {
      '/integrations':
        'Once the limit is hit integrations using record exports will be paused, contact our team to discuss options',
      '/billing':
        'Once the limit is hit integrations using record exports will be paused and you will not be able to export reports, contact our team to discuss options',
    },
    calloutType: 'warning' as const,
  },
  'limit-reached': {
    subtitle: {
      '/integrations':
        'Integrations using record exports have been paused, contact our team to discuss options',
      '/billing':
        'Integrations using record exports have been paused and you can no longer export reports, contact our team to discuss options',
    },
    calloutType: 'danger' as const,
  },
};

const subtitle = computed(() => {
  const { path } = route;
  if (path !== '/integrations' && path !== '/billing') {
    return '';
  }
  return exportLimitStatus.value && exportLimitStatus.value !== 'over-75'
    ? statusMessages[exportLimitStatus.value]?.subtitle[path]
    : '';
});
const calloutType = computed(() =>
  exportLimitStatus.value && exportLimitStatus.value !== 'over-75'
    ? statusMessages[exportLimitStatus.value]?.calloutType
    : 'neutral',
);

const onAlertClicked = () => {
  const cta =
    route.name === 'integrations'
      ? INTEGRATIONS_CTAS.RECORD_EXPORT_LIMIT
      : PLAN_AND_BILLING_CTAS.RECORD_EXPORT_LIMIT;
  handleBillingInteraction({
    cta,
    talkToSalesReason: 'Record Export limit',
    event_site: EVENT_SITES.EXPORT_LIMIT_CALLOUT,
  });
};
</script>
