import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { BILLING_VERSION } from '@/constants/billing';
import {
  DISABLE_SELF_SERVE_DEBOOKING,
  OVER_SEAT_QUOTA_RESTRICTED,
  TEMP_REBUILT_DEBOOKING,
  TEMP_TEST_DEBOOKING,
} from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePartnersStore,
} from '@/stores';

export default function useHasFeature() {
  const billingStore = useBillingStore();
  const featureFlagStore = useFeatureFlagStore();
  const partnersStore = usePartnersStore();

  const {
    isEnterpriseTier,
    isConnectorTier,
    offlinePartnersQuota,
    inRenewalPeriod,
    billingVersion,
  } = storeToRefs(billingStore);
  const { partnerOrgs } = storeToRefs(partnersStore);

  const canGoOverSeatQuota = computed(() => {
    return (
      isEnterpriseTier.value &&
      !featureFlagStore.hasFeatureFlag(OVER_SEAT_QUOTA_RESTRICTED)
    );
  });

  const hasPotentialRevenue = computed(
    () => isEnterpriseTier.value || isConnectorTier.value,
  );

  const canAddOfflinePartner = computed(() => {
    const offlinePartnerCount = partnerOrgs.value.filter(
      (p) => p.offline_partner,
    ).length;
    return offlinePartnerCount < offlinePartnersQuota.value;
  });

  const onLatestBilling = computed(
    () => BILLING_VERSION === billingVersion.value,
  );

  /* To see the debooking flow, you have to have the temp_rebuilt_debooking flag,
   * and either be in your renewal period, or have the test flag enabled. The
   * temp_rebuilt_debooking and temp_test_debooking flags are only for testing
   * and will be removed after launch. */
  const debookingEnabled = computed(() => {
    if (featureFlagStore.hasFeatureFlag(DISABLE_SELF_SERVE_DEBOOKING))
      return false;
    return (
      featureFlagStore.hasFeatureFlag(TEMP_TEST_DEBOOKING) ||
      (inRenewalPeriod.value &&
        featureFlagStore.hasFeatureFlag(TEMP_REBUILT_DEBOOKING))
    );
  });

  return {
    onLatestBilling,
    hasPotentialRevenue,
    canGoOverSeatQuota,
    canAddOfflinePartner,
    debookingEnabled,
  };
}
