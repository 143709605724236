import axios from 'axios';
import { groupBy, sortBy, uniqBy } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { initStore } from '@/stores/store-utils';
import { Permission } from '@/types/permissions';
import urls from '@/urls';
import { indexBy } from '@/utils';

type Resource = {
  name: string;
  displayName: string;
};

type PermissionLookupByResource = Record<string, Permission[]>;

type PermissionLookupById = Record<string, Permission>;

export const usePermissionsStore = defineStore('PermissionsStore', () => {
  const allResources = ref<Resource[]>([]);
  const defaultPermissions = ref<Permission[]>([]);
  const permissionGroups = ref<PermissionLookupByResource>({});
  const permissionDetails = ref<PermissionLookupById>({});

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data } = await axios.get(urls.all_permissions);
    const permissions = data.items as Permission[];

    allResources.value = uniqBy(
      sortBy(permissions, 'resource_rank'),
      'resource',
    ).map((p) => ({ name: p.resource, displayName: p.resource_display_name }));
    permissionDetails.value = indexBy(permissions, 'id');
    permissionGroups.value = groupBy(permissions, 'resource');
    defaultPermissions.value = permissions
      .filter((p) => p.is_default)
      .map((p) => ({ ...p, is_default: false }));
  });

  refresh({ useCache: true });

  return {
    error,
    ready,
    readySync,
    refreshPermissionsStore: refresh,
    running,
    permissionDetails,
    allResources,
    permissionGroups,
    defaultPermissions,
  };
});
