import type { GetApiResponseByUrl } from '@crossbeam/openapi';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';

import { crossbeamApi } from '@/api';
import {
  ATTRIBUTION_FILTER_QUERY_PARAMS,
  ATTRIBUTION_METRIC_TYPES,
} from '@/constants/attribution';
import { ATTRIBUTION_ACCESS_OVERRIDE } from '@/constants/feature_flags';
import { BASE_ROUTES } from '@/constants/routes';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import type {
  AttributionPageQuery,
  EcosystemInsightsPageQuery,
  SaveAttributionsPayload,
} from '@/types/attribution';

import MetricsModule from './Attribution/metrics';
import Opportunities from './Attribution/opportunities';
import { useAttributionState } from './Attribution/state';
import {
  buildCurrentQuarterDateRangeQuery,
  dismissRecommendedAttribution as dismissRecommendedAttributionApi,
  saveAttributions as saveAttributionsApi,
} from './Attribution/utils';
import { useBillingStore } from './BillingStore';
import { useFeatureFlagStore } from './FeatureFlagStore';
import { useFlashesStore } from './FlashesStore';
import { useRootStore } from './RootStore';
import { useSourcesStore } from './SourcesStore';

const { IS_CLOSED, IS_WON, SORT, ORDER, PAGE, LIMIT } =
  ATTRIBUTION_FILTER_QUERY_PARAMS;

export type AttributionRoute = Partial<RouteLocationNormalizedLoaded>;

type ActivityTimelineResponse =
  GetApiResponseByUrl<'/v0.1/activity-timeline/{source_id}/{master_id}'>;
type ActivityTimelineItem = ActivityTimelineResponse['items'][0];

export const useAttributionStore = defineStore('AttributionStore', () => {
  const accountActivities = ref<ActivityTimelineItem[]>([]);
  const {
    $reset,
    isCached,
    opportunities,
    pagination,
    rollupMetrics,
    unattributedOpportunities,
    eiAttributionMetrics,
  } = useAttributionState();

  const featureFlagStore = useFeatureFlagStore();
  const sourcesStore = useSourcesStore();
  const rootStore = useRootStore();

  const hasAttributionAccess = computed(() => {
    const { isEnterpriseTier } = useBillingStore();

    return (
      isEnterpriseTier ||
      featureFlagStore.hasFeatureFlag(ATTRIBUTION_ACCESS_OVERRIDE)
    );
  });

  const {
    TOTAL_INFLUENCED,
    TOTAL_SOURCED,
    TOTAL_ATTRIBUTED,
    TOTAL_UNATTRIBUTED,
    TOTAL_ATTRIBUTED_COUNT,
    TOTAL_UNATTRIBUTED_COUNT,
  } = ATTRIBUTION_METRIC_TYPES;

  function initAttributionStore(
    route: AttributionRoute,
    { useCache = false } = {},
  ) {
    if (!route.name) {
      throw new Error('route is required to initialize AttributionStore');
    }

    if (useCache) return;

    isCached.value = true;
    const baseRoute = route.matched?.at(1)?.name;

    const { query = {} as AttributionPageQuery } = route;
    const { sort, order, page } = query;

    query[SORT] = sort ?? 'close_date';
    query[ORDER] = order ?? 'desc';
    query[LIMIT] = 20;
    query[PAGE] = page ?? 1;

    switch (baseRoute) {
      case BASE_ROUTES.ATTRIBUTION:
        return refreshAttributionStore(query as AttributionPageQuery, {
          refreshUnattributed: true,
        });
      case BASE_ROUTES.ECOSYSTEM_INSIGHTS:
        return refreshEcosystemDashboard(query as EcosystemInsightsPageQuery);
    }
  }

  async function refreshAttributionStore(
    query: AttributionPageQuery,
    { refreshUnattributed = false, refreshMetrics = true } = {},
  ) {
    const settingsFromLS = ls.attributionSettings.get();
    try {
      if (hasAttributionAccess.value) {
        const promises = [Opportunities.refreshOpportunities(query)];

        if (refreshMetrics) {
          promises.push(MetricsModule.refreshMetrics(query, settingsFromLS));
        }
        if (refreshUnattributed) {
          promises.push(Opportunities.fetchUnattributedOpportunities());
        }
        await Promise.all(promises);
      }
    } catch (err) {
      isCached.value = false;
      captureException(err);
    }
  }

  async function refreshEcosystemDashboard(
    query: EcosystemInsightsPageQuery,
    { refreshMetrics = true } = {},
  ) {
    // add quarter date range to both metrics and oppies
    const quarterRangeQuery = buildCurrentQuarterDateRangeQuery(
      rootStore.quarterLookup,
    );

    const queryWithQuarterRange = {
      ...query,
      ...quarterRangeQuery,
    };

    try {
      if (hasAttributionAccess.value) {
        const promises = [
          Opportunities.refreshClosedWonOppies(queryWithQuarterRange),
        ];

        if (refreshMetrics) {
          promises.push(
            MetricsModule.fetchEiAttributionMetrics({
              ...queryWithQuarterRange,
              [TOTAL_UNATTRIBUTED]: true,
              [TOTAL_SOURCED]: true,
              [TOTAL_INFLUENCED]: true,
              [TOTAL_ATTRIBUTED]: true,
              [TOTAL_UNATTRIBUTED_COUNT]: true,
              [TOTAL_ATTRIBUTED_COUNT]: true,
              [IS_CLOSED]: true,
              [IS_WON]: true,
            }),
          );
        }

        await Promise.all(promises);
      }
    } catch (err) {
      isCached.value = false;
      captureException(err);
    }
  }

  type TempDeal = {
    record_id: string;
    feed_id: string;
  };
  async function fetchTimelineEvents(deal: TempDeal) {
    await sourcesStore.readySync;
    const { record_id: recordId, feed_id: feedId } = deal;

    const sources = sourcesStore.getSourcesByMdmType('account');
    const accountSource = sources
      .filter((source) => source.feed_id === Number(feedId))
      .at(0);

    if (accountSource?.id) {
      const { data } = await crossbeamApi.GET(
        '/v0.1/activity-timeline/{source_id}/{master_id}',
        {
          params: {
            query: {
              limit: 1000,
              page: 1,
            },
            path: {
              source_id: accountSource?.id,
              master_id: recordId,
            },
          },
        },
      );
      if (data) accountActivities.value = data.items;
    }
  }

  async function saveAttributions({
    payload,
    masterUUID,
    opportunityID,
  }: {
    payload: SaveAttributionsPayload;
    masterUUID: string;
    opportunityID: string;
  }): Promise<void> {
    const promises = [
      saveAttributionsApi({ payload, masterUUID, opportunityID }),
      dismissRecommendedAttribution({ masterUUID, opportunityID }, false),
    ];
    await Promise.all(promises);
  }

  async function dismissRecommendedAttribution(
    {
      masterUUID,
      opportunityID,
    }: { masterUUID: string; opportunityID: string },
    showToast = true,
  ): Promise<void> {
    const flashesStore = useFlashesStore();
    try {
      await dismissRecommendedAttributionApi({ masterUUID, opportunityID });
      const oppInState =
        Opportunities.getOpportunityInCurrentStateByID(opportunityID);
      if (oppInState) oppInState.is_dismissed = true;
      unattributedOpportunities.value = unattributedOpportunities.value.filter(
        (oppy) => {
          return (
            oppy.record_master_uuid !== masterUUID && oppy.id !== opportunityID
          );
        },
      );
      if (showToast) {
        flashesStore.addSuccessFlash({
          message: 'Attribution dismissed',
        });
      }
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Something went wrong',
        description: 'Attribution was not dismissed',
      });
      throw err;
    }
  }

  return {
    $reset,
    initAttributionStore,
    refreshAttributionStore,
    refreshEcosystemDashboard,
    saveAttributions,
    fetchTimelineEvents,
    dismissRecommendedAttribution,

    // OPPORTUNITIES
    fetchOpportunity: Opportunities.fetchOpportunity,
    fetchOpportunityOverlaps: Opportunities.fetchOpportunityOverlaps,
    fetchEiAttributionMetrics: MetricsModule.fetchEiAttributionMetrics,

    // METRICS
    getUpdatedMetrics: MetricsModule.getUpdatedMetrics,
    refreshMetrics: MetricsModule.refreshMetrics,

    // STATE
    isCached,
    opportunities,
    pagination,
    unattributedOpportunities,
    accountActivities,
    rollupMetrics,
    hasAttributionAccess,
    eiAttributionMetrics,
  };
});
