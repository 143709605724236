<template>
  <div>
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="title"
      variant="sidebar"
      :loading="loading"
      :use-mask-to-close="true"
      :hide-footer="hideFooter"
      @closed="close"
      @action="handleAction"
      :disabled="saving || loading"
      :action-loading="saving"
    >
      <template #sidebar-header-prefix>
        <BittsSvg
          v-if="feed?.integration"
          class="w-24 h-24 mr-16"
          :svg="feed.integration.type + 'Icon'"
        />
      </template>
      <template #sidebar-footer>
        <BittsButton
          :disabled="saving"
          type="danger"
          text="Remove data source"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-datasource-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <ModalSidebarLink
          route-name="data-sources-settings-new"
          v-for="(item, i) in ModalSidebarItems"
          :item="item"
          :key="item.tab"
          :class="{
            'mt-auto': i === ModalSidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-datasource-settings-modal__content">
        <component :is="TabComponent" class="min-h-[600px]" />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo, BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DataSourceSettingsTabType } from '@/components/data-sources/SettingsModal/constants';
import DataImportTab from '@/components/data-sources/SettingsModal/DataImportTab.vue';
import GeneralTab from '@/components/data-sources/SettingsModal/GeneralTab.vue';
import PopulationsTab from '@/components/data-sources/SettingsModal/PopulationsTab/components/PopulationsTab.vue';
import SharingTab from '@/components/data-sources/SettingsModal/SharingTab.vue';
import ModalSidebarLink, {
  ModalSidebarItem,
} from '@/components/ModalSidebarLink.vue';

import useDataSourceSettings from '@/composables/useDataSourceSettings';

const router = useRouter();
const { handlePatchFeed, loading, saving, feed } = useDataSourceSettings();

const ModalSidebarItems = computed<
  ModalSidebarItem<DataSourceSettingsTabType>[]
>(() => [
  {
    label: 'General',
    icon: ['far', 'gear'],
    tab: DataSourceSettingsTabType.GENERAL,
  },
  {
    label: 'Populations',
    icon: ['far', 'database'],
    tab: DataSourceSettingsTabType.POPULATIONS,
  },
  {
    label: 'Sharing',
    icon: ['far', 'arrow-right-arrow-left'],
    tab: DataSourceSettingsTabType.SHARING,
  },
  {
    label: 'Data Import',
    icon: ['far', 'upload'],
    tab: DataSourceSettingsTabType.DATA_IMPORT,
  },
]);

const route = useRoute();
const tab = computed(() => route.params.tab as DataSourceSettingsTabType);

const TabComponent = computed(() => {
  switch (tab.value) {
    case 'general':
      return GeneralTab;
    case 'populations':
      return PopulationsTab;
    case 'sharing':
      return SharingTab;
    case 'data-import':
      return DataImportTab;
    default:
      return GeneralTab;
  }
});

const title = computed(() => {
  switch (tab.value) {
    case 'general':
      return 'General Settings';
    case 'populations':
      return 'Create your Populations';
    case 'sharing':
      return 'Sharing Settings';
    case 'data-import':
      return 'Data Import';
    default:
      return 'General Settings';
  }
});

const hideFooter = computed(() => {
  switch (tab.value) {
    case 'data-import':
      return true;
    default:
      return false;
  }
});

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'general':
      await handlePatchFeed();
      break;
    default:
      await handlePatchFeed();
  }
}

async function close() {
  await router.push({ name: 'data-sources' });
}

async function showDeleteModal() {
  await router.push({ name: 'delete-data-source' });
}
</script>

<style lang="pcss" scoped>
.c-datasource-settings-modal__content {
  @apply flex flex-col py-24 px-8;
}
</style>
