<template>
  <component
    :is="tooltipOrDiv"
    v-bind="tooltipProps"
    :style="fullComponentWidth"
    :class="[
      {
        'bitts-avatar-stack__transparent': transparent,
      },
      `bitts-avatar-stack-${size}`,
    ]"
  >
    <div class="bitts-avatar-stack__org-list h-full">
      <div class="flex items-center">
        <BittsAvatar
          v-for="(org, index) in orgListTruncated"
          :key="`avatar-stack-org__${org?.id || index}`"
          :avatar-classes="allAvatarClasses"
          :org="org"
          :alt-text-from-parent="org.altTextFromParent"
          :image-source="org.imageSource"
          :custom-svg="org.customSvg"
          :show-initials="org.showInitials"
          :style="getStyleForAvatar(index)"
          :size="size"
          shape="square"
          class="bitts-avatar-stack__org-list-org"
        />
      </div>
      <div
        v-if="numberOfOverflowOrgs"
        :style="styleForOverflowNumber"
        class="c-bitts-avatar-stack__overflow-number"
      >
        {{ `+${numberOfOverflowOrgs}` }}
      </div>
    </div>
    <template #title>
      <div class="bitts-avatar-stack__tooltip">
        <div
          v-for="org in orgList"
          :key="`avatar-stack-tooltip__${org.id}`"
          class="bitts-avatar-stack__tooltip-orgs"
        >
          <BittsAvatar
            :org="org"
            :avatar-classes="tooltipAvatarClasses"
            :alt-text-from-parent="org.altTextFromParent"
            :image-source="org.imageSource"
            :show-initials="org.showInitials"
            size="x-small"
            shape="square"
            class="mr-8"
          />
          {{ org.name }}
        </div>
      </div>
    </template>
  </component>
</template>

<script lang="ts" setup>
import { StrictExclude } from '@crossbeam/types';

import { computed } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';
import type { Props as BittsTooltipProps } from '../BittsTooltip/BittsTooltip.vue';
import { BittsSize } from '../types';

interface Org {
  id?: string | number;
  name?: string;
  altTextFromParent?: string;
  imageSource?: string;
  customSvg?: string;
  showInitials?: boolean;
}

export interface Props {
  avatarClasses?: string;
  includeHover?: boolean;
  orgList?: Org[];
  size?: StrictExclude<BittsSize, 'x-small' | 'x-large' | 'tiny'>;
  tooltipAvatarClasses?: string;
  transparent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  avatarClasses: '',
  includeHover: false,
  /* according to spec, this will only ever be orgs
   this is coded such that the individual props for each avatar is coded within each org
   so, a potential org would look like:
   {
       ...org,
       showInitials: {{ is not a record }},
       altTextForParent: {{ is there nonstandard alt text we want }},
       ...etc
   }
  besides avatarClasses, because we want those to be uniform */
  orgList: () => [],
  size: 'small',
  tooltipAvatarClasses: '',
  transparent: true,
});

const MAX_LENGTH_FOR_TRUNCATED_ORGLIST = 5;

const ORGLIST_COUNT_TO_LIST_MAP = {
  // width of the stack itself
  small: {
    maxWidth: 104,
    base: 24,
  },
  medium: {
    maxWidth: 138,
    base: 34,
  },
  large: {
    maxWidth: 272,
    base: 68,
  },
};

const OVERLAPPING_ORG_WIDTH = {
  small: 14,
  medium: 20,
  large: 40,
};

const SINGLE_DIGIT_LENGTH = 8;

const allAvatarClasses = computed(() => {
  return `${props.avatarClasses} bitts-avatar-stack__outline-class`;
});

const numberOfOverflowOrgs = computed(() => {
  if (props.orgList.length > MAX_LENGTH_FOR_TRUNCATED_ORGLIST) {
    return props.orgList.length - MAX_LENGTH_FOR_TRUNCATED_ORGLIST;
  }
  return null;
});

const fullComponentWidth = computed(() => {
  let width =
    ORGLIST_COUNT_TO_LIST_MAP[props.size].base +
    (props.orgList.length - 1) * OVERLAPPING_ORG_WIDTH[props.size];
  if (numberOfOverflowOrgs.value) {
    width = ORGLIST_COUNT_TO_LIST_MAP[props.size].maxWidth;
    const overflowNumberDigits = numberOfOverflowOrgs.value
      .toString()
      .split('').length;
    width += overflowNumberDigits * SINGLE_DIGIT_LENGTH;
  }
  return {
    width: `${width}px`,
  };
});

const orgListTruncated = computed(() => {
  return props.orgList.slice(0, MAX_LENGTH_FOR_TRUNCATED_ORGLIST);
});

const multiplierForLeftOffset = computed(() => {
  if (props.size === 'medium') return -12;
  if (props.size === 'large') return -24;
  return -10;
});

const styleForOverflowNumber = computed(() => {
  // this is always going to be 5
  const indexLength = MAX_LENGTH_FOR_TRUNCATED_ORGLIST;
  /* we don't want this to overlap like the orgs, so we are moving it over first
      and then moving back right the length of an overlapped org icon */
  const leftAmount =
    indexLength * multiplierForLeftOffset.value +
    OVERLAPPING_ORG_WIDTH[props.size];
  return {
    left: `${leftAmount}px`,
  };
});
const tooltipOrDiv = computed(() =>
  props.includeHover ? BittsTooltip : 'div',
);

const tooltipProps = computed(() => {
  const bittsTooltipProps: Partial<BittsTooltipProps> = {};
  if (props.includeHover) {
    bittsTooltipProps.placement = 'bottomLeft';
    bittsTooltipProps.overlayClass = 'bitts-avatar-stack';
    bittsTooltipProps.mountToBody = true;
  }
  return bittsTooltipProps;
});

function getStyleForAvatar(index: number) {
  // each subsequent avatar needs to be moved to the left proportional to its position
  const leftAmount = index * multiplierForLeftOffset.value;
  return {
    left: `${leftAmount}px`,
  };
}
</script>

<style lang="pcss">
.bitts-avatar-stack.ant-tooltip {
  @apply shadow-overlay;
}
.bitts-avatar-stack-small {
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }

  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-4 pr-0 rounded-bts-sm;
  }

  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 2px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }

  .bitts-avatar-stack__tooltip-orgs {
    @apply flex items-center mr-8;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative;
  }
}

.bitts-avatar-stack-medium {
  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-4 pr-0 rounded-bts-md;
  }
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }
  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 2px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }

  .bitts-avatar-stack__tooltip-orgs {
    @apply flex items-center mr-8;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative text-m;
  }
}
.bitts-avatar-stack-large {
  .bitts-avatar-stack__org-list {
    @apply flex flex-row items-center p-8 pr-0 rounded-bts-lg;
  }
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }
  .bitts-avatar-stack__org-list-org {
    @apply relative;
  }

  .bitts-avatar-stack__outline-class {
    outline: solid 4px white;
  }

  .bitts-avatar-stack__tooltip {
    @apply flex items-center flex-wrap;
    max-width: 250px;
  }
  .c-bitts-avatar-stack__overflow-number {
    @apply text-neutral-text-weak relative text-lg;
  }
}

.bitts-avatar-stack__transparent {
  .bitts-avatar-stack__org-list {
    @apply p-0 bg-white;
  }
}

.bitts-avatar-stack__tooltip-orgs {
  @apply flex items-center mr-8;
}
</style>
