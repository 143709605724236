import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import { useFlashesStore, usePartnerCloudStore } from '@/stores';
import { popupCenter } from '@/utils';

export default function useTrayIntegrations() {
  const partnerCloudStore = usePartnerCloudStore();
  const flashesStore = useFlashesStore();

  const isConfiguringTray = ref(false);

  const openConfigWindow = (data) => {
    isConfiguringTray.value = true;
    const integrationExists = !!data.url;
    const configWindow = popupCenter({
      url: data.url,
      title: 'Crossbeam Integrations',
      w: 800,
      h: 800,
      win: window,
    });
    let configFinished = false;
    const onmessage = (e) => {
      if (e.data.type === 'tray.configPopup.error') {
        configWindow.close();
      }
      if (e.data.type === 'tray.configPopup.cancel') {
        configWindow.close();
      }
      if (e.data.type === 'tray.configPopup.finish') {
        configFinished = true;
        configWindow.close();
      }
    };
    window.addEventListener('message', onmessage);
    const CHECK_TIMEOUT = 1000;
    const checkWindow = async () => {
      if (configWindow.closed) {
        if (!configFinished && !integrationExists) {
          await partnerCloudStore.refreshPartnerCloudStore();
          flashesStore.addErrorFlash({
            message: 'Unable to successfully set up integration',
            description: 'Reach out to us at support.crossbeam.com',
          });
        } else if (Object.values(data).length) {
          try {
            if (data.autoEnable) {
              await crossbeamApi.PUT('/v0.1/tray-integrations', {
                body: {
                  instance_name: data.instance_name,
                  instance_id: data.instance_id,
                },
              });
            }
          } catch (_err) {
            flashesStore.addErrorFlash({
              message: 'Unable to successfully update integration',
              description: 'Reach out to us at support.crossbeam.com',
            });
          } finally {
            if (!data.autoEnable) {
              partnerCloudStore.$patch({
                inProgressInstallation: data.trayTag,
              });
            }
            await partnerCloudStore.refreshPartnerCloudStore();
          }
        }
        window.removeEventListener('message', onmessage);
        isConfiguringTray.value = false;
      } else {
        setTimeout(checkWindow, CHECK_TIMEOUT);
      }
    };
    checkWindow();
    return configWindow;
  };
  return {
    openConfigWindow,
    isConfiguringTray,
  };
}
