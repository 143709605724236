import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import usePartnerSharing from '@/composables/usePartnerSharing';
import { SHARING } from '@/constants/data_shares';
import {
  COMPLETED_STATUSES,
  CONNECTION_CHECK_ERROR_STATUS,
  DELETING_FEED_STATUS,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_EXCEEDED_STATUS,
  REQUIRED_FEED_STATUS,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import {
  CALCULATING,
  COMPLETE,
  DELETING,
  ERRORED,
  LOCKED,
  NEEDS_PREVIOUS,
  NEEDS_SHEET,
  NOT_SYNCED,
  PENDING,
  PROGRESS,
} from '@/constants/onboarding';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import {
  useDataSharesStore,
  useFeedsStore,
  useFileUploadsStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
  useSourcesStore,
  useTeamStore,
} from '@/stores';
import { useRootStore } from '@/stores/RootStore';
import urls from '@/urls';

export default function () {
  const notificationsStore = useNotificationsStore();
  const dataSharesStore = useDataSharesStore();
  const feedsStore = useFeedsStore();
  const fileUploadsStore = useFileUploadsStore();
  const partnersStore = usePartnersStore();
  const populationsStore = usePopulationsStore();
  const sourcesStore = useSourcesStore();
  const teamStore = useTeamStore();

  const { allNotifications } = storeToRefs(notificationsStore);
  const { feeds } = storeToRefs(feedsStore);
  const { uploadTables } = storeToRefs(fileUploadsStore);
  const { sources } = storeToRefs(sourcesStore);
  const { partnerOrgs, proposalsSent } = storeToRefs(partnersStore);
  const { populations } = storeToRefs(populationsStore);
  const { isSharingDataWithPartners } = storeToRefs(dataSharesStore);

  const { currentOrg, hasScope } = useAuth();
  const rootStore = useRootStore();
  const { inviteTeamSkipped } = storeToRefs(rootStore);
  const onboardingClosed = computed(() => !!currentOrg.value.setup_menu_hidden);
  const { coreSeats } = storeToRefs(teamStore);

  /* Team Step */
  /* This step is considered complete when you skip it (and add a value to your local storage) or
  when you invite at least one other person via the invite modal */
  const canInviteTeammates = computed(() => hasScope('write:members'));
  const inviteTeamStatus = computed(() => {
    if (coreSeats.value.length > 1 || inviteTeamSkipped.value) return COMPLETE;
    return canInviteTeammates.value ? PROGRESS : LOCKED;
  });

  /* Import Data Step */
  /* This step is considered complete when you add a data source that can be used to
  build a population. This means that the feed is connected and has synced without
  issues and has valid sources to use for the population (for Google Sheets this
  means you also have to add a sheet, for CRMs, this means you have to choose which
  fields to sync) */
  const canAddDataSources = computed(() => hasScope('write:data-sources'));
  const importDataStepStatus = computed(() => {
    const currentFeed = feeds.value.at(0);
    if (!currentFeed && canAddDataSources.value) return PROGRESS;
    if (!currentFeed && !canAddDataSources.value) return LOCKED;

    /* Check whether they abandoned their CRM connection w/out choosing fields */
    if (
      !currentFeed.initial_sync_complete &&
      currentFeed.status === REQUIRED_FEED_STATUS
    )
      return NOT_SYNCED;

    /* Check if their CRM connection is errored */
    if (
      [CONNECTION_CHECK_ERROR_STATUS, RECORD_LIMIT_EXCEEDED_STATUS].includes(
        currentFeed.status,
      )
    )
      return ERRORED;

    if (currentFeed.status === DELETING_FEED_STATUS) return DELETING;

    /* Check if they haven't added any sheets for their Google Sheets connection */
    if (currentFeed.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE) {
      const gSheetSources = sourcesStore.getSourcesByFeedId(currentFeed?.id);
      const connectionNeedsSheet = gSheetSources.length === 0;
      if (connectionNeedsSheet) return NEEDS_SHEET;
    }

    /* Check if the initial sync hasn't finished */
    if (!currentFeed.initial_sync_complete) return PENDING;

    /* Check if any of their feeds are active, and have an associated source.
     * If so, then that source can be used for populations; the step is complete */
    const hasFeedProcessed = allNotifications.value
      .filter((notification) => notification.event_type === 'feed_processed')
      .map((notification) =>
        feedsStore.getFeedById(notification.object_id.feed_id),
      )
      .some(
        (feed) =>
          ![
            CONNECTION_CHECK_ERROR_STATUS,
            RECORD_LIMIT_EXCEEDED_STATUS,
          ].includes(feed?.status),
      );
    const csvsSynced = feeds.value
      .filter(feedByType([FILE_UPLOAD_DATA_SOURCE_TYPE]))
      .some(isFeedActiveOrWarning);
    const csvsConnected = csvsSynced && uploadTables.value.length > 0;
    const activeGoogleSheetFeeds = feeds.value
      .filter(feedByType([GOOGLE_SHEETS_DATA_SOURCE_TYPE]))
      .filter(isFeedActiveOrWarning);
    const sourceFeedIds = sources.value.map((source) => source.feed_id);
    const googleSheetsConnected = activeGoogleSheetFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    const activeOtherFeeds = feeds.value
      .filter(
        feedByType(
          [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE],
          false,
        ),
      )
      .filter(isFeedActiveOrWarning);
    const otherFeedsConnected = activeOtherFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    if (
      hasFeedProcessed ||
      googleSheetsConnected ||
      csvsConnected ||
      otherFeedsConnected
    )
      return COMPLETE;

    /* Otherwise, return default state */
    return canAddDataSources.value ? PROGRESS : LOCKED;
  });

  /* Create Partnership Step */
  const canManagePartnerships = computed(() => hasScope('write:partnerships'));
  const invitePartnersStepStatus = computed(() => {
    if (partnerOrgs.value.length === 0 && proposalsSent.value.length > 0)
      return PENDING;
    if (partnerOrgs.value.length > 0) return COMPLETE;
    return canManagePartnerships.value ? PROGRESS : LOCKED;
  });

  /* Segment Data Step */
  const canManagePops = computed(() => hasScope('write:populations'));
  const segmentDataStepStatus = computed(() => {
    if (populations.value.length > 0) return COMPLETE;
    if (importDataStepStatus.value !== COMPLETE) return NEEDS_PREVIOUS;
    return canManagePops.value ? PROGRESS : LOCKED;
  });

  /* Share Data Step */
  const shareDataStepStatus = computed(() => {
    if (segmentDataStepStatus.value !== COMPLETE) return NEEDS_PREVIOUS;
    if (isSharingDataWithPartners.value) return COMPLETE;
    return canManagePops.value ? PROGRESS : LOCKED;
  });

  /* Account Mapping Step */
  const route = useRoute();
  const isActivelyOnboarding = computed(() => {
    const isOnHomePage = route?.name === 'home';
    return isOnHomePage && !onboardingClosed.value;
  });

  const partnerPops = computed(() => {
    if (isActivelyOnboarding.value) {
      return partnerOrgs.value
        .filter((partner) => !partner.offline_partner)
        .reduce((acc, partner) => {
          const { partnerPopulationsWithMetadata } = usePartnerSharing(
            partner.id,
          );
          acc[partner.id] = partnerPopulationsWithMetadata.value;
          return acc;
        }, {});
    }
    return {};
  });

  const canManageReports = computed(() => hasScope('write:reports'));
  const accountMappingStepDone = computed(
    () => ls.onboardingMappingDone.get() === currentOrg.value.id,
  );
  const allDataRequested = computed(() => {
    if (!isActivelyOnboarding.value) return;
    return (
      !!Object.values(partnerPops.value).flat().length &&
      Object.values(partnerPops.value)
        .flat()
        .every(
          (pop) =>
            !!pop.dataRequestInformation &&
            pop.requestDataIsVisible &&
            !pop.dataShare?.visibility.includes(SHARING),
        )
    );
  });
  const isCalculating = computed(() => {
    if (!isActivelyOnboarding.value) return;
    return !!populations.value.filter(
      (pop) => !pop.current_version?.first_processed_at,
    ).length;
  });
  const accountMappingStepStatus = computed(() => {
    if (
      importDataStepStatus.value !== COMPLETE ||
      invitePartnersStepStatus.value !== COMPLETE ||
      segmentDataStepStatus.value !== COMPLETE ||
      shareDataStepStatus.value !== COMPLETE
    )
      return NEEDS_PREVIOUS;
    if (!canManageReports.value) return LOCKED;
    if (isCalculating.value) return CALCULATING;
    if (allDataRequested.value) return PENDING;
    if (accountMappingStepDone.value) return COMPLETE;
    return PROGRESS;
  });

  /* Used in the navigation bar */
  const earliestCompletedPath = computed(() => {
    const STEP_TO_PATH = [
      'data-sources',
      'partners',
      'populations',
      'populations',
      'reports',
    ];
    const i = [
      importDataStepStatus.value,
      invitePartnersStepStatus.value,
      segmentDataStepStatus.value,
      shareDataStepStatus.value,
      accountMappingStepStatus.value,
    ].findIndex((status) => status !== COMPLETE);
    return i >= 0 ? STEP_TO_PATH[i] : null;
  });

  /* We want to show a separate view when the only unfinished
   * prerequisite to account mapping is their partner accepting
   * an invite request */
  const showNeedsPartnership = computed(() => {
    return (
      importDataStepStatus.value === COMPLETE &&
      invitePartnersStepStatus.value === PENDING &&
      segmentDataStepStatus.value === COMPLETE &&
      shareDataStepStatus.value === COMPLETE &&
      accountMappingStepStatus.value !== COMPLETE
    );
  });

  /* This will automatically fire on the home page when someone completes onboarding */
  const router = useRouter();
  async function closeOnboardingAndShowModal() {
    if (accountMappingStepDone.value && !onboardingClosed.value) {
      try {
        await axios.patch(urls.org.patch, { setup_menu_hidden: true });
        await router.push({ name: 'onboarding_done_modal' });
        rootStore.setIsVgActivationClosed(true);
        ls.onboardingMappingDone.set(null);
      } catch (err) {
        captureException(err);
      }
    }
  }

  return {
    inviteTeamStatus,
    inviteTeamSkipped,
    importDataStepStatus,
    invitePartnersStepStatus,
    segmentDataStepStatus,
    shareDataStepStatus,
    accountMappingStepStatus,
    onboardingClosed,
    earliestCompletedPath,
    showNeedsPartnership,
    closeOnboardingAndShowModal,
    partnerPops,
    allDataRequested,
  };
}

/* Helper functions 🤝 */
const feedByType =
  (types, inclusive = true) =>
  (feed) => {
    return inclusive
      ? types.includes(feed.integration.type)
      : !types.includes(feed.integration.type);
  };

function isFeedActiveOrWarning(feed) {
  return (
    [...COMPLETED_STATUSES, ...WARNING_STATUSES].includes(feed.status) &&
    feed.initial_sync_complete
  );
}
