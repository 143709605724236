import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import {
  DATA_TEMPLATES_CRMS,
  DataSourceFriendlyName,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { useDataSourcesStore, useFeedsStore, useSourcesStore } from '@/stores';
import { TDataSourceType } from '@/types/feeds';
import urls from '@/urls';

export default function () {
  const router = useRouter();
  const { currentOrg, hasPermission } = useAuth();
  const dataSourcesStore = useDataSourcesStore();
  const { supportedDataSources } = storeToRefs(dataSourcesStore);
  const feedsStore = useFeedsStore();
  const sourcesStore = useSourcesStore();

  const { feeds, googleFeed } = storeToRefs(feedsStore);
  const { sources } = storeToRefs(sourcesStore);

  const viewOnly = computed(() => !hasPermission('manage:data-sources'));

  /* The organizations preferred CRM, set up when they log in */
  const preferredCrm = computed(() => {
    if (HUBSPOT_DATA_SOURCE_TYPE === currentOrg.value.preferred_crm) {
      return HS3_DATA_SOURCE_TYPE as TDataSourceType;
    }
    return (
      (currentOrg.value.preferred_crm as TDataSourceType) ||
      (SALESFORCE_DATA_SOURCE_TYPE as TDataSourceType)
    );
  });

  /* Choosing a CRM, when your preferred CRM is not available */
  const chosenCrm = ref(preferredCrm.value);
  const isChosenCrmSupported = computed(() =>
    supportedDataSources.value.find((ds) => ds.type === chosenCrm.value),
  );

  const crmOptions = computed(() =>
    supportedDataSources.value
      .filter(({ type }) =>
        [...DATA_TEMPLATES_CRMS, SNOWFLAKE_DATA_SOURCE_TYPE].includes(type),
      )
      .map(({ type, friendly_name: name }) => ({
        display: name,
        value: type,
        svg: `${type}Icon`,
      }))
      .sort((a, b) =>
        a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
      ),
  );
  const chosenCrmFriendlyName = computed(() =>
    chosenCrm.value
      ? (DataSourceFriendlyName as Record<TDataSourceType, string>)[
          chosenCrm.value as TDataSourceType
        ]
      : 'Choose a CRM',
  );

  /* Code related to extra content in Connect Data Source sub-section */
  const feedsIntegrationTypeList = computed(() =>
    feeds.value.map((feed) => feed.integration.type),
  );
  const feedsIntegrationTypeWithoutGoogleSheetList = computed(() =>
    feedsIntegrationTypeList.value.filter(
      (type) => type !== GOOGLE_SHEETS_DATA_SOURCE_TYPE,
    ),
  );
  const hasConnectedGoogleSheets = computed(() => {
    const sourcesFeedIdList = computed(() =>
      sources.value.map((source) => source.feed_id),
    );
    return (
      sourcesFeedIdList.value.filter(
        (feedId) => feedId === googleFeed.value?.id,
      ).length > 0
    );
  });
  const connectedDataSourceList = computed(() => {
    if (hasConnectedGoogleSheets.value) return feedsIntegrationTypeList.value;
    // The gsheet dataSource in feedsIntegrationTypeList is listed as soon as the connection is active.
    // This is the google account connection, not the actual gsheets.
    // So if  no actual gsheets added, we don't want it to be listed here.
    return feedsIntegrationTypeWithoutGoogleSheetList.value;
  });

  const soleDataSourceConnectedFriendlyName = computed(() =>
    connectedDataSourceList.value.length === 1
      ? (DataSourceFriendlyName as Record<TDataSourceType, string>)[
          connectedDataSourceList.value[0] as TDataSourceType
        ]
      : null,
  );

  /* Connecting CRMs/Google */
  const isSandbox = ref(false);
  async function handleConnectCrm(type: string) {
    if (chosenCrm.value === SNOWFLAKE_DATA_SOURCE_TYPE) {
      // Snowflake requires an additional form
      await router.push({
        name: 'onboarding-data-sources-connect',
        params: { type: SNOWFLAKE_DATA_SOURCE_TYPE },
      });
      return;
    }

    const payload = {
      integration_type: type || chosenCrm.value,
      settings_usecase:
        type === GOOGLE_SHEETS_DATA_SOURCE_TYPE ? 'recommended' : 'custom',
    } as {
      integration_type: string;
      settings_usecase: string;
      form_properties?: { is_sandbox: boolean };
    };

    if (isSandbox.value) payload.form_properties = { is_sandbox: true };
    const resp = await axios.post(urls.oauth.authorize, { params: payload });
    window.open(resp.data.authorization_url, '_self');
  }

  /* Adding a CSV */
  const showAddDataModal = ref(false);
  async function handleAddCsv() {
    showAddDataModal.value = false;
    await router.push({
      name: 'onboarding_file_uploads',
      query: { cancelDestination: 'home' },
    });
  }

  const isConnectCrmDisabled = computed(() =>
    connectedDataSourceList.value.includes(chosenCrm.value),
  );

  function handleChooseCrm({ value }: { value: TDataSourceType }) {
    chosenCrm.value = value;
  }
  return {
    chosenCrm,
    chosenCrmFriendlyName,
    connectedDataSourceList,
    crmOptions,
    isChosenCrmSupported,
    isConnectCrmDisabled,
    isSandbox,
    soleDataSourceConnectedFriendlyName,
    viewOnly,
    handleAddCsv,
    handleChooseCrm,
    handleConnectCrm,
  };
}
