import { SharedField } from '@/interfaces/data_shares';

export enum DataSourceSettingsTabType {
  GENERAL = 'general',
  POPULATIONS = 'populations',
  SHARING = 'sharing',
  DATA_IMPORT = 'data-import',
}

export enum PopulationType {
  CUSTOMERS = 'customers',
  PROSPECTS = 'prospects',
  OPEN_OPPS = 'open-opportunities',
  STRATEGICS = 'strategic-accounts',
}

export const isRequiredMapping = {
  [PopulationType.CUSTOMERS]: true,
  [PopulationType.PROSPECTS]: true,
  [PopulationType.OPEN_OPPS]: true,
  [PopulationType.STRATEGICS]: false,
};

export interface Source {
  id: number;
  schema: string;
  table: string;
  source_id: number;
  fields: SharedField[];
}

export type FilterType = {
  label: string;
  source_field_id: number;
  operator: string;
};

export type FilterGroupType = {
  id: number;
  source_id: number;
  filters: FilterType[];
};
