<template>
  <div class="filter-segment">
    <BittsButton
      v-if="!isFirst"
      size="x-small"
      :active="true"
      :text="getFilterExpression(filterExpression)"
      @click="changeCombinator(filterExpression)"
    />
    <div
      v-if="!isEditable"
      class="sidebar__filter-segment"
      @mouseover="isUserHovering = true"
      @mouseleave="isUserHovering = false"
    >
      <div class="flex flex-row justify-between items-center">
        <div class="text-sm text-neutral-500 items-center w-full">
          <span class="text-base text-neutral-800">
            {{ `${name} ` }}
          </span>
          <span class="text-base">
            {{ `${operator} ` }}
          </span>
          <span
            v-for="(word, idx) in operands"
            :key="idx"
            :class="
              isCombinator(word)
                ? 'text-neutral-500'
                : 'font-bold text-neutral-800'
            "
            class="text-base inline-flex items-center"
          >
            <span class="sidebar__filter-segment-operand-grouped">
              {{ `${word}` }}</span
            >
            <span v-if="idx !== operands.length - 1" />
          </span>
        </div>
        <div
          :class="isUserHovering && !readOnly ? 'visible' : 'invisible'"
          class="flex flex-row self-start gap-3"
        >
          <BittsButton
            type="neutral"
            variant="outline"
            size="x-small"
            :center-icon="['fak', 'edit']"
            center-icon-classes="text-neutral-400"
            @click="onEdit"
          />
          <BittsButton
            type="danger"
            size="x-small"
            variant="outline"
            :center-icon="['fak', 'delete']"
            @click="onDelete"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="!readOnly"
      v-click-away="onClickawayWrapper"
      :class="{ 'mb-8': isEmptyOperatorSelected }"
      @mouseover="isUserHovering = true"
      @mouseleave="isUserHovering = false"
    >
      <div class="flex h-full">
        <div
          :class="getTopFilterClasses"
          class="sidebar__filter-segment-add-filter-grouped flex justify-between items-center relative"
        >
          <BittsPopover
            placement="bottom"
            :show-menu="showDropdown"
            class="sidebar__filter-segment-dropdown w-full"
          >
            <div
              :class="[
                'flex',
                'justify-between',
                'items-center',
                'text-neutral-400',
                { 'mb-0 text-neutral-900': selectedField },
              ]"
              @click="showDropdown = true"
            >
              <span>{{ fieldDisplayName }}</span>
              <BittsButton
                v-if="showDeleteEmptyFilterOnHover"
                type="danger"
                variant="outline"
                size="x-small"
                :center-icon="['fak', 'delete']"
                @click.stop="onDelete(true)"
              />
              <FontAwesomeIcon
                v-else-if="!selectedField"
                :icon="['fak', 'chevron-down']"
                :style="{
                  height: '12px',
                  width: '12px',
                  color: 'currentColor',
                }"
                class="text-neutral-400"
              />
            </div>
            <template #content>
              <PopulationFilterGroupSelector
                :show-title-if-one-source="showTitleIfOneSource"
                :org-sources="sources"
                :combinator="combinator"
                @field-selected="onFieldSelected"
              />
              <div
                v-if="showAdditionalFields"
                class="flex justify-center bg-white relative z-10 p-16 pt-0 rounded-b-bts-lg"
              >
                <BittsButton
                  text="Add Additional Fields"
                  size="x-small"
                  class="w-full"
                  type="neutral"
                  @click="onAddAdditionalFieldsClick"
                />
              </div>
            </template>
          </BittsPopover>
        </div>
        <div v-if="selectedField" class="w-full self-stretch">
          <BittsSelect
            v-model="selectedOperatorId"
            :class="{
              'sidebar__filter-segment-operator-selector-no-operand':
                isEmptyOperatorSelected,
            }"
            class="sidebar__filter-segment-operator-selector"
            :options="operatorList"
            :dropdown-match-select-width="false"
            :searchable="true"
            :mount-to-body="false"
          />
        </div>
      </div>
      <div v-if="!isEmptyOperatorSelected && selectedField">
        <BittsRangePicker
          v-if="selectedField.data_type === 'datetime'"
          format="YYYY-MM-DD"
          placeholder="Select a date"
          :initial-date="selectedDate"
          :use-single-date-picker="true"
          class="sidebar__filter-segment-datepicker-input"
          @change="addDate"
        />
        <BittsSelectTags
          v-else
          v-model="selectedOptions"
          :options="isEmptyOptions || hideDropdown ? [] : selectedFieldOptions"
          :mode="isEmptyOptions || hideDropdown ? 'tags' : 'multiple'"
          :loading="isLoadingFieldOptions"
          :placeholder="
            isEmptyOptions || hideDropdown
              ? 'Type in value and hit enter'
              : 'Select Options'
          "
          class="sidebar__filter-segment-operand-selector"
          :get-popup-container
          :hide-selected="false"
          @search="onFieldSearchChanged"
          @focusout="getInitialFields"
          @change="getInitialFields"
        />
      </div>
    </div>
    <div class="sidebar__filter-segment-dropdown-container" />
  </div>
</template>

<script>
import {
  BittsButton,
  BittsPopover,
  BittsRangePicker,
  BittsSelect,
  BittsSelectTags,
} from '@crossbeam/bitts';

import { debounce, isEmpty, orderBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { mixin as VueClickAway } from 'vue3-click-away';

import PopulationFilterGroupSelector from '@/components/PopulationFilterGroupSelector.vue';

import useAuth from '@/composables/useAuth';
import useGetPicklistFieldOptions from '@/composables/useGetPicklistFieldOptions';
import {
  HUBSPOT_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { TEMP_POPULATION_FILTER_WITH_PICKLIST_OPTIONS } from '@/constants/feature_flags';
import { OPERATORS } from '@/constants/populations';
import { useFeatureFlagStore, useSourcesStore } from '@/stores';
import urls from '@/urls';

const PRIMARY_OPERATORS = new Set([
  'in',
  'not in',
  'is empty',
  'is not empty',
  'contains',
  'does not contain',
]);

export default {
  name: 'PopulationFilterSegment',
  components: {
    BittsButton,
    BittsRangePicker,
    BittsPopover,
    PopulationFilterGroupSelector,
    BittsSelect,
    BittsSelectTags,
  },
  mixins: [VueClickAway],
  inject: {
    offlineSources: {
      default: null,
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    filter: {
      type: Object,
      required: false,
      default: () => {
        // do nothing
      },
    },
    combinator: {
      type: String,
      required: false,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showTitleIfOneSource: {
      type: Boolean,
      default: false,
    },
    filterSources: {
      type: Array,
      default: () => [],
    },
    feed: {
      type: Object,
      required: false,
      default: () => {
        // do nothing
      },
    },
    showDeleteEmptyFilter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['combinator-changed', 'filter-clickaway', 'delete-filter'],
  setup() {
    const { hasScope } = useAuth();
    return { hasScope };
  },
  data() {
    return {
      inputVal: '',
      isEditable: this.isEdit,
      isEmptyOperatorSelected: false,
      isLoadingFieldOptions: false,
      isUserHovering: false,
      filterExpression: this.combinator,
      filterLabel: '',
      filterPart: this.filter,
      selectedDate: '',
      selectedField: null,
      selectedOperator: null,
      selectedOptions: [],
      selectedFieldOptions: [],
      showDropdown: false,
      sources: [],
    };
  },
  computed: {
    areOperandsValid() {
      const operands = this.allFilterFields.operands;
      if (operands === undefined) return false;
      const isOperandsTypeAnArray = Array.isArray(operands);
      return !isOperandsTypeAnArray || operands.length > 0;
    },
    allInputsCompleted() {
      return (
        this.areOperandsValid &&
        this.isEditable &&
        this.allFilterFields.field &&
        this.allFilterFields.operator &&
        this.allFilterFields.label
      );
    },
    fieldDisplayName() {
      if (this.selectedField) return this.selectedField.display_name;
      return 'Add Object Filter';
    },
    filterPartField() {
      if (!this.filterPart.source_field_id) return null;
      if (this.offlinePartnerUuid) {
        /* Look in offline sources if it's an offline population */
        for (const source of this.offlineSources) {
          const field = source.fields.find(
            (f) => f.id === this.filterPart.source_field_id,
          );
          if (field) return field;
        }
      }

      return this.getSourceFieldById(this.filterPart.source_field_id);
    },
    getTopFilterClasses() {
      return {
        'sidebar__filter-segment-group-selector': this.selectedField,
        'sidebar__filter-segment-group-selector-empty': !this.selectedField,
        'sidebar__filter-segment-group-selector-no-operand':
          this.isEmptyOperatorSelected,
      };
    },
    isEmptyOptions() {
      return ['contains', 'does not contain'].includes(
        this.selectedOperator?.id,
      );
    },
    showDeleteEmptyFilterOnHover() {
      return (
        this.isUserHovering &&
        this.showDeleteEmptyFilter &&
        !this.selectedField &&
        !this.showDropdown
      );
    },
    selectedFieldSource() {
      if (!this.selectedField) return;
      if (this.offlinePartnerUuid)
        return this.offlineSources.find(
          (s) => s.id === this.selectedField.source_id,
        );
      // Get offline source
      return this.getSourceById(this.selectedField.source_id);
    },
    operatorList() {
      if (!this.selectedField) return [];
      let currentOperators = OPERATORS.map((op) => {
        let label = op.label;
        if (op.labelOverrides[this.selectedField.data_type]) {
          label = op.labelOverrides[this.selectedField.data_type];
        }
        return { ...op, label, value: op.id };
      });
      if (!['number', 'datetime'].includes(this.selectedField.data_type)) {
        currentOperators = currentOperators.filter((op) => {
          const whitelist = new Set([
            'is',
            'is not',
            'is empty',
            'is not empty',
            'contains',
            'does not contain',
          ]);
          return whitelist.has(op.label);
        });
      }
      return currentOperators;
    },
    name() {
      return this.filterPartField ? this.filterPartField.display_name : '';
    },
    operator() {
      if (this.filterPart.operator) {
        const operator = OPERATORS.find((op) => {
          if (
            ['is empty', 'is not empty'].indexOf(this.filterPart.operator) ===
            -1
          ) {
            return op.id === this.filterPart.operator;
          }
          return op.label === this.filterPart.operator;
        });
        if (
          operator.labelOverrides &&
          this.filterPartField &&
          operator.labelOverrides[this.filterPartField.data_type]
        ) {
          return operator.labelOverrides[this.filterPartField.data_type];
        }
        return operator.label;
      }
      return '';
    },
    operands() {
      if (this.filterPart.operand || this.filterPart.operand === 0) {
        if (Array.isArray(this.filterPart.operand)) {
          if (this.filterPartField?.data_type === 'datetime') {
            // if datetimes are an array, they will always only have 1 date in them
            // you can only choose one date at a time
            // the DB requires an array for certain operators
            return [].concat(
              DateTime.fromISO(this.filterPart.operand[0]).toFormat('DD'),
            );
          }
          return this.filterPart.operand.reduce((acc, item, index) => {
            acc.push(item);
            if (index !== this.filterPart.operand.length - 1) {
              acc.push('or');
            }
            return acc;
          }, []);
        }
        if (this.filterPartField?.data_type === 'datetime') {
          return [].concat(
            DateTime.fromISO(this.filterPart.operand).toFormat('DD'),
          );
        }
        return [].concat(this.filterPart.operand);
      }
      return '';
    },
    allFilterFields() {
      const allFields = {
        field: this.selectedField,
        operator: this.selectedOperator,
        label: this.filterLabel,
        combinator: this.filterExpression,
        index: this.index,
        operands: this.selectedOptions,
      };
      // if an operator and options are selected
      // ensure options are in the correct format
      if (this.selectedOperator && this.selectedOptions) {
        if (
          [
            'in',
            'not in',
            'is empty',
            'is not empty',
            'contains',
            'does not contain',
          ].indexOf(this.selectedOperator?.id) === -1 &&
          Array.isArray(this.selectedOptions)
        ) {
          allFields.operands = this.selectedOptions[0];
        }
      }
      return allFields;
    },
    canAddMultipleFieldOptions() {
      if (!this.selectedOperator) return false;
      return PRIMARY_OPERATORS.has(this.selectedOperator?.id);
    },
    showAdditionalFields() {
      return (
        this.feed &&
        [HUBSPOT_DATA_SOURCE_TYPE, SALESFORCE_DATA_SOURCE_TYPE].includes(
          this.feed.integration.friendly_name.toLowerCase(),
        )
      );
    },
    offlinePartnerUuid() {
      return this.$route.query?.offlinePartnerUuid;
    },
    hideDropdown() {
      return (
        this.selectedField?.pg_data_type === 'double precision' ||
        this.selectedField?.pg_data_type === 'bigint' ||
        this.selectedField.pg_data_type === 'money'
      );
    },
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    usePicklistOptions() {
      return this.hasFeatureFlag(TEMP_POPULATION_FILTER_WITH_PICKLIST_OPTIONS);
    },
    selectedOperatorId: {
      get() {
        return this.selectedOperator?.id || null;
      },
      set(id) {
        this.selectedOperator = id
          ? this.operatorList.find((op) => op.id === id)
          : null;
      },
    },
  },
  watch: {
    combinator(newCombinator) {
      if (newCombinator) this.filterExpression = newCombinator;
    },
    selectedField(val, oldVal) {
      if (val && oldVal && val.id !== oldVal.id) this.selectedOptions = [];
    },
    filterSources(newSources) {
      if (newSources)
        this.sources = this.getOrderedSourcesAndFields(newSources);
    },
    isEditable() {
      if (this.isEditable === true && this.selectedField) {
        if (
          this.selectedOperator.labelOverrides[this.selectedField.data_type]
        ) {
          this.selectedOperator.label =
            this.selectedOperator.labelOverrides[this.selectedField.data_type];
        }
      }
    },
    filterExpression() {
      this.$emit('combinator-changed', this.allFilterFields);
    },
    filter() {
      this.filterPart = this.filter;
      if (this.filterPart.isEdit) {
        this.selectedField = null;
        this.selectedOperator = '';
        this.selectedOptions = [];
      }
      if (Object.keys(this.filter).length) {
        this.isEditable = this.filterPart.isEdit;
      }
    },
    selectedOperator(newOperator) {
      if (newOperator) {
        if (['is empty', 'is not empty'].indexOf(newOperator.label) !== -1) {
          this.isEmptyOperatorSelected = true;
          this.selectedOptions = null;
        } else {
          this.isEmptyOperatorSelected = false;
          if (!this.selectedOptions) {
            this.selectedOptions = [];
          }

          // If the new Operator is in PRIMARY_OPERATORS and the options aren't an array we make them an array.
          // selectedOptions won't be null, bc we already checked for that above
          if (
            PRIMARY_OPERATORS.has(newOperator.id) &&
            !Array.isArray(this.selectedOptions)
          ) {
            this.selectedOptions = [].concat(this.selectedOptions);
            // if the operator is <, > etc and the options ARE in an array
            // we take the first index and stringify it/parseFloat it
          } else if (
            !PRIMARY_OPERATORS.has(newOperator.id) &&
            Array.isArray(this.selectedOptions)
          ) {
            if (this.selectedField.data_type === 'datetime') {
              this.selectedOptions = this.selectedOptions[0];
            } else {
              this.selectedOptions =
                parseFloat(this.selectedOptions[0], 10) ||
                this.selectedOptions[0];
            }
          }
        }
      }
    },
  },
  async created() {
    this.onFieldSearchDebounced = debounce(this.onFieldSearch, 300);
    const sourcesStore = useSourcesStore();
    await sourcesStore.readySync;
    if (this.filterPart && this.filterPart.label)
      this.filterLabel = this.filterPart.label;
    else this.filterLabel = uuidv4();
    this.sources = this.getOrderedSourcesAndFields(this.filterSources);
  },
  methods: {
    ...mapActions(useSourcesStore, ['getSourceFieldById', 'getSourceById']),
    async onAddAdditionalFieldsClick() {
      const isEditing = this.$route.name === 'edit_population';
      const name = isEditing
        ? 'edit-population-data'
        : 'edit-new-population-data';
      await this.$router.push({
        name,
        params: { id: this.feed.id },
        query: { customizing: true, closeRoute: this.$route.path },
      });
    },
    getCheckboxValue(val) {
      if (this.selectedOptions && Array.isArray(this.selectedOptions)) {
        return !!this.selectedOptions.find((item) => item === val);
      }
      return false;
    },
    getOrderedSourcesAndFields(items) {
      const sourcesWithOrderedFields = items.map((source) => {
        const orderedFields = orderBy(source.fields, [
          (field) => field.display_name.toLowerCase(),
        ]);
        return { ...source, fields: orderedFields };
      });
      return uniqBy(sourcesWithOrderedFields, 'id');
    },
    getFilterExpression(expression) {
      if (expression) return expression[0] + expression.slice(1).toLowerCase();
      return '';
    },
    isCombinator(word) {
      return word === 'or';
    },
    addTag(newTag) {
      if (this.canAddMultipleFieldOptions) {
        this.selectedOptions.push(newTag);
        this.selectedFieldOptions.push(newTag);
      } else {
        this.selectedOptions = [newTag];
        this.selectedFieldOptions = [newTag];
      }
    },
    addDate(date) {
      let isoDate = date.toISOString();
      isoDate = DateTime.fromISO(isoDate).startOf('day').toISO();
      if (['in', 'not in'].indexOf(this.selectedOperator?.id) !== -1) {
        this.selectedOptions = [].concat(isoDate);
      } else {
        this.selectedOptions = isoDate;
      }
    },
    changeCombinator(combinator) {
      if (!this.readOnly) {
        const newCombinator = combinator === 'OR' ? 'AND' : 'OR';
        this.filterExpression = newCombinator;
      }
    },
    getPopupContainer() {
      return (
        document.querySelector('.sidebar__filter-segment-dropdown-container') ??
        document.body
      );
    },
    onClickawayWrapper(event) {
      const dropdowns = document.querySelectorAll('.ant-select-dropdown');

      // Check if the click happened inside any of the dropdowns
      for (const dropdown of dropdowns) {
        if (dropdown.contains(event.target)) {
          return;
        }
      }

      this.onClickaway(event);
    },
    onClickaway() {
      this.showDropdown = false;
      if (this.allInputsCompleted) {
        this.isEditable = false;
        this.$emit('filter-clickaway', this.allFilterFields);
      }
    },
    onDelete(deleteEmptyFilter = false) {
      if (isEmpty(this.filter)) {
        this.selectedField = null;
        this.selectedOperator = null;
        this.selectedOptions = [];
        if (deleteEmptyFilter) this.$emit('delete-filter');
        return;
      }
      this.$emit('delete-filter');
      this.isEditable = this.isEdit;
    },
    async onEdit() {
      await this.onFieldSelected(this.filterPartField);
      this.isEditable = true;
      const dataType = this.filterPartField.data_type;
      switch (dataType) {
        case 'datetime':
          if (Array.isArray(this.filterPart.operand)) {
            this.selectedDate = this.filterPart.operand[0];
          } else {
            this.selectedDate = this.filterPart.operand;
          }
          break;
        case 'number':
          if (Array.isArray(this.filterPart.operand)) {
            this.inputVal = this.filterPart.operand[0];
          } else {
            this.inputVal = this.filterPart.operand;
          }
          break;
      }
    },
    async onFieldSelected(field) {
      this.selectedField = field;
      this.filterSearchInput = field.display_name;
      this.showDropdown = false;
      await this.getInitialFields();

      if (this.filterPart.operator) {
        this.selectedOperator = OPERATORS.find((op) => {
          if (
            ['is empty', 'is not empty'].indexOf(this.filterPart.operator) ===
            -1
          ) {
            return op.id === this.filterPart.operator;
          }
          return op.label === this.filterPart.operator;
        });
      } else {
        this.selectedOperator = this.operatorList[0];
      }
      if (
        !Array.isArray(this.filterPart.operand) ||
        this.filterPart.operand.length
      ) {
        this.selectedOptions = this.filterPart.operand;
      } else {
        this.selectedOptions = [];
      }
    },
    async getFields(query) {
      const url = this.offlinePartnerUuid
        ? urls.offlinePartners.populations.filterSearch(this.offlinePartnerUuid)
        : urls.filterSearch;
      // We were indexing only 'true' values for boolean fields
      // We stopped indexing any value for boolean fields
      if (this.selectedField.data_type === 'boolean') {
        this.selectedFieldOptions = [
          {
            label: 'true',
            value: true,
          },
          {
            label: 'false',
            value: false,
          },
        ];
      } else if (
        this.selectedField.pg_data_type === 'bigint' ||
        this.selectedField.pg_data_type === 'double precision' ||
        this.selectedField.pg_data_type === 'money'
      ) {
        this.selectedFieldOptions = [];
      } else {
        const picklistOptions = await useGetPicklistFieldOptions({
          searchUrl: url,
          query: query || '',
          fieldId: this.selectedField.id,
          fieldOptions: this.selectedField.options || {},
          dataSourceType: (this.feed?.integration?.type || '').toLowerCase(),
          usePicklistOptions: this.usePicklistOptions,
        });
        this.selectedFieldOptions =
          picklistOptions?.map((item) => ({
            label: item,
            value: item,
          })) ?? [];
      }
    },
    async getInitialFields() {
      if (!this.selectedField) return;
      await this.getFields();
    },
    async onFieldSearch(query) {
      this.isLoadingFieldOptions = true;
      await this.getFields(query);
      this.isLoadingFieldOptions = false;
    },
    onFieldSearchChanged(query) {
      if (this.isEmptyOptions) return;

      if (
        this.selectedField.pg_data_type === 'bigint' ||
        this.selectedField.pg_data_type === 'double precision' ||
        this.selectedField.pg_data_type === 'money'
      )
        return;

      this.onFieldSearchDebounced(query);
    },
  },
};
</script>

<style lang="pcss">
.filter-segment {
  @apply pt-0 pb-2 !important;
}
.sidebar__filter-segment {
  @apply border-2 rounded-bts-base p-4;
}

.sidebar__filter-segment-operator-selector {
  @apply border-neutral-200 pt-8 h-full w-full;
  .ant-select,
  .ant-select-selector,
  .ant-select-selection-search-input {
    height: 100% !important;
  }
  .ant-select-selector {
    @apply !border-2 !border-neutral-200 !border-l-0;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: none !important;
  }
  .ant-select-focused .ant-select-selector {
    @apply !border-2 !border-neutral-200 !border-l-0;
    box-shadow: none !important;
  }

  .ant-select-dropdown .ant-select-item-option {
    @apply mx-0;
  }
}

.sidebar__filter-segment-operator-selector-no-operand {
  @apply pt-8 w-full;
  .ant-select-selector,
  .ant-select-selection-search-input {
    height: 46px !important;
  }
  .ant-select-selector {
    @apply !border-2 !border-neutral-200 !border-l-0;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 6px !important;
  }
  .ant-select-focused .ant-select-selector {
    @apply !border-2 !border-neutral-200 !border-l-0;
    box-shadow: none !important;
  }
}

.sidebar__filter-segment-operand-selector {
  @apply mb-8;
  .ant-select-selector {
    @apply !border-2 !border-neutral-200 !border-t-0;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    box-shadow: none !important;
  }
}

.sidebar__filter-segment-datepicker-input {
  @apply w-full cursor-pointer text-neutral-900 mb-8;
  .ant-picker {
    @apply w-full border-2 border-solid border-neutral-200 border-t-0 rounded-t-none;
  }
  .ant-picker-focused {
    border-right-width: 2px !important;
    @apply border-t-0 border-neutral-200 shadow-none;
  }
  .ant-picker-input {
    @apply border-none cursor-pointer w-full py-8;
  }
  .ant-input:not(.ant-input-disabled) {
    @apply border-none;
  }
  .ant-picker:hover {
    border-right-width: 2px !important;
  }
}

.sidebar__filter-segment-add-partner-filter-bottom
  .multiselect__content-wrapper {
  @apply hidden;
}

.sidebar__filter-segment-add-partner-filter-bottom {
  @apply mb-8;
  .multiselect__select {
    @apply invisible;
  }
}

.sidebar__filter-segment-dropdown {
  .bitts-overflow-menu {
    bottom: 10px;
  }
  .ant-popover-inner {
    @apply w-[320px] overflow-y-auto overflow-x-hidden;
  }
}
</style>

<style scoped lang="pcss">
input::placeholder,
textarea::placeholder {
  @apply text-neutral-400;
}

.sidebar__filter-segment {
  @apply bg-white p-8 my-8 border-solid border-neutral-200;
  border-radius: 4px;
}
.sidebar__filter-expression-input {
  @apply bg-neutral-50 py-4 px-16 text-sm text-neutral-500 font-bold border border-solid border-neutral-border;
}

.sidebar__filter-segment-add-filter {
  @apply text-neutral-300 text-base bg-white py-8 px-12 my-8 border border-solid border-neutral-background-weak;
}

.sidebar__filter-segment-add-filter-grouped {
  @apply text-neutral-300 text-base bg-white py-8 px-12 w-full border border-solid border-neutral-background-weak;
  min-height: 46px;
}

.sidebar__filter-segment-group-selector {
  @apply border-2 border-neutral-200 mt-8 self-stretch;
  border-radius: 6px 0px 0 0;
}

.sidebar__filter-segment-group-selector-empty {
  @apply p-8 my-8 rounded-bts-base border-2 border-neutral-200 mt-8;
}

.sidebar__filter-segment-group-selector-empty
  .sidebar__filter-segment-add-filter-grouped:first-child {
  @apply mt-0;
}

.sidebar__filter-segment-group-selector-no-operand {
  border-radius: 6px 0px 0px 6px;
}

.sidebar__filter-segment-operand-grouped {
  @apply inline-block mr-2;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:last-of-type {
    margin: 0;
  }
}
</style>
