<template>
  <BittsInput
    v-model="ssoLink"
    data-testid="sso-login-input"
    class="w-full"
    form-label="SSO Log In URL"
    placeholder="SSO Log In URL"
    name="ssoLink"
    :status="v$.ssoLink.$errors?.length ? 'danger' : 'default'"
    :danger-text="v$.ssoLink.$errors?.at(-1)?.$message || ''"
    @enter-pressed="ssoLogin"
  />
  <BittsButton class="w-full" text="Continue with SSO" @click="ssoLogin" />
  <BittsLink url="#" text="Go back" @click="props.toggleSso" />
</template>

<script setup>
import { BittsButton, BittsInput, BittsLink } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, url } from '@vuelidate/validators';
import { ref } from 'vue';

const props = defineProps({
  login: { type: Function, required: true },
  toggleSso: { type: Function, required: true },
});

useHead({ title: 'Login - Crossbeam' });

const ssoLink = ref('');

/* Vuelidate validation rules */
const hasSSOParam = (value) => value.includes('sso=');
const isLoginURL = (value) => value.includes('/login');
const rules = {
  ssoLink: {
    hasSSOParam: helpers.withMessage(
      '"sso" parameter missing from SSO URL',
      hasSSOParam,
    ),
    isLoginURL: helpers.withMessage(
      'This does not appear to be an SSO login URL',
      isLoginURL,
    ),
    url: helpers.withMessage('Please enter a valid URL', url),
    required: helpers.withMessage('Please enter an SSO URL', required),
  },
};
const v$ = useVuelidate(rules, { ssoLink });

const ssoLogin = () => {
  v$.value.$touch();
  if (v$.value.$invalid) {
    console.log(v$.value.ssoLink.$errors[0].$message);
    console.log(v$.value.ssoLink.required);
    console.log(v$.value.ssoLink.required.$errors);
    return;
  }
  props.login(`${ssoLink.value}&`);
};
</script>
