import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  ACTIVE_FEED_STATUS,
  COMPLETED_STATUSES,
  CONNECTION_CHECK_ERROR_STATUS,
  DELETING_FEED_STATUS,
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  RECORD_LIMIT_EXCEEDED_STATUS,
  REQUIRED_FEED_STATUS,
  WARNING_STATUSES,
} from '@/constants/data_sources';
import {
  COMPLETE,
  DELETING,
  ERRORED,
  LOCKED,
  NEEDS_PREVIOUS,
  NEEDS_SHEET,
  NOT_SYNCED,
  PENDING,
  PROGRESS,
  StatusType,
  StepType,
} from '@/constants/onboarding';
import { ls } from '@/local_storage';
import {
  useFeedsStore,
  useFileUploadsStore,
  usePartnersStore,
  useSourcesStore,
} from '@/stores';
import { Feed } from '@/types/feeds';

type Step = {
  status: StatusType;
  isActive: boolean;
  subSteps?: string[];
};

const activeStep = ref<null | number>(null);
const inviteCrmAdminSkipped = ref(false);

export default function () {
  const feedsStore = useFeedsStore();
  const sourcesStore = useSourcesStore();
  const fileUploadsStore = useFileUploadsStore();
  const partnersStore = usePartnersStore();

  const { feeds } = storeToRefs(feedsStore);
  const { uploadTables } = storeToRefs(fileUploadsStore);
  const { sources } = storeToRefs(sourcesStore);
  const { proposalsSent, partnerOrgs } = storeToRefs(partnersStore);
  const { hasScope, currentOrg } = useAuth();

  /* Invite partner */
  const canAddPartners = computed(() => hasScope('write:partnerships'));
  const invitePartnerStepStatus = computed(() => {
    if (proposalsSent.value.length || partnerOrgs.value.length) return COMPLETE;
    return canAddPartners.value ? PROGRESS : LOCKED;
  });

  const invitePartnerStep = computed<Step>(() => {
    return {
      isActive:
        activeStep.value === StepType.InvitePartner &&
        invitePartnerStepStatus.value !== COMPLETE,
      status: invitePartnerStepStatus.value,
    };
  });

  /* Import data */
  const canAddDataSources = computed(() => hasScope('write:data-sources'));

  const connectedDataSourceType = computed(() => {
    const activeFeed = feeds.value?.find((feed) => {
      return feed.status === ACTIVE_FEED_STATUS;
    });
    return activeFeed?.integration.type || null;
  });
  const unconnectedDataSourceType = computed(() => {
    const inactiveFeed = feeds.value?.find((feed) => {
      return feed.status !== ACTIVE_FEED_STATUS;
    });
    return inactiveFeed?.integration.type || null;
  });

  const importDataStepStatus = computed<StatusType>(() => {
    const currentFeed = feeds.value.at(0); // TODO: What if we want to support multiple data sources
    if (!currentFeed && canAddDataSources.value) return PROGRESS;
    else if (!currentFeed) return LOCKED;

    /* Check whether they abandoned their CRM connection w/out choosing fields */
    if (
      !currentFeed.initial_sync_complete &&
      currentFeed.status === REQUIRED_FEED_STATUS
    ) {
      return NOT_SYNCED;
    }

    /* Check if their CRM connection is errored */
    if (
      [CONNECTION_CHECK_ERROR_STATUS, RECORD_LIMIT_EXCEEDED_STATUS].includes(
        currentFeed.status,
      )
    )
      return ERRORED;

    if (currentFeed.status === DELETING_FEED_STATUS) return DELETING;

    /* Check if they haven't added any sheets for their Google Sheets connection */
    if (currentFeed.integration.type === GOOGLE_SHEETS_DATA_SOURCE_TYPE) {
      const gSheetSources = sourcesStore.getSourcesByFeedId(currentFeed?.id);
      const connectionNeedsSheet = gSheetSources.length === 0;
      if (connectionNeedsSheet) return NEEDS_SHEET;
    }

    /* Check if the initial sync hasn't finished */
    if (!currentFeed.initial_sync_complete) return PENDING;

    /* Check if any of their feeds are active, and have an associated source.
     * If so, then that source can be used for populations; the step is complete */
    const csvsSynced = feeds.value
      .filter(feedByType([FILE_UPLOAD_DATA_SOURCE_TYPE]))
      .some(isFeedActiveOrWarning);
    const csvsConnected = csvsSynced && uploadTables.value.length > 0;
    const activeGoogleSheetFeeds = feeds.value
      .filter(feedByType([GOOGLE_SHEETS_DATA_SOURCE_TYPE]))
      .filter(isFeedActiveOrWarning);
    const sourceFeedIds = sources.value.map((source) => source.feed_id);
    const googleSheetsConnected = activeGoogleSheetFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    const activeOtherFeeds = feeds.value
      .filter(
        feedByType(
          [FILE_UPLOAD_DATA_SOURCE_TYPE, GOOGLE_SHEETS_DATA_SOURCE_TYPE],
          false,
        ),
      )
      .filter(isFeedActiveOrWarning);
    const otherFeedsConnected = activeOtherFeeds.some((feed) =>
      sourceFeedIds.includes(feed.id),
    );
    if (googleSheetsConnected || csvsConnected || otherFeedsConnected)
      return COMPLETE;

    /* Otherwise, return default state */
    return canAddDataSources.value ? PROGRESS : LOCKED;
  });

  // Inviting CRM admin
  const canInviteTeammates = computed(() => hasScope('write:members'));
  const crmAdminEmails = ref([]); // TODO: Use BE data when ready.
  const inviteCrmAdminsStatus = computed<StatusType>(() => {
    if (crmAdminEmails.value.length === 0 && !canInviteTeammates.value)
      return LOCKED;
    if (crmAdminEmails.value.length > 0 || inviteCrmAdminSkipped.value)
      return COMPLETE;
    return PROGRESS;
  });
  inviteCrmAdminSkipped.value =
    ls.onboardingCrmAdminSkip.get() === currentOrg.value.id;
  function skipInviteCrmAdmin() {
    ls.onboardingCrmAdminSkip.set(currentOrg.value.id);
    inviteCrmAdminSkipped.value = true;
  }

  const defineCustomersStatus = computed<StatusType>(() => {
    return importDataStepStatus.value === COMPLETE ? PROGRESS : NEEDS_PREVIOUS;
  });

  const connectDataSubSteps = computed(() => [
    importDataStepStatus.value,
    inviteCrmAdminsStatus.value,
    defineCustomersStatus.value,
  ]);
  const connectDataStepStatus = computed(() => {
    if (connectDataSubSteps.value.some((status) => status === LOCKED))
      return LOCKED;
    if (connectDataSubSteps.value.every((status) => status === COMPLETE))
      return COMPLETE;
    return PROGRESS;
  });

  const connectDataStep = computed<Step>(() => {
    const isActive: boolean =
      activeStep.value === StepType.ConnectData ||
      (invitePartnerStepStatus.value === COMPLETE && status !== COMPLETE); // Auto-open if partner invited
    return {
      isActive,
      status: connectDataStepStatus.value,
      subSteps: connectDataSubSteps.value,
    };
  });

  // Future logic related to Share Data Step here.
  const shareDataStep = computed<Step>(() => {
    return {
      isActive: activeStep.value === StepType.ShareData,
      status: NEEDS_PREVIOUS,
    };
  });

  // Future logic related to Uncover Step here.
  const uncoverStep = computed<Step>(() => {
    return {
      isActive: activeStep.value === StepType.Uncover,
      status: NEEDS_PREVIOUS,
    };
  });

  /* Helper functions 🤝 */
  function getDefaultActiveStep() {
    if (invitePartnerStepStatus.value !== COMPLETE)
      return StepType.InvitePartner;
    return StepType.ConnectData;
  }

  function handleChangeActiveStep(type: StepType) {
    activeStep.value = type;
  }

  // Set active step if not already set
  if (!activeStep.value) activeStep.value = getDefaultActiveStep();

  return {
    invitePartnerStep,
    crmAdminEmails,
    inviteCrmAdminsStatus,
    importDataStepStatus,
    connectDataStep,
    shareDataStep,
    uncoverStep,
    handleChangeActiveStep,
    getDefaultActiveStep,
    skipInviteCrmAdmin,
    inviteCrmAdminSkipped,
    defineCustomersStatus,
    connectedDataSourceType,
    unconnectedDataSourceType,
  };
}

/* Helper functions 🤝 */
const feedByType =
  (types: string[], inclusive = true) =>
  (feed: Feed) => {
    return inclusive
      ? types.includes(feed.integration.type)
      : !types.includes(feed.integration.type);
  };

function isFeedActiveOrWarning(feed: Feed) {
  return (
    [...COMPLETED_STATUSES, ...WARNING_STATUSES].includes(feed.status) &&
    feed.initial_sync_complete
  );
}
