<template>
  <BittsLoading :is-loading="!ready">
    <BittsLayout :is-page="true">
      <template #content>
        <PageTitle
          title="Plan & Billing"
          :class="{ 'mb-16': showRecordExportCallout }"
        />
        <BittsCallout
          v-if="isActivelyInTrial"
          data-testid="trial-callout"
          :icon="['fak', 'timer']"
          :title="`Your organization is in a Connector free trial through ${trialEndDate?.withoutYear}`"
          subtitle="If you decide not to upgrade, your organization will be moved to our free Explorer plan with limited features once the trial concludes."
          type="info"
          class="mb-40"
        />
        <ExportLimitCalloutDeux
          v-if="showRecordExportCallout"
          class="mb-40"
          v-model="isRecordExportModalVisible"
        />
        <SeatSummaryCard />
        <RecordExportSummaryCardDeux
          v-model="isRecordExportModalVisible"
          v-if="!!exportLimit"
        />
        <ExplorerCard
          v-if="isFreeTier || isActivelyInTrial"
          :trial-end-date="isActivelyInTrial ? trialEndDate?.withYear : null"
        />
        <ConnectorCard v-else-if="isConnectorTier" />
        <EnterpriseCard v-else-if="isEnterpriseTier" />
        <ExploreBillingPlans
          v-if="!isEnterpriseTier"
          :is-on-trial="isActivelyInTrial"
        />
        <router-view-wrapper />
        <!-- The upgrade modal -->
      </template>
    </BittsLayout>
  </BittsLoading>
  <RecordExportSummaryModal
    v-if="isRecordExportModalVisible"
    @closed:record-export-modal="
      isRecordExportModalVisible = !isRecordExportModalVisible
    "
  />
</template>
<script setup lang="ts">
import { BittsCallout, BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';

import ConnectorCard from '@/components/billing/ConnectorCard.vue';
import EnterpriseCard from '@/components/billing/EnterpriseCard.vue';
import ExploreBillingPlans from '@/components/billing/ExploreBillingPlans.vue';
import ExplorerCard from '@/components/billing/ExplorerCard.vue';
import ExportLimitCalloutDeux from '@/components/billing/ExportLimitCalloutDeux.vue';
import RecordExportSummaryCardDeux from '@/components/billing/RecordExportSummaryCardDeux.vue';
import SeatSummaryCard from '@/components/billing/SeatSummaryCard.vue';
import RecordExportSummaryModal from '@/components/integrations/RecordExportSummaryModal.vue';
import PageTitle from '@/components/PageTitle.vue';

import { useConnectorTrialEndDate } from '@/composables/useConnectorTrialEndDate';
import useRecordExportLimits from '@/composables/useRecordExportLimits';
import { captureException } from '@/errors';
import { allReady, useBillingStore, useTeamStore } from '@/stores';

useHead({ title: 'Billing' });

const isRecordExportModalVisible = ref(false);

const billingStore = useBillingStore();
const teamsStore = useTeamStore();
const { exportLimitStatus, exportLimit } = useRecordExportLimits();

const { freeTrialEndDate, isActivelyInTrial } = useConnectorTrialEndDate();
const trialEndDate = computed(() => {
  if (!freeTrialEndDate.value) return null;
  return {
    withoutYear: DateTime.fromISO(freeTrialEndDate.value).toFormat('MMMM d'),
    withYear: DateTime.fromISO(freeTrialEndDate.value).toFormat('DDD'),
  };
});

const { hasSubscription, isFreeTier, isEnterpriseTier, isConnectorTier } =
  storeToRefs(billingStore);

const showRecordExportCallout = computed(() =>
  ['limit-reached', 'over-90'].includes(exportLimitStatus.value || ''),
);

const ready = allReady(teamsStore, billingStore);

onBeforeMount(async () => {
  try {
    const apis = [
      teamsStore.refreshTeamStore(),
      billingStore.refreshBillingStore({ syncChargify: true }),
    ];

    /* If a connector account with subscription, populate the card information into the store */
    if (hasSubscription.value)
      apis.push(billingStore.refreshPortalAndPaymentInfo());
    await Promise.all(apis);
  } catch (err) {
    captureException(err);
  }
});
</script>
