<template>
  <div>
    <div v-for="node in nodeData" :key="node.id" class="flex flex-col">
      <div
        class="min-h-[24px] gap-8 items-center flex cursor-pointer"
        @click="toggleExpand(node.id)"
      >
        <div class="w-12 flex">
          <FontAwesomeIcon
            v-if="nodeHasChildren(node)"
            :icon="['fak', 'chevron-right']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="bitts-tree__chevron"
            :class="{
              'text-neutral-accent': !isExpanded(node.id),
              'rotate-90': isExpanded(node.id),
            }"
          />
        </div>
        <BittsCheckbox
          :checked="node.checked"
          :indeterminate="isIndeterminate(node)"
          @input="(checked) => onCheckboxChange(checked, node)"
          @click.stop
          :disabled="node.isDisabled || isDisabled"
        />
        <slot name="node-prefix" :node="node"></slot>
        <slot name="node-content" :node="node">
          <p class="text-neutral-text-strong text-base">{{ node.label }}</p>
        </slot>
        <slot name="node-suffix" :node="node"></slot>
      </div>
      <div
        v-if="isExpanded(node.id)"
        class="ml-28 pl-4 border-l border-neutral-border flex flex-col"
      >
        <BittsBaseTree
          v-if="nodeHasChildren(node)"
          :is-disabled
          :node-data="node.children ?? []"
          @update="
            (updatedNode, updatedNodeId) =>
              handleChildUpdate(updatedNode, node, updatedNodeId)
          "
          :expand-all="expandAll"
        >
          <template #node-prefix="{ node: nodeSlot }">
            <slot name="node-prefix" :node="nodeSlot"></slot>
          </template>
          <template #node-content="{ node: nodeSlot }">
            <slot name="node-content" :node="nodeSlot">
              <p class="text-neutral-text-strong text-base">{{
                (nodeSlot as TreeNodeType).label
              }}</p>
            </slot>
          </template>
          <template #node-suffix="{ node: nodeSlot }">
            <slot name="node-suffix" :node="nodeSlot"></slot>
          </template>
        </BittsBaseTree>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import BittsCheckbox from '../BittsCheckbox/BittsCheckbox.vue';

export interface TreeNodeType {
  label: string;
  checked?: boolean;
  children?: TreeNodeType[];
  id: number | string;
  isDisabled?: boolean;
}
const {
  expandAll = false,
  isDisabled = false,
  initialExpandedNodes = {},
  nodeData,
} = defineProps<{
  isDisabled?: boolean;
  nodeData: TreeNodeType[];
  expandAll?: boolean;
  initialExpandedNodes?: Record<number | string, boolean>;
}>();

const emit = defineEmits<{
  update: [value: TreeNodeType[], updatedNodeId: number | string];
}>();

defineSlots<{
  'node-prefix': { node: TreeNodeType };
  'node-content': { node: TreeNodeType };
  'node-suffix': { node: TreeNodeType };
}>();

const expandedNodes =
  ref<Record<number | string, boolean>>(initialExpandedNodes);

const nodeHasChildren = (node: TreeNodeType) => {
  return !!node.children && node.children.length > 0;
};

const isExpanded = (id: number | string) => {
  return expandAll || expandedNodes.value[id] || false;
};

const toggleExpand = (id: number | string) => {
  expandedNodes.value[id] = !expandedNodes.value[id];
};

const isIndeterminate = (node: TreeNodeType) => {
  const children = node.children || [];
  const allChecked = children.every((child) => child.checked);
  const noneChecked = children.every((child) => !child.checked);
  return !allChecked && !noneChecked;
};

const onEmitUpdate = (
  updatedNodes: TreeNodeType[],
  updatedNodeId: number | string,
) => {
  emit('update', updatedNodes, updatedNodeId);
};

const onCheckboxChange = (checked: boolean, node: TreeNodeType) => {
  const updatedNode = {
    ...node,
    checked,
    children: updateChildrenCheck(node.children, checked),
  };
  handleNodeUpdate(updatedNode, node.id);
};

const updateChildrenCheck = (
  children: TreeNodeType[] | undefined,
  checked: boolean,
): TreeNodeType[] | undefined => {
  if (!children) return undefined;

  return children.map((child) => ({
    ...child,
    checked,
    children: updateChildrenCheck(child.children, checked),
  }));
};

const handleNodeUpdate = (
  updatedNode: TreeNodeType,
  updatedNodeId: number | string,
) => {
  const updatedNodeData = nodeData.map((node) =>
    node.id === updatedNode.id ? updatedNode : node,
  );
  onEmitUpdate(updatedNodeData, updatedNodeId);
};

const handleChildUpdate = (
  updatedChildren: TreeNodeType[],
  parent: TreeNodeType,
  updatedNodeId: number | string,
) => {
  const updatedParent = {
    ...parent,
    children: updatedChildren,
    checked: updatedChildren.every((child) => child.checked),
  };
  handleNodeUpdate(updatedParent, updatedNodeId);
};
</script>

<style scoped lang="pcss">
.bitts-tree__chevron {
  @apply ml-4 transition-transform duration-150;
}
</style>
