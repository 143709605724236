<template>
  <div class="c-integration-settings-partner-settings">
    <BittsCheckbox
      v-for="population in populations"
      :checked="checkedKeys.includes(population.id)"
      :disabled="isDisabled"
      :key="population.id"
      :label="population.name"
      @input="(isChecked: boolean) => boxChecked(population.id, isChecked)"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsCheckbox } from '@crossbeam/bitts';

import { computed } from 'vue';

import { ALL_STANDARD_POPULATIONS } from '@/constants/standard_populations';
import { MinimalIntegrationProfile } from '@/types/integration_profiles';

const props = withDefaults(
  defineProps<{
    integrationProfileSettings?: MinimalIntegrationProfile | null;
    isDisabled?: boolean;
    settingType: string;
  }>(),
  { integrationProfileSettings: undefined, isDisabled: false },
);

const emit =
  defineEmits<
    (e: 'update-settings', payload: MinimalIntegrationProfile) => void
  >();

const checkedKeys = computed<string[]>(() => {
  return (
    props.integrationProfileSettings?.standard_population_settings
      .filter((pop) => pop.is_included)
      .map((pop) => pop.standard_type) ?? []
  );
});

const populations = [
  ...ALL_STANDARD_POPULATIONS.map((standardPop) => ({
    id: standardPop.standard_type,
    name: standardPop.name,
  })),
  {
    id: 'custom',
    name: 'Custom Populations',
  },
];

const updatePopulationSettings = (id: string, isChecked: boolean) => {
  return {
    population_settings:
      props.integrationProfileSettings?.population_settings ?? [],
    standard_population_settings: [
      ...(props.integrationProfileSettings?.standard_population_settings.map(
        (pop) => ({
          ...pop,
          is_included:
            pop.standard_type === id
              ? isChecked
              : checkedKeys.value.includes(pop.standard_type),
          config_type: props.settingType,
        }),
      ) ?? []),
    ],
  };
};

const boxChecked = (id: string, isChecked: boolean) => {
  const payload = updatePopulationSettings(id, isChecked);
  emit('update-settings', payload);
};
</script>

<style lang="pcss">
.c-integration-settings-partner-settings {
  @apply flex flex-col gap-4 mt-8;
}
</style>
