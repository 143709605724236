<template>
  <div class="c-role-detail__permissions">
    <div
      v-for="(resource, resourceIndex) in allResourcesWithRemovals"
      :key="`resource_${resourceIndex}`"
      class="permission-item"
    >
      <RoleDetailPermissionSelect :resource :role-id :view-only />
    </div>
  </div>
</template>

<script setup lang="ts">
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import {
  AUDIT_LOG,
  SSO,
  TEMP_LISTS_PERMISSIONS,
  TEMP_PARTNER_VISIBILITY,
} from '@/constants/feature_flags';
import {
  useBillingStore,
  useFeatureFlagStore,
  usePermissionsStore,
} from '@/stores';

import RoleDetailPermissionSelect from './RoleDetailPermissionSelect.vue';

const { roleId, viewOnly = false } = defineProps<{
  roleId?: string;
  viewOnly?: boolean;
}>();

const featureFlagStore = useFeatureFlagStore();
const billingStore = useBillingStore();
const permissionsStore = usePermissionsStore();

const { isEnterpriseTier } = storeToRefs(billingStore);
const { allResources } = storeToRefs(permissionsStore);

const allResourcesWithRemovals = computed(() => {
  /* More insight into why we are filtering members and roles
https://trello.com/c/NfPkBB5n/
356-pentest-high-priority-our-permissions-and-
roles-system-allows-certain-users-to-escalate-their-own-privileges
Crux of the issue is that if you can manage roles you can change
your own role so that you can get data sharing or something like that */
  return sortBy(
    allResources.value.filter((resource) => {
      if (
        resource.name === 'members' ||
        resource.name === 'roles' ||
        (resource.name === 'audit-log' && !auditLogEnabled.value) ||
        (resource.name === 'sso' && !ssoFeatureEnabled.value) ||
        resource.name === 'threads' ||
        (resource.name === 'partner-visibility' &&
          !partnerVisibilityEnabled.value) ||
        resource.name === 'partner-creation' ||
        (resource.name == 'lists' && !listsPermissionsEnabled.value)
      ) {
        return false;
      }

      return true;
    }),
    ['displayName'],
  );
});
const ssoFeatureEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(SSO) || isEnterpriseTier.value;
});
const auditLogEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(AUDIT_LOG) || isEnterpriseTier.value;
});
const partnerVisibilityEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(TEMP_PARTNER_VISIBILITY);
});
const listsPermissionsEnabled = computed(() => {
  return featureFlagStore.hasFeatureFlag(TEMP_LISTS_PERMISSIONS);
});
</script>

<style lang="pcss">
.c-role-detail__permissions {
  max-height: 315px;
  min-height: 170px;
  overflow-y: scroll;
  @apply mb-16 pt-1 pr-1;
}

.permission-item {
  @apply text-base mb-16 flex justify-between items-center;
}

.u-grey-box {
  border-radius: 3px;
  @apply border border-neutral-200;
  &:focus {
    @apply border-brand-teal;
  }
  &:focus-within {
    @apply border-brand-teal;
  }
}

.c-edit-role__permission-detail {
  @apply flex flex-col border border-neutral-200 p-20;
}
.c-edit-role__permission-select {
  @apply bg-neutral-200 w-120 h-25 flex justify-between items-center;
  border-radius: 2px;
}
</style>
