import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { ADMIN_PANEL, NO_ACCESS, SALES_EDGE_SEATS } from '@/constants/team';
import { initStore } from '@/stores/store-utils';
import { Invite, TeamAuthorization, TeamUser } from '@/types/team';
import urls from '@/urls';

export const useTeamStore = defineStore('TeamStore', () => {
  const authorizations = ref<TeamAuthorization[]>([]);
  const redeemableInvites = ref<Invite[]>([]);
  const revokedUsers = ref<TeamUser[]>([]);

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    const { data } = await axios.get(urls.team.list);
    if (data) {
      authorizations.value = data.authorizations;
      redeemableInvites.value = data.redeemable_invites;
      revokedUsers.value = data.revoked_users;
    }
  });

  refresh({ useCache: true });

  /* For both core and sales:
   * - An "invite" is an invitation that hasn't been redeemed yet
   * - A "user" is an authorization, it's what's used by an actual person when using Crossbeam
   * - A "seat" is either of these things (it's used to count an organization's total number of seats occupied, for instance)
   */

  const coreInvites = computed(() =>
    redeemableInvites.value.filter((invite) => invite.role_id),
  );
  const coreUsers = computed(() =>
    authorizations.value.filter(
      (auth) => auth.role_id && auth.authorizer_type !== ADMIN_PANEL,
    ),
  );
  const coreSeats = computed<(Invite | TeamAuthorization)[]>(() => [
    ...coreUsers.value,
    ...coreInvites.value,
  ]);

  const salesInvites = computed(() =>
    redeemableInvites.value.filter((invite) => {
      return (
        invite.sales_edge_role &&
        SALES_EDGE_SEATS.includes(invite.sales_edge_role)
      );
    }),
  );
  const salesUsers = computed(() => {
    return authorizations.value.filter((auth) => {
      return (
        auth.authorizer_type !== ADMIN_PANEL &&
        auth.sales_edge_role &&
        SALES_EDGE_SEATS.includes(auth.sales_edge_role)
      );
    });
  });
  const salesSeats = computed<(Invite | TeamAuthorization)[]>(() => [
    ...salesUsers.value,
    ...salesInvites.value,
  ]);
  const salesOnlyUsers = computed<TeamAuthorization[]>(() =>
    salesUsers.value.filter((auth) => !auth.role_id),
  );
  const salesOnlyInvites = computed<Invite[]>(() =>
    salesInvites.value.filter((auth) => !auth.role_id),
  );
  const salesOnlySeats = computed<(Invite | TeamAuthorization)[]>(() => [
    ...salesOnlyUsers.value,
    ...salesOnlyInvites.value,
  ]);

  const allSeats = computed(() => {
    const seen =
      new Set(); /* We need to get every unique instance of an email or invite, including the login type (since you can have SSO + Normal */
    const invites = [];
    for (const authOrInvite of [...salesSeats.value, ...coreSeats.value]) {
      const emailWithLogin =
        'email' in authOrInvite
          ? authOrInvite.email
          : `${authOrInvite.user.email}__${authOrInvite.login_method}`;
      seen.add(emailWithLogin);
      invites.push(authOrInvite);
    }
    return invites;
  });

  // TODO: For new onboarding
  const hasCrmAdmin = computed(() => {
    return false;
  });

  function getAuthorizationByUserId(id: number) {
    return authorizations.value.find(
      (authorization) => authorization.user.id === id,
    );
  }

  function getAuthsByRoleId(roleId: string) {
    return authorizations.value.filter(
      (authorization) => authorization.role_id === roleId,
    );
  }

  function normalizeNoAccessRole(role: string) {
    return !role || role === NO_ACCESS ? null : role;
  }

  async function updateRoles({
    userIds,
    salesEdgeRoleId,
    crossbeamRoleId,
  }: {
    userIds: number[];
    salesEdgeRoleId: string;
    crossbeamRoleId: string;
  }) {
    const updatedRoles = userIds.map((userId) => ({
      user_id: userId,
      role_id: normalizeNoAccessRole(crossbeamRoleId),
      sales_edge_role: normalizeNoAccessRole(salesEdgeRoleId),
    }));
    await axios.put(urls.team.change_roles, updatedRoles);
    refresh();
  }

  async function sendInvites({
    emails,
    salesEdgeRoleId,
    crossbeamRoleId,
    isSSO,
    onBehalfOf,
  }: {
    emails: string[];
    crossbeamRoleId: string;
    salesEdgeRoleId: string;
    isSSO: boolean;
    onBehalfOf?: null | number;
  }) {
    const invites = emails.map((email) => ({
      email,
      role_id: normalizeNoAccessRole(crossbeamRoleId),
      sales_edge_role: normalizeNoAccessRole(salesEdgeRoleId),
      purpose: 'user',
      is_sso: isSSO,
    }));

    await axios.post(urls.team.invite, { invites, on_behalf_of: onBehalfOf });

    return invites;
  }

  async function removeAuthorization({ userId }: { userId: number }) {
    const url = urls.team.deauthorize(userId);
    await axios.post(url);
    authorizations.value = authorizations.value.filter(
      (authorization) => authorization.user.id !== userId,
    );
  }
  async function revokeInvite({ inviteId }: { inviteId: number }) {
    const url = urls.invites.revoke(inviteId);
    await axios.delete(url);
    redeemableInvites.value = redeemableInvites.value.filter((invite) => {
      return invite.id !== inviteId;
    });
  }

  return {
    error,
    ready,
    readySync,
    running,
    authorizations,
    redeemableInvites,
    revokedUsers,
    coreSeats,
    coreInvites,
    coreUsers,
    salesInvites,
    salesOnlyInvites,
    salesOnlySeats,
    salesUsers,
    salesSeats,
    salesOnlyUsers,
    allSeats,
    hasCrmAdmin,
    getAuthsByRoleId,
    getAuthorizationByUserId,
    refreshTeamStore: refresh,
    sendInvites,
    updateRoles,
    removeAuthorization,
    revokeInvite,
  };
});
