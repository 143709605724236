export default {
  env: 'dev',
  apiBaseUrl: 'https://api.crossbeam-dev.com',
  host: 'app.crossbeam-dev.com',
  salesEdgeBaseUrl: 'https://sales-backend-api.crossbeam-dev.com',
  salesEdgeRedirectUrl: 'https://sales.crossbeam-dev.com',
  salesforceReauthUrl: 'lightning/n/xbqa__Setup',
  checkRoutes: true,
  auth0: {
    domain: 'crossbeam-dev.auth0.com',
    clientID: 'AEN6yGB89U55gQTg0VcJQlRDoJinOjYm',
    audience: 'http://api.xbeam.dev1',
  },
  statuspageBaseUrl: null,
  statusPageEmbedUrl: 'https://6dcrv6nnlbkg.statuspage.io/embed/script.js',
  // statuspageBaseUrl: 'https://crossbeamstaging.statuspage.io',
  // statuspagePageId: '6dcrv6nnlbkg',
  // datadog: {
  //   applicationId: '1e70f535-076b-454c-88c6-c01d1eb495a9',
  //   clientToken: 'pub881a1c58f3a08260853d80d2a704b75e',
  //   site: 'datadoghq.com',
  //   service: 'crossbeam-web',
  //   env: 'dev',
  //   sessionSampleRate: 100,
  //   sessionReplaySampleRate: 20,
  //   trackUserInteractions: true,
  //   trackResources: true,
  //   trackLongTasks: true,
  //   defaultPrivacyLevel: 'allow',
  //   allowedTracingUrls: ['https://api.crossbeam-dev.com'],
  // },
  intercom: {
    enabled: false,
    workspaceId: 'g42wpi5b',
  },
  enableGoogleTags: false, // only use for prod, but flip to true to test
  userLeap: {
    enabled: false,
    appId: '6pBot6YECBQ',
  },
  iteratively: {
    environment: 'development',
  },
  hubSpotForms: {
    contactSalesForm: '4ca71264-cb55-4f5c-8cae-440686ab46bd',
    envID: '5767578',
  },
  chargify: {
    publicKey: 'chjs_7gy7gw4t7tkymw4dt8yn9qxg',
    serverHost: 'https://crossbeam-dev.chargify.com',
  },
} as const;
