import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import useAuth from '@/composables/useAuth';
import { REST_API } from '@/constants/feature_flags';
import { captureException } from '@/errors';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

import { useFeatureFlagStore } from './FeatureFlagStore';

export const useOauthApplicationStore = defineStore('OAuthApplications', () => {
  const oAuthApplications = ref([]);
  const { hasPermission } = useAuth();
  const { hasFeatureFlag } = useFeatureFlagStore();
  const hasRestApiEnabled = computed(() => hasFeatureFlag(REST_API));

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    if (!hasPermission('manage:oauth-applications') || !hasRestApiEnabled.value)
      return;
    const { data } = await axios.get(urls.oAuthApplications.get);
    oAuthApplications.value = data.items;
  });

  refresh({ useCache: true });

  function getOAuthApplicationById(id) {
    return oAuthApplications.value.find((app) => app.id === id);
  }

  async function createOAuthApplication(payload) {
    try {
      await axios.post(urls.oAuthApplications.post, payload);
    } catch (err) {
      captureException(err);
      throw err;
    }
  }
  async function deleteOAuthApplication(appId) {
    try {
      oAuthApplications.value = oAuthApplications.value.filter(
        (app) => app.id !== appId,
      );
      await axios.delete(urls.oAuthApplications.delete(appId));
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  return {
    error,
    ready,
    readySync,
    refreshOAuthApplicationsStore: refresh,
    running,
    createOAuthApplication,
    deleteOAuthApplication,
    getOAuthApplicationById,
    oAuthApplications,
  };
});
