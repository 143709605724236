import { GetApiResponseByUrl } from '@crossbeam/openapi';
import { Nullable, StrictOmit } from '@crossbeam/types';

import { RoleAccess, UserAccess } from './roles';

export type PartnerUser = {
  id: number;
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  email: string;
  picture_url: Nullable<string>;
  gravatar_url: string;
};

type SendingUser = { name: Nullable<string>; email: string };

export type PartnersRaw = GetApiResponseByUrl<'/v0.1/partners'>;
export type Partner = PartnersRaw['partner_orgs'][0];
export type PartnerTagsRaw = GetApiResponseByUrl<'/v0.1/partner-tags'>;
export type PartnerTag = PartnerTagsRaw['items'][0];
export type ReceivedPartnershipProposal = PartnersRaw['proposals_received'][0];
export type OldSentPartnershipProposal = PartnersRaw['proposals'][0];
export type OverlapCounts =
  GetApiResponseByUrl<'/v0.1/partners/global/overlap-counts'>;

export type OfflinePartner = StrictOmit<Partner, 'offline_partner'> & {
  offline_partner: true;
};

export function isOfflinePartner(
  partner: Partner | OfflinePartner,
): partner is OfflinePartner {
  return partner.offline_partner === true;
}

export type PartnerTagMap = Record<string, number[]>;

export type SentPartnershipProposal = {
  id: number;
  status: string;
  created_at: string;
  contact_name: Nullable<string>;
  contact_email: Nullable<string>;
  contact_company: string;
  sending_user: Nullable<SendingUser>;
  migrated_from_reveal: boolean;
};

export type PublicInviteOrg = Nullable<{
  name: string;
  logo_url: Nullable<string>;
  clearbit_domain: Nullable<string>;
}>;

export type VisibilityResponse = {
  data: { roles: RoleAccess[]; users: UserAccess[] };
};

export type UpdateVisibilityPayload = {
  users: {
    user_id: number;
    has_access: boolean;
  }[];
  roles: {
    role_id: string;
    has_access: boolean;
  }[];
};

export function isNewProposal(
  proposal: OldSentPartnershipProposal | SentPartnershipProposal,
): proposal is SentPartnershipProposal {
  return !('contact' in proposal);
}
