import { defineStore } from 'pinia';
import { ref } from 'vue';

import { crossbeamApi } from '@/api';
import useAuth from '@/composables/useAuth';
import { CREATOR, OWNER, SHARED } from '@/constants/shared_list';
import { initStore } from '@/stores/store-utils';
import {
  SharedList,
  SharedListAccessLevel,
  SharedListOrg,
  SharedListUser,
  SharedListUserWithOrgId,
} from '@/types/shared_lists';

export const useCollaborateStore = defineStore('CollaborateStore', () => {
  const lists = ref<SharedList[]>([]);
  const listAccess = ref<SharedListAccessLevel | null>(null);
  const { hasScope } = useAuth();

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    if (!hasScope('write:lists')) return;
    const { data } = await crossbeamApi.GET('/v0.2/lists');
    if (data) {
      lists.value = data.lists;
      listAccess.value = data.access_level;
    }
  });

  refresh({ useCache: true });

  function getListById(id: string): SharedList | undefined {
    if (!id) return;
    return lists.value.find((list) => list.id === id);
  }
  function getOwnerOrgByListId(id: string): SharedListOrg | undefined {
    if (!id) return;
    const list = getListById(id);
    return list?.permissions?.find(
      (org: SharedListOrg) => org.org_access === CREATOR,
    );
  }
  function getPartnerOrgByListId(id: string): SharedListOrg | undefined {
    if (!id) return;
    const list = getListById(id);
    return list?.permissions?.find(
      (org: SharedListOrg) => org.org_access === SHARED,
    );
  }
  function getOwnerByListId(id: string): SharedListUserWithOrgId | undefined {
    if (!id) return;
    const org = getOwnerOrgByListId(id);
    const user = org?.users?.find(
      (user: SharedListUser) => user.user_access === OWNER,
    );
    if (!user) return;
    return { ...user, organization_id: org?.organization_id };
  }
  function getPartnerOwnerByListId(
    id: string,
  ): SharedListUserWithOrgId | undefined {
    if (!id) return;
    const org = getPartnerOrgByListId(id);
    const user = org?.users?.find(
      (user: SharedListUser) => user.user_access === OWNER,
    );
    if (!user) return;
    return { ...user, organization_id: org?.organization_id };
  }
  function getListUsersInOrg(
    id: string,
    orgId: number | undefined,
  ): SharedListUserWithOrgId[] | [] {
    if (!id) return [];
    const list = getListById(id);
    const users = list?.permissions?.find(
      (org: SharedListOrg) => org.organization_id === orgId,
    )?.users;
    if (!users) return [];
    return users.map((user: SharedListUser) => ({
      ...user,
      organization_id: orgId,
    }));
  }
  function getAllListsByOrgId(orgId: number | undefined): SharedList[] | [] {
    if (!orgId) return [];
    return (
      lists.value?.filter((list) =>
        list.permissions?.find(
          (org: SharedListOrg) => org.organization_id === orgId,
        ),
      ) || []
    );
  }

  return {
    error,
    ready,
    readySync,
    running,
    lists,
    listAccess,
    refreshCollaborateStore: refresh,
    getListById,
    getOwnerOrgByListId,
    getPartnerOrgByListId,
    getOwnerByListId,
    getPartnerOwnerByListId,
    getListUsersInOrg,
    getAllListsByOrgId,
  };
});
