<template>
  <BittsModal
    :visible="modalVisible"
    title="Invite Partner"
    name="partner-invite-modal"
    :show-buttons="false"
    @closed="modalClosed"
  >
    <template #content>
      <div
        class="c-invite-modal__content md:p-0 md:rounded-none md:border-none md:shadow-none"
      >
        <div
          class="py-16 w-full rounded-bts-base text-center bg-neutral-100 border-none"
        >
          <BittsAvatar
            :org="publicInviteOrg"
            :show-initials="true"
            shape="square"
            size="large"
          />
          <div
            data-testid="modal-partner-name"
            class="text-neutral-900 font-bold text-m my-8"
          >
            {{ publicInviteOrg?.name || '' }}
          </div>
          <div
            data-testid="modal-partner-text"
            class="text-neutral-500 text-base"
          >
            A partnership request will be sent to
            {{ publicInviteOrg?.name || '' }}.
          </div>
        </div>
        <BittsDivider class="my-16 hidden md:block" />
        <div class="md:flex md:justify-end mt-16">
          <BittsButton
            :loading="sending"
            type="neutral"
            variant="outline"
            text="Cancel"
            class="mr-8 w-full md:w-auto hidden md:flex"
            @click="cancel"
          />
          <BittsButton
            :loading="sending"
            text="Invite"
            class="w-full md:w-auto"
            @click="onSubmit"
          />
        </div>
      </div>
    </template>
  </BittsModal>
</template>

<script>
import {
  BittsAvatar,
  BittsButton,
  BittsDivider,
  BittsModal,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import { ls } from '@/local_storage';
import { useFlashesStore, usePartnersStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'AcceptPublicInvite',
  components: {
    BittsAvatar,
    BittsButton,
    BittsDivider,
    BittsModal,
  },
  inject: ['pointbreak'],

  setup() {
    useHead({
      title: 'Invite - Partner - Crossbeam',
      meta: [
        {
          name: 'robots',
          content: 'noindex nofollow',
        },
      ],
    });
    const { currentOrg } = useAuth();
    return { currentOrg };
  },

  data() {
    return {
      formValues: {
        contact: {
          name: null,
          email: null,
        },
        company: {
          name: null,
          clearbit_domain: null,
        },
      },
      sending: false,
      modalVisible: true,
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['publicInviteOrg', 'publicInviteCode']),
    orgYoungerThanTwoHours() {
      const created = DateTime.fromISO(this.currentOrg.created_at);
      const now = DateTime.local();
      return now.diff(created, 'hours').toObject().hours < 2;
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['addProposal', 'refreshPartnersStore']),
    ...mapActions(useFlashesStore, ['addSuccessFlash']),
    async onSubmit() {
      try {
        this.sending = true;
        const payload = {
          public_invite_code: this.publicInviteCode,
          from_public_invite: true,
        };
        const response = await axios.post(urls.proposals.create_v0_4, payload);
        this.addProposal(response.data);
        const { from } = this.$route.query;
        const iterativelyPayload = {
          invite_type: from === 'home' ? 'QR Scan' : 'Public Invite',
          event_site: EVENT_SITES.PUBLIC_INVITE_MODAL,
        };
        this.$iteratively.userSentPartnerInvite(iterativelyPayload);
        await this.refreshPartnersStore();
        this.stayOrRedirect();
        const message = 'Invitation sent';
        this.addSuccessFlash({ message });
        ls.publicInvite.set();
      } finally {
        this.sending = false;
      }
    },
    modalClosed() {
      ls.publicInvite.set(); // Acts as a dismissal
      this.addSuccessFlash({ message: 'Invitation not sent' });
      this.stayOrRedirect();
    },
    stayOrRedirect() {
      this.orgYoungerThanTwoHours || this.$route.query.from === 'home'
        ? this.$router.push({ name: 'home' })
        : this.$router.push({ name: 'partners' });
    },
    cancel() {
      this.modalVisible = false;
      this.stayOrRedirect();
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-invite-modal__close {
  @apply cursor-pointer text-neutral-text-button;
}
.c-invite-modal__content {
  @apply p-16 rounded-lg border border-neutral-border shadow-md;
}
</style>
