import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { TEMP_CONNECTOR_TRIAL } from '@/constants/feature_flags';
import { useBillingStore, useFeatureFlagStore } from '@/stores';

export function useConnectorTrialEndDate() {
  const { isOnFreeTrial, freeTrialEndDate } = storeToRefs(useBillingStore());
  const ffStore = useFeatureFlagStore();

  const hasConnectorTrial = computed(() =>
    ffStore.hasFeatureFlag(TEMP_CONNECTOR_TRIAL),
  );

  const daysLeft = computed<number>(() => {
    if (
      !isOnFreeTrial.value ||
      !freeTrialEndDate.value ||
      !hasConnectorTrial.value
    )
      return 0;
    const today = DateTime.now();
    const endDate = DateTime.fromISO(freeTrialEndDate.value);
    const days = Math.ceil(endDate.diff(today, 'days').days);
    if (days <= 0) return 0;
    return days;
  });

  const daysLeftText = computed<string>(() => {
    if (daysLeft.value === 1) return `${daysLeft.value} day`;
    return `${daysLeft.value} days`;
  });

  const isTrialOver = computed(
    () => isOnFreeTrial.value && !daysLeft.value && hasConnectorTrial.value,
  );

  const isActivelyInTrial = computed(
    () => hasConnectorTrial.value && isOnFreeTrial.value && !isTrialOver.value,
  );

  return {
    daysLeft,
    daysLeftText,
    freeTrialEndDate,
    isActivelyInTrial,
    isTrialOver,
    hasConnectorTrial,
  };
}
