<template>
  <div>
    <div class="greenfield-sharing">
      Share
      <BittsTooltip
        :cta-icon="greenfieldSharingTooltipInfo.ctaIcon"
        :button-text="greenfieldSharingTooltipInfo.ctaText"
        :include-cta="true"
        :learn-more-link="greenfieldSharingTooltipInfo.learnMoreLink"
        placement="right"
        class="greenfield-sharing__tooltip"
        @cta-clicked="onUpgradeClicked"
      >
        Greenfield accounts
        <template #title>
          {{ greenfieldSharingTooltipInfo.text }}
        </template>
      </BittsTooltip>
      ?
    </div>
    <BittsCheckbox
      :disabled="!canEditSharing"
      :checked="isGreenfieldChecked"
      data-testid="gfield-checkbox"
      :label="`Opt in to also share non-overlapping accounts with ${isPartnerType ? 'this partner' : 'partners'}`"
      @input="(checked) => emit('greenfield-checked', checked)"
    />
    <OverrideAlert
      v-if="showAlert"
      :partner-name="partner?.name"
      class="mt-8"
    />
  </div>
</template>

<script setup>
import { BittsCheckbox, BittsTooltip } from '@crossbeam/bitts';
import { EVENT_SITES, POPULATION_CTAS } from '@crossbeam/itly';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import OverrideAlert from '@/components/partners/DataSharingSettings/OverrideAlert.vue';

import useBilling from '@/composables/useBilling';
import { useBillingStore } from '@/stores';

const props = defineProps({
  canEditSharing: {
    type: Boolean,
    default: true,
  },
  isGreenfieldChecked: {
    type: Boolean,
    default: false,
  },
  partner: {
    type: Object,
    default: null,
  },
  showAlert: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['greenfield-checked']);

const billingStore = useBillingStore();
const { isFreeTier } = storeToRefs(billingStore);
const { handleBillingInteraction } = useBilling();

const isPartnerType = computed(() => !!props.partner);

const greenfieldSharingTooltipInfo = computed(() => {
  if (!isFreeTier.value) {
    return {
      ctaText: 'Learn more',
      learnMoreLink:
        'https://help.crossbeam.com/en/articles/8840015-sharing-settings-for-greenfield-data',
      text: 'When you share Greenfield accounts, partners can see accounts that do not exist in their Populations',
    };
  }
  return {
    ctaIcon: ['fak', 'upgrade'],
    ctaText: 'Upgrade',
    text: 'When you share Greenfield accounts, you’re allowing partners to see accounts that do not exist in their Populations. Upgrade to view Greenfield accounts from your partners in reports',
  };
});
function onUpgradeClicked() {
  if (!isFreeTier.value) return;
  handleBillingInteraction({
    cta: POPULATION_CTAS.PARTNER_GREENFIELD,
    event_site: EVENT_SITES.SHARING_MODAL_GREENFIELD_TOOLTIP,
  });
}
</script>

<style lang="pcss" scoped>
.greenfield-sharing {
  @apply text-base font-bold flex items-center mt-16;
}

.greenfield-sharing__tooltip {
  @apply flex items-center mx-4 cursor-default border-b border-dashed border-neutral-text-weak;
  :deep(.ant-tooltip-inner) {
    @apply font-normal;
  }
}
</style>
