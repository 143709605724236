<template>
  <BittsTable
    v-if="columns.length && rows.length"
    class="bitts-reports-table"
    :pagination-page-size="MAX_TABLE_SIZE"
    :columns="columns"
    :compress-columns="true"
    :rows="rows"
    :search-query="reportSearch"
    :hide-pagination="!showPagination"
    :pagination="true"
    :suppress-row-click-selection="true"
    :row-height="70"
    :row-multi-select-with-click="true"
    :on-row-selected="(e) => onReportChecked(e)"
    :use-search="true"
    results-text=""
    row-selection="multiple"
  />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { BittsTable } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import PartnersInReportCell from '@/components/reports/PartnersInReportCell.vue';
import ReportNameAndFolderCell from '@/components/reports/ReportNameAndFolderCell.vue';
import ReportNotificationsCell from '@/components/reports/ReportNotificationsCell.vue';
import ReportOverflowMenuCell from '@/components/reports/ReportOverflowMenuCell.vue';
import ReportTypeCell from '@/components/reports/ReportTypeCell.vue';
import ReportUserCell from '@/components/reports/ReportUserCell.vue';

import useAuth from '@/composables/useAuth';
import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  ECOSYSTEM,
  GREENFIELD_CONSOLIDATED_TYPES,
  GREENFIELD_TYPE,
  OVERLAPS,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
  SINGLE_OVERLAPS_TYPE,
} from '@/constants/reports';
import { useBillingStore, usePartnersStore, useTeamStore } from '@/stores';

const nameComparator = (cellA, cellB) => {
  if (cellA.report.name === cellB.report.name) return 0;
  return cellA.report.name > cellB.report.name ? 1 : -1;
};

const partnerComparator = (cellA, cellB) => {
  if (cellA.partnersInReport.length === cellB.partnersInReport.length) return 0;
  return cellA.partnersInReport.length > cellB.partnersInReport.length ? 1 : -1;
};

const timeComparator = (cellA, cellB, timeType) => {
  if (cellA.report[timeType] === cellB.report[timeType]) return 0;
  return cellA.report[timeType] > cellB.report[timeType] ? 1 : -1;
};

const MAX_TABLE_SIZE = 50;

const DEFAULT_COL_FIELDS = {
  autoHeight: true,
  suppressMovable: true,
};

export default {
  Name: 'ReportsTable',
  components: {
    BittsTable,
    PartnersInReportCell,
    ReportNameAndFolderCell,
    ReportNotificationsCell,
    ReportOverflowMenuCell,
    ReportTypeCell,
    ReportUserCell,
  },
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    reportSearch: {
      type: String,
      default: '',
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
    isOnReportsPage: {
      type: Boolean,
      default: true,
    },
    trialOver: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['report-checked'],
  setup() {
    const { hasScope } = useAuth();

    return { hasScope };
  },
  data() {
    return {
      currentSelectedReports: [],
      MAX_TABLE_SIZE,
    };
  },
  computed: {
    ...mapState(useBillingStore, ['isOnFreeTrial']),
    ...mapState(useTeamStore, ['authorizations', 'revokedUsers']),
    ...mapState(usePartnersStore, ['partnerOrgsLookup']),
    columnHeadings() {
      return [
        {
          ...DEFAULT_COL_FIELDS,
          cellRenderer: 'ReportNameAndFolderCell',
          field: 'Report Name',
          getQuickFilterText: (params) => {
            if (!params?.value?.report) return '';
            return params.value.report.name;
          },
          sortable: true,
          initialSort: 'asc',
          comparator: (cellA, cellB) => nameComparator(cellA, cellB),
          checkboxSelection: !this.trialOver,
          headerCheckboxSelection: !this.trialOver,
          minWidth: 300,
        },
        {
          ...DEFAULT_COL_FIELDS,
          cellRenderer: 'PartnersInReportCell',
          field: 'Partners in Report',
          sortable: true,
          comparator: (cellA, cellB) => partnerComparator(cellA, cellB),
          maxWidth: 200,
        },
        {
          cellRenderer: 'ReportTypeCell',
          field: 'Report Type',
          maxWidth: 150,
        },
        {
          ...DEFAULT_COL_FIELDS,
          cellRenderer: 'ReportUserCell',
          field: 'Last Edited',
          sortable: true,
          comparator: (cellA, cellB) =>
            timeComparator(cellA, cellB, 'updated_at'),
          maxWidth: 200,
        },
        {
          ...DEFAULT_COL_FIELDS,
          cellRenderer: 'ReportNotificationsCell',
          field: 'Notifications',
          maxWidth: 120,
        },
        {
          ...DEFAULT_COL_FIELDS,
          cellRenderer: 'ReportOverflowMenuCell',
          field: ' ',
          maxWidth: 62,
        },
      ];
    },
    columns() {
      return this.columnHeadings.map((heading) => {
        const newHeading = { ...heading };
        // need write permissions to check/uncheck
        if (newHeading.checkboxSelection) {
          newHeading.checkboxSelection = this.hasWriteReportPermissions;
          newHeading.headerCheckboxSelection = this.hasWriteReportPermissions;
        }
        return newHeading;
      });
    },
    hasWriteReportPermissions() {
      return this.hasScope('write:reports');
    },
    rows() {
      if (!this.reports) return [];
      return this.reports.map((report) => {
        const row = {};
        const updatedByUser = this.getReportUser(report);
        this.columns.forEach((col) => {
          row[col.field] = {
            report,
            hideExport: this.hideExport || this.isOnFreeTrial,
            isOnReportsPage: this.isOnReportsPage,
            partnersInReport: this.partnerOrgs(report),
            hasWritePermissions: this.hasWriteReportPermissions,
            trialOver: this.trialOver,
            reportLink: {
              name: 'edit_report',
              params: { report_id: report.id },
            },
            updatedByUser,
            reportType: this.getReportType(report),
          };
        });
        return row;
      });
    },
    showPagination() {
      return this.rows.length > MAX_TABLE_SIZE;
    },
  },
  methods: {
    getReportType(report) {
      const flow = report.consolidated_report_type;
      if (GREENFIELD_CONSOLIDATED_TYPES.includes(flow)) return GREENFIELD_TYPE;
      if (flow === ECOSYSTEM)
        return report.tag_id ? PARTNER_TAGS_TYPE : ALL_PARTNERS_TYPE;
      if (flow === OVERLAPS)
        return this.isOneToOne(report) ? SINGLE_OVERLAPS_TYPE : CUSTOM_TYPE;
      return POTENTIAL_REVENUE_TYPE;
    },
    getReportUser(report) {
      const updatedByAuthorization = this.authorizations.find(
        (authorization) => authorization.user.id === report.updated_by_user_id,
      );
      const editedByRevokedUser = this.revokedUsers.find(
        (revokedUser) => revokedUser.id === report.updated_by_user_id,
      );
      const updatedByUser = updatedByAuthorization
        ? updatedByAuthorization.user
        : editedByRevokedUser
          ? {
              ...editedByRevokedUser,
              last_name: `${editedByRevokedUser.last_name} (Deactivated)`,
            }
          : { first_name: 'Admin', last_name: '' };
      return updatedByUser;
    },
    isOneToOne({
      our_population_ids: ourPopulationIds,
      partner_population_ids: partnerPopulationIds,
    }) {
      return ourPopulationIds.length === 1 && partnerPopulationIds.length === 1;
    },
    onReportChecked(node) {
      const reportId = node.data['Report Name'].report.id;
      const reportIndex = this.currentSelectedReports.indexOf(reportId);
      if (reportIndex >= 0) {
        this.currentSelectedReports.splice(reportIndex, 1);
      } else {
        this.currentSelectedReports.push(reportId);
      }
      this.$emit('report-checked', this.currentSelectedReports);
    },
    partnerOrgs(report) {
      const partnerOrgs = report.partner_organization_ids.map(
        (orgId) => this.partnerOrgsLookup[orgId],
      );
      return partnerOrgs.filter((org) => !!org); // filter out potential undefined values
    },
  },
};
</script>
<style lang="pcss">
.bitts-reports-table {
  .ag-body-horizontal-scroll {
    @apply invisible;
  }

  .ag-center-cols-container {
    @apply px-8;
  }

  .ag-header {
    @apply border-b-0;
  }

  .ag-header-cell:first-child {
    @apply pl-[33px];
  }

  .ag-header-select-all {
    @apply mr-24;
  }

  .ag-row {
    @apply rounded-bts-md w-[98.8%];
  }

  &.bitts-table .ag-cell {
    @apply px-0;
  }

  .reports-tab-list__header {
    @apply flex items-center w-full justify-between mb-16;
  }

  .reports-table-cell__wrapper {
    @apply flex flex-col justify-center h-full w-full py-16 pr-0 pl-8;
  }

  .ag-cell-value {
    @apply w-full;
  }

  .ag-cell-wrapper {
    @apply items-stretch h-full;
    width: inherit;
  }

  .ag-selection-checkbox {
    @apply pl-24 h-full;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper,
  .ag-theme-alpine .ag-checkbox-input {
    @apply cursor-pointer;
  }

  .ag-theme-alpine
    .ag-cell-wrapper
    > :not(.ag-cell-value):not(.ag-group-value) {
    height: auto;
  }

  .ag-theme-alpine .ag-ltr .ag-row-drag,
  .ag-theme-alpine .ag-ltr .ag-selection-checkbox,
  .ag-theme-alpine .ag-ltr .ag-group-expanded,
  .ag-theme-alpine .ag-ltr .ag-group-contracted {
    @apply m-0;
  }
}
</style>
