import { MINIMAL_VIEW_PARAM } from '@/utils';

let isMinimalView: boolean | null = null;

export default function useMinimalApp() {
  if (isMinimalView === null) {
    isMinimalView = Boolean(sessionStorage.getItem(MINIMAL_VIEW_PARAM));
  }

  return {
    isMinimalView,
  };
}
