import { GetApiResponseByUrl } from '@crossbeam/openapi';
import { Nullable } from '@crossbeam/types';

import axios from 'axios';
import { differenceBy } from 'lodash';
import { HttpResponse } from 'msw';
import { storeToRefs } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref, watchEffect } from 'vue';

import { crossbeamApi } from '@/api';
import { captureException } from '@/errors';
import { enableMocks, typedHttp, worker } from '@/mocks';
import { useFeedsStore, useFlashesStore, useSourcesStore } from '@/stores';
import {
  NewRecurringPopulationPrefix,
  OperandOption,
  SelectedOperand,
  SourceOption,
} from '@/types/business_settings';
import urls from '@/urls';

export default function useBusinessSettings() {
  if (enableMocks) {
    worker.use(
      // @ts-expect-error - we don't have this route in OAS yet
      typedHttp.put('/v0.1/opportunity-filters', () => HttpResponse.json({})),
    );
  }
  const sourcesStore = useSourcesStore();

  const selectedFeedId = ref<Nullable<number>>(null);
  const selectedOpportunityFieldId = ref<Nullable<number>>(null);
  const selectedOperandsLookup = ref<{
    newBusiness: SelectedOperand;
    recurringBusiness: SelectedOperand;
  }>({
    newBusiness: [],
    recurringBusiness: [],
  });

  const opportunityFieldOptions = ref<SourceOption[]>([]);
  const operandOptions = ref<OperandOption[]>([]); // operands are the values of the selected source field

  function resetOperands() {
    selectedOperandsLookup.value.newBusiness = [];
    selectedOperandsLookup.value.recurringBusiness = [];
  }

  function resetAll() {
    resetOperands();
    selectedOpportunityFieldId.value = null;
    opportunityFieldOptions.value = [];
    operandOptions.value = [];
  }

  function filterOperandOptions(
    excludedValues: string[],
    options: OperandOption[],
  ) {
    return differenceBy(
      options,
      excludedValues.map((val) => ({ value: val })),
      'value',
    );
  }

  const isDataSourceInputDisabled = computed(() => crmFeeds.value.length === 1);

  const isMultiSelect = computed(
    () => selectedSourceField.value?.dataType === 'text',
  );

  const newBusinessOptions = computed(() =>
    isMultiSelect.value
      ? filterOperandOptions(
          selectedOperandsLookup.value.recurringBusiness as string[],
          operandOptions.value,
        )
      : operandOptions.value,
  );

  const recurringBusinessOptions = computed(() =>
    isMultiSelect.value
      ? filterOperandOptions(
          selectedOperandsLookup.value.newBusiness as string[],
          operandOptions.value,
        )
      : operandOptions.value,
  );
  const selectedSourceField = computed(() =>
    opportunityFieldOptions.value.find(
      (field) => field.value === selectedOpportunityFieldId.value,
    ),
  );

  function getDataSourceSvg(integrationType: string) {
    let name = integrationType;
    if (name === 'hubspot_v3') name = 'hubspot';
    return `${name}Icon`;
  }

  const feedsStore = useFeedsStore();
  const { crmFeeds } = storeToRefs(feedsStore);
  const feedOptions = computed(() =>
    crmFeeds.value.map((feed) => ({
      id: feed.id,
      label: feed.integration.friendly_name,
      name: feed.integration.friendly_name,
      value: feed.id,
      svg: getDataSourceSvg(feed.integration.type),
    })),
  );

  type DealSource = GetApiResponseByUrl<'/v0.1/sources'>['items'][number];
  const dealSourceFromSelectedFeed = computed(() =>
    (sourcesStore.dealSources as DealSource[]).find(
      (source) => source.feed_id === selectedFeedId.value,
    ),
  );

  const isValidSettings = computed(
    () =>
      !!selectedFeedId.value &&
      !!selectedOpportunityFieldId.value &&
      selectedOperandsLookup.value.newBusiness.length > 0 &&
      selectedOperandsLookup.value.recurringBusiness.length > 0,
  );

  const createFilterParts = (label: string, operand: SelectedOperand) => [
    {
      source_field_id: selectedOpportunityFieldId.value,
      label,
      operator: 'in',
      operand,
    },
  ];

  const createPayloadItem = (
    nameSuffix: NewRecurringPopulationPrefix,
    uuid: string,
    operand: SelectedOperand,
  ) => ({
    name: `${nameSuffix}-${selectedFeedId.value}`,
    source_id: dealSourceFromSelectedFeed.value?.id,
    source_filter_expressions: {
      [`${dealSourceFromSelectedFeed.value?.table}`]: {
        filter_expression: [uuid],
        filter_parts: createFilterParts(uuid, operand),
      },
    },
  });

  function buildPayload() {
    const newBusinessUUID = uuidv4();
    const recurringBusinessUUID = uuidv4();

    return [
      createPayloadItem(
        'xb:net-new-business',
        newBusinessUUID,
        selectedOperandsLookup.value.newBusiness,
      ),
      createPayloadItem(
        'xb:recurring-business',
        recurringBusinessUUID,
        selectedOperandsLookup.value.recurringBusiness,
      ),
    ];
  }

  const { addErrorFlash, addSuccessFlash } = useFlashesStore();
  async function saveSettings() {
    if (!isValidSettings.value) return;

    try {
      const payload = buildPayload();

      // @ts-expect-error - this route is not defined in OAS yet
      const { error } = await crossbeamApi.PUT('/v0.1/opportunity-filters', {
        body: payload,
      });

      if (error) {
        throw error;
      }

      addSuccessFlash({ message: 'Settings successfully saved' });
    } catch (error) {
      captureException(error);
      addErrorFlash({ message: 'Failed to save settings' });
    }
  }

  async function fetchFilterableSources(sourceId: number) {
    const { data, error } = await crossbeamApi.GET(
      '/v0.2/sources/{id}/filterable-sources',
      { params: { path: { id: sourceId } } },
    );

    if (error) {
      captureException(error);
      return [];
    }

    const dealSource = data?.items.find((source) => source.mdm_type === 'deal');
    return (
      dealSource?.fields
        ?.filter((field) =>
          ['boolean', 'text'].includes(field.pg_data_type as string),
        )
        .map((field) => ({
          label: field.display_name,
          value: field.id as number,
          dataType: field.pg_data_type as 'text' | 'boolean',
        })) ?? []
    );
  }

  async function fetchOperandOptions(fieldId: number) {
    const selectedField = opportunityFieldOptions.value.find(
      (field) => field.value === fieldId,
    );
    if (selectedField?.dataType === 'boolean') {
      return [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
      ];
    }
    try {
      const { data } = await axios.post(urls.filterSearch, { field: fieldId });
      return (
        data
          ?.map((label: string) => ({ label, value: label }))
          .sort((a: { label: string }, b: { label: string }) =>
            a.label.localeCompare(b.label),
          ) ?? []
      );
    } catch (err) {
      captureException(err);
      return [];
    }
  }

  watchEffect(() => {
    if (!selectedFeedId.value && crmFeeds.value.length === 1) {
      selectedFeedId.value = crmFeeds.value[0]?.id || null;
    }
  });

  return {
    buildPayload,
    selectedFeedId,
    selectedOpportunityFieldId,
    selectedOperandsLookup,
    feedOptions,
    opportunityFieldOptions,
    operandOptions,
    isValidSettings,
    newBusinessOptions,
    recurringBusinessOptions,
    saveSettings,
    dealSourceFromSelectedFeed,
    isMultiSelect,
    resetAll,
    resetOperands,
    fetchFilterableSources,
    fetchOperandOptions,
    isDataSourceInputDisabled,
  };
}
