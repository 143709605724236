<template>
  <div :class="wrapperClasses">
    <popover
      v-bind="dynamicProps()"
      :align="align"
      :placement="placement"
      :trigger="trigger"
      :mouse-enter-delay="mouseEnterDelay"
      :mouse-leave-delay="mouseLeaveDelay"
      :overlay-class-name="wrapperClasses"
      :get-popup-container="getPopupContainer"
      :auto-adjust-overflow="autoAdjustOverflow"
      :destroy-tooltip-on-hide="destroyOnClose"
      @open-change="onVisibleChange"
    >
      <!-- default slot is for the item that triggers a popover -->
      <slot>
        <span
          :class="showMenu && isVisible && 'active'"
          class="bitts-overflow-menu__default-toggle"
        >
          <FontAwesomeIcon
            :icon="['fak', 'menu']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          />
        </span>
      </slot>
      <!-- content slot is for popover content -->
      <template #content>
        <slot name="content" />
      </template>
    </popover>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Popover } from 'ant-design-vue';
import { computed, ref } from 'vue';

import { Placement, PlacementType, Trigger, TriggerType } from '../types';

export type Props = {
  align?: object;
  overlayClass?: string;
  autoAdjustOverflow?: boolean;
  placement?: PlacementType;
  trigger?: TriggerType;
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  mountToBody?: boolean;
  destroyOnClose?: boolean;
  showMenu?: boolean | null;
  disableTransition?: boolean;
  transitionName?: string;
  autoclose?: number;
};

const props = withDefaults(defineProps<Props>(), {
  align: undefined,
  overlayClass: '',
  autoAdjustOverflow: true,
  placement: Placement.Bottom,
  trigger: Trigger.Click,
  mouseEnterDelay: 0,
  mouseLeaveDelay: 0.1,
  mountToBody: false,
  destroyOnClose: false,
  showMenu: null,
  disableTransition: false,
  transitionName: 'fade',
  autoclose: 0,
});

const emit = defineEmits(['menu-visible']);

const isVisible = ref(false);

const wrapperClasses = computed(() => {
  const classes = ['bitts-overflow-menu'];
  if (props.overlayClass) classes.push(props.overlayClass);
  return classes.join(' ');
});

function onVisibleChange(visible: boolean) {
  if (props.autoclose > 0) {
    setTimeout(() => {
      isVisible.value = false;
      emit('menu-visible', { visible: false });
    }, props.autoclose);
  }
  isVisible.value = visible;
  emit('menu-visible', { visible });
}

function getPopupContainer(triggerNode: HTMLElement) {
  // TODO
  return (
    props.mountToBody ? document.body : triggerNode?.parentElement
  ) as HTMLElement;
}

function dynamicProps() {
  return {
    ...(props.showMenu !== null
      ? { open: props.showMenu }
      : props.autoclose > 0
        ? { open: isVisible.value }
        : {}),
    ...(props.transitionName
      ? {
          transitionName: props.disableTransition
            ? undefined
            : props.transitionName,
        }
      : {}),
  };
}
</script>

<style lang="pcss">
.bitts-overflow-menu {
  &.ant-popover {
    font-family: inherit;
    .ant-popover-inner {
      @apply p-0;
    }
    a:hover {
      @apply text-neutral-text-strong;
    }
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    @apply border border-neutral-border rounded-lg;
  }
  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight,
  &.ant-popover-placement-left,
  &.ant-popover-placement-leftTop,
  &.ant-popover-placement-leftBottom,
  &.ant-popover-placement-top,
  &.ant-popover-placement-topLeft,
  &.ant-popover-placement-topRight {
    padding: 0;
  }

  .bitts-overflow-menu__default-toggle {
    @apply h-24 w-24 rounded-bts-sm cursor-pointer
    flex items-center text-neutral-text-placeholder justify-center;

    &.active,
    &:hover {
      @apply bg-neutral-background;
    }
  }
}
</style>
