<template>
  <div
    @click="handleRoute"
    class="c-modal-sidebar-link"
    :class="{ active: isActive }"
  >
    <FontAwesomeIcon
      class="c-modal-sidebar-link__icon"
      :icon="item.icon"
      :style="{ height: '20px', width: '20px' }"
    />
    <span class="c-modal-sidebar-link__label">{{ item.label }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DataSourceSettingsTabType } from '@/components/data-sources/SettingsModal/constants';
import { PartnershipSettingsTabType } from '@/components/partners/DataSharingSettings/SettingsModal/PartnershipSettingsModal.vue';

export type ModalSidebarItem<T> = {
  label: string;
  icon: [string, string];
  tab: T;
};

const props = withDefaults(
  defineProps<{
    item: ModalSidebarItem<
      DataSourceSettingsTabType | PartnershipSettingsTabType
    >;
    defaultTab?: string;
    routeName: string;
  }>(),
  {
    defaultTab: 'general',
  },
);

const router = useRouter();
const route = useRoute();

const isActive = computed(
  () =>
    route.params?.tab === props.item.tab ||
    (!route.params?.tab && props.item.tab === props.defaultTab),
);

async function handleRoute() {
  await router.push({
    name: props.routeName,
    params: { ...route.params, tab: props.item.tab },
  });
}
</script>

<style lang="pcss" scoped>
.c-modal-sidebar-link__label {
  @apply opacity-75 font-bold relative;
}

.c-modal-sidebar-link {
  @apply flex items-center gap-10 py-10 w-full rounded-8 relative pl-10;

  .c-modal-sidebar-link__icon {
    @apply opacity-50;
  }

  &:focus-visible {
    @apply outline-none outline-offset-2 outline-2 outline-neutral-border-focus;
  }

  &:hover,
  &.active {
    @apply cursor-pointer bg-secondary-background-weak;
    .c-modal-sidebar-link__icon {
      @apply text-secondary-text;
    }
  }

  &.active .c-modal-sidebar-link__icon {
    @apply opacity-100;
  }
}
</style>
