import type { Endpoints, GetApiResponseByUrl, paths } from '@crossbeam/openapi';

import { HttpResponse, http } from 'msw';
import { SetupWorker } from 'msw/browser';
import { createOpenApiHttp } from 'openapi-msw';

import statusPageEvents from '@test/mocks/default/axios.statuspage-active.json';
// import trayResponse from '@test/temp-handlers/tray.json';

import appConfig from '@/config';
import urls from '@/urls';

const typedHttp = createOpenApiHttp<paths>({
  baseUrl: 'https://api.crossbeam-dev.com',
});

const isTestEnv = process.env.NODE_ENV === 'test';
const enableMocks =
  import.meta.env.DEV &&
  import.meta.env.VITE_USE_MOCKS === 'true' &&
  !isTestEnv;

let worker: SetupWorker;
const initializeMockServer = async () => {
  if (!enableMocks) return;

  const { setupWorker } = await import('msw/browser');
  worker = setupWorker();

  /* enable bypass mode to allow un-mocked requests through */
  await worker.start({ onUnhandledRequest: 'bypass' });

  worker.use(
    http.get(
      appConfig.statuspageBaseUrl + urls.external.activeStatusPageEvents,
      () => {
        return HttpResponse.json({
          ...statusPageEvents.page,
          scheduled_maintenances: [],
        });
      },
    ),
    http.get(appConfig.apiBaseUrl + urls.connections.all, () => {
      return HttpResponse.json({
        items: [
          {
            config: { app_version: 50, instance_url: 'https://foo.com' },
            integration_type: 'salesforce',
            connected_by_user_email: 'shmaryeh@foo.com',
            date_last_pushed: '2023-06-09T13:38:22.000+00:00',
            connected_at: '2023-06-09T13:38:22.000+00:00',
          },
          {
            id: '1234-1234-1234-1234',
            config: { app_version: 51, instance_url: 'https://foo1.com' },
            is_paused: false,
            integration_type: 'snowflake',
            connected_by_user_email: 'shmaryeh@foo.com',
            date_last_pushed: '2023-06-09T13:38:22.000+00:00',
            connected_at: '2023-06-09T13:38:22.000+00:00',
          },
        ],
      });
    }),
    http.get(
      `${appConfig.salesEdgeBaseUrl}/extensions/status/third-party-integration-statuses`,
      () => {
        return HttpResponse.json({
          integrations: [
            {
              name: 'Hubspot',
              install_user: {
                email: 'shmaryeh@yahoo.lycos.co.uk.biz.info',
              },
              install_date: 1623240000,
              is_active: true,
            },
          ],
        });
      },
    ),
    // typedHttp.get('/v0.1/tray-integrations', () =>
    //   HttpResponse.json(trayResponse),
    // ),
  );
};

const registerMockEndpoints = (endpoints: Endpoints) => {
  const isTestEnv = process.env.NODE_ENV === 'test';
  const enableMocks =
    import.meta.env.DEV &&
    import.meta.env.VITE_USE_MOCKS === 'true' &&
    !isTestEnv;

  if (!enableMocks) return;
  worker.use(
    ...endpoints.map(({ url, response = {} }) =>
      typedHttp.get(url, () =>
        HttpResponse.json(response as GetApiResponseByUrl<typeof url>),
      ),
    ),
  );
};

export {
  initializeMockServer,
  worker,
  typedHttp,
  registerMockEndpoints,
  enableMocks,
};
