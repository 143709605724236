<template>
  <BittsModalTwo
    :width="900"
    :loading="loading"
    :use-mask-to-close="true"
    class="c-create-population_modal"
    @closed="close"
    @action="handleAction"
    :disabled="saving || loading"
    :action-loading="saving"
  >
    <template #header>
      <div class="c-create-population_header">
        <h3 class="c-create-population_title"> {{ title }}</h3>
        <BittsTag color="secondary" size="small" variant="rounded">
          {{ `${recordCount} ${pluralize(recordCount, 'record')}` }}
        </BittsTag>
      </div>
    </template>
    <div>We recommend to include all your customers without exception</div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { useRouter } from 'vue-router';

import useDataSourceSettings from '@/composables/useDataSourceSettings';

type Props = {
  cancelDestination: string;
};

const props = defineProps<Props>();

const router = useRouter();
const { loading, saving } = useDataSourceSettings();

const recordCount = 0;
const populationType = '"Customers"';

const title = `Create ${populationType} Populations`;

async function close() {
  await router.push({ path: props.cancelDestination });
}
async function handleAction() {
  await console.log('Action');
}
</script>

<style lang="pcss">
.c-create-population_modal.o-bitts-modal.bitts-modal--primary {
  .o-bitts-modal__header {
    @apply p-24 flex-row border-b border-neutral-border;
  }
  .o-bitts-modal__content {
    @apply p-0 mt-20;
  }
}
</style>

<style lang="pcss" scoped>
.c-create-population_header {
  @apply flex gap-8;
}
.c-create-population_title {
  @apply text-left text-neutral-text-strong font-bold text-lg text-center w-full;
}
.c-create-population__content {
  @apply flex flex-col;
}
</style>
