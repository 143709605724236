<template>
  <BittsModalTwo
    title="Crossbeam overview"
    @closed="handleClose"
    secondary-button-text="Close"
    :show-primary-button="false"
    :use-mask-to-close="true"
    :width="900"
    :no-padding="true"
    class="c-intro-video-modal"
  >
    <iframe
      width="900"
      height="506"
      src="https://www.youtube.com/embed/MYyMwnsY2K4?si=gPzIc2TqB49FVtb3&autoplay=1"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo } from '@crossbeam/bitts';

import { useRouter } from 'vue-router';

const router = useRouter();

async function handleClose() {
  await router.push({ name: 'home' });
}
</script>

<style lang="pcss">
.c-intro-video-modal.o-bitts-modal.bitts-modal--primary {
  dialog > div {
    overflow: hidden;
  }
}
.c-intro-video-modal.o-bitts-modal.bitts-modal--primary {
  .o-bitts-modal__header {
    @apply items-start pt-24;
  }
  .o-bitts-modal__content {
    @apply mb-0;
  }
}
</style>
