import { GetApiResponseByUrl } from '@crossbeam/openapi';

// SeatRequestRaw[number]['status'] is plain string, this one is more specific
export enum SeatRequestStatus {
  rejected = 'rejected',
  accepted = 'accepted',
  pending = 'pending',
}

export type SeatRequestRaw = GetApiResponseByUrl<'/v0.1/seat-requests'>;
export type SeatRequest = SeatRequestRaw[number];
export type SeatType = SeatRequestRaw[number]['type'];
