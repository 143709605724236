import axios from 'axios';

import urls from '@/urls';

export const FILE_UPLOAD_DATA_SOURCE_TYPE = 'file_upload';
export const GOOGLE_SHEETS_DATA_SOURCE_TYPE = 'google_sheets';
export const PIPEDRIVE_DATA_SOURCE_TYPE = 'pipedrive';
export const HUBSPOT_DATA_SOURCE_TYPE = 'hubspot';
export const HS3_DATA_SOURCE_TYPE = 'hubspot_v3';
export const SALESFORCE_DATA_SOURCE_TYPE = 'salesforce';
export const SNOWFLAKE_DATA_SOURCE_TYPE = 'snowflake';
export const SNOWFLAKE_INTEGRATION_PREFIX = 'snowflake';
export const MD_DATA_SOURCE_TYPE = 'microsoft_dynamics';

export const SALESFORCE_INTEGRATION_FRIENDLY_NAME = 'Salesforce';
export const SNOWFLAKE_INTEGRATION_FRIENDLY_NAME = 'Snowflake';

/**
 * @returns { Record<TDataSourceType, string> }
 */
export const DataSourceFriendlyName = {
  [SALESFORCE_DATA_SOURCE_TYPE]: 'Salesforce',
  [HS3_DATA_SOURCE_TYPE]: 'Hubspot',
  [SNOWFLAKE_DATA_SOURCE_TYPE]: 'Snowflake',
  [PIPEDRIVE_DATA_SOURCE_TYPE]: 'Pipedrive',
  [MD_DATA_SOURCE_TYPE]: 'Microsoft Dynamics',
  [GOOGLE_SHEETS_DATA_SOURCE_TYPE]: 'Google Sheets',
  [FILE_UPLOAD_DATA_SOURCE_TYPE]: 'CSV File',
};

export const MAX_SALESFORCE_CONNECTIONS = 3;
export const RECORD_LIMIT_THRESHOLD = 1000;
export const SYNC_FEED_INTERVAL = 10000;
export const DELETE_FEED_INTERVAL = 10000;
export const MAX_FIELDS = 100;

export const DATA_TEMPLATES_CRMS = [
  SALESFORCE_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
];
// We have Hubspot feeds converted into HS3
// Those sources don't have 'hubspot_v3_...' schema but have 'hubspot_...' schema instead.
export const DEAL_CAPABLE_CRMS = [
  SALESFORCE_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  HUBSPOT_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
];

/* Possible statuses for a source */
export const SOURCE_STATUS_MAP = {
  process: ['PROCESSING', 'SCHEDULED_TO_PROCESS'],
  done: ['PROCESSED'],
  error: ['ERROR', 'PROCESSING_FAILED', 'COLUMN_ERRORS'],
  warning: ['DUPLICATE_ROWS'],
  deleting: ['DELETING'],
};

/* The possible statuses for a feed */
export const REQUIRED_FEED_STATUS = 'REQUIRE_SOURCES_SELECTION';
export const PENDING_FEED_STATUS = 'OAUTH_COMPLETE';
export const ACTIVE_FEED_STATUS = 'CONFIGURED';
export const CONNECTION_CHECK_ERROR_STATUS = 'CONN_CHECK_FAILED';
export const RECORD_LIMIT_EXCEEDED_STATUS = 'RECORD_LIMIT_EXCEEDED';
export const SOURCE_FAILED_STATUS = 'SOURCE_FAILED';
export const DELETING_FEED_STATUS = 'DELETING';
export const DELETION_FAILED_FEED_STATUS = 'DELETION_FAILED';

export const COMPLETED_STATUSES = [PENDING_FEED_STATUS, ACTIVE_FEED_STATUS];
export const WARNING_STATUSES = [SOURCE_FAILED_STATUS];

export const LOOKUP_FIELD = 'lookup-field';
export const SOURCE_FIELD = 'source-field';
export const SOURCE = 'source';

export const ALL_FIELDS = 'all_fields';
export const SELECTED_FIELDS = 'selected_fields';

export const LOOKUP_PARENT = 'lookup_parent';
export const CONTAINS_LOOKUPS = 'contains_lookups';
export const CONTAINS_COPIED = 'contains_copied';

export const SYNCED = 'synced';
export const UNSYNCED = 'unsynced';
export const UNSYNCED_DISABLED = 'unsynced=disabled';
export const CANNOT_UNSYNC = 'cannot-unsync-field';
export const CANNOT_UNSYNC_PLURAL = 'cannot-unsync-fields';

const getLookupFieldString = (list) => {
  let str = '';
  list.forEach((item) => {
    str += ` ${item.copyingFieldName},`;
  });
  return str;
};

export const getFieldTooltipText = (fieldOrFields, source, messageType) => {
  switch (messageType) {
    case SYNCED:
      return `This field is synced to the ${fieldOrFields.display_name} field in the ${source.table} object`;
    case UNSYNCED:
      // no tooltip for UNSYNCED
      return null;
    case UNSYNCED_DISABLED:
      return `Select ${fieldOrFields.display_name} to enable this field`;
    case CANNOT_UNSYNC:
      return `Unsync lookup field ${fieldOrFields[0].copyingFieldName} in order to unsync this field.`;
    case CANNOT_UNSYNC_PLURAL:
      return `Unsync lookup fields${getLookupFieldString(fieldOrFields)} in order to unsync this field.`;
  }
};

export const getKey = ({ type, sourceId, fieldId = null, relId = null }) => {
  let key;
  if (fieldId) {
    key = `${type}__${fieldId}__${sourceId}`;
  } else {
    key = `${type}__${sourceId}`;
  }
  if (relId) {
    key += `__${relId}`;
  }
  return key;
};

export const getIconTooltipText = (linkedSource, messageType) => {
  switch (messageType) {
    case LOOKUP_PARENT:
      return `This field is linked to the ${linkedSource.display_name || linkedSource.table} object`;
    case CONTAINS_COPIED:
      return 'Fields in this object are linked to lookup fields';
    case CONTAINS_LOOKUPS:
      return 'Fields in this object include lookup fields';
  }
};

export function isInPopDefinition(pop, fieldId) {
  return Object.values(pop.source_filter_expressions)
    .map((expr) => expr.filter_parts)
    .flat()
    .find((fp) => fp.source_field_id === fieldId);
}

/* Returns the required columns for a given feed, per each source */
/* We may have to incorporate logic in here to account for fields from the template */
export async function getRequiredColumns(feed) {
  const dataSourceType = feed.integration.type;
  const response = await axios.get(
    urls.dataSources.requiredColumns(dataSourceType),
  );
  return response.data.reduce(
    (acc, { table, columns }) => ({ ...acc, [table]: columns }),
    {},
  );
}

export function isValidSourceField(sourceField) {
  return (
    !sourceField.column.startsWith('_sdc_') &&
    !sourceField.syncing_disabled_reason
  );
}

export async function patchSources({ feedId, sources, fields }) {
  await axios.patch(urls.feeds.sourceFields(feedId), {
    sources,
    fields,
  });
}

export async function patchFeed(feedId, payload) {
  await axios.patch(urls.feeds.patch(feedId), payload);
}
