<template>
  <div class="c-explore-billing-plans">
    <h3 class="c-explore-billing-plans__title"> Explore Plans </h3>
    <RadioGroup v-if="isFreeTier" v-model:value="option" class="flex">
      <BittsRadio :option="options[0]">
        <template #label>
          Pay annually
          <span class="text-success-text font-bold">(Save 10%)</span>
        </template>
      </BittsRadio>
      <BittsTooltip placement="bottom">
        <BittsRadio :option="options[1]" />
        <template #title> Requires annual commitment </template>
      </BittsTooltip>
    </RadioGroup>
  </div>
  <BittsLayout variant="equal" class="mb-72" :gap="24" :min-col-width="305">
    <template #explorer>
      <BillingPlanComparisonCard
        :highlight="
          isConnectorTier && isSubscriptionCancelled
            ? `Your new plan on ${subscriptionRenewalDate}`
            : undefined
        "
        tier="explorer"
        :is-billing-page="true"
      >
        <template #action>
          <BittsButton
            :text="
              isFreeTier || isSubscriptionCancelled || isOnTrial
                ? 'Current plan'
                : 'Downgrade'
            "
            size="medium"
            type="neutral"
            :variant="
              isFreeTier || isSubscriptionCancelled || isOnTrial
                ? 'outline'
                : 'fill'
            "
            class="w-full mx-auto"
            :class="{ 'bg-white': isFreeTier || isSubscriptionCancelled }"
            :disabled="downgradeDisabled"
            @click="handleDowngrade(EVENT_SITES.EXPLORER_CARD_DOWNGRADE_BUTTON)"
          />
        </template>
      </BillingPlanComparisonCard>
    </template>
    <template #connector>
      <BillingPlanComparisonCard
        :highlight="isFreeTier ? 'Recommended' : undefined"
        :period="option"
        tier="connector"
        :is-billing-page="true"
      >
        <template #action>
          <BittsButton
            :text="connectorButtonText"
            size="medium"
            type="primary"
            :variant="
              isFreeTier || isSubscriptionCancelled || isOnTrial
                ? 'fill'
                : 'outline'
            "
            class="w-full mx-auto"
            :left-icon="
              isFreeTier ||
              !hasSubscription ||
              isSubscriptionCancelled ||
              isOnTrial
                ? null
                : ['fak', 'upgrade']
            "
            :disabled="!hasSubscription && isConnectorTier && !isOnTrial"
            :class="{
              'bg-white': !hasSubscription && isConnectorTier && !isOnTrial,
            }"
            @click="handleConnectorAction"
          />
        </template>
      </BillingPlanComparisonCard>
    </template>
    <template #supernode>
      <BillingPlanComparisonCard tier="supernode" :is-billing-page="true">
        <template #action>
          <BittsButton
            text="Talk to Sales"
            size="medium"
            type="upsell"
            :left-icon="['fak', 'upgrade']"
            class="w-full mx-auto"
            @click="
              handleBillingInteraction({
                talkToSalesReason: 'Upgrade to Supernode',
                event_site: EVENT_SITES.EXPLORE_PLANS_ENTERPRISE_BUTTON,
              })
            "
          />
        </template>
      </BillingPlanComparisonCard>
    </template>
  </BittsLayout>
</template>
<script setup lang="ts">
import {
  BittsButton,
  BittsLayout,
  BittsRadio,
  BittsTooltip,
} from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import { Radio } from 'ant-design-vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import BillingPlanComparisonCard from '@/components/billing/BillingPlanComparisonCard.vue';

import useBilling from '@/composables/useBilling';
import useHasFeature from '@/composables/useHasFeature';
import { useBillingStore } from '@/stores';
import { BillingPeriod } from '@/types/billing';

const { isOnTrial = false } = defineProps<{ isOnTrial?: boolean }>();

const RadioGroup = Radio.Group;

const router = useRouter();
const billingStore = useBillingStore();
const { handleBillingInteraction, handleDowngrade } = useBilling();

const { debookingEnabled } = useHasFeature();

const {
  isFreeTier,
  isConnectorTier,
  isSubscriptionCancelled,
  subscriptionRenewalDate,
  hasSubscription,
} = storeToRefs(billingStore);

const options = [
  { value: BillingPeriod.Year },
  { value: BillingPeriod.Month, label: 'Pay monthly*' },
];

const connectorButtonText = computed(() => {
  if (isSubscriptionCancelled.value && debookingEnabled.value)
    return 'Restore plan';
  if (isFreeTier.value || isOnTrial) return 'Upgrade to Connector';
  return hasSubscription.value && !isSubscriptionCancelled.value
    ? 'Add seats'
    : 'Current plan';
});

function handleConnectorAction() {
  if (isSubscriptionCancelled.value) router.push({ name: 'restore-plan' });
  else
    handleBillingInteraction(
      {
        event_site: EVENT_SITES.EXPLORE_PLANS_FREE_TIER_BUTTON,
        period: option.value,
      },
      null,
      true,
    );
}

const downgradeDisabled = computed(
  () =>
    !isConnectorTier.value ||
    !hasSubscription.value ||
    !debookingEnabled.value ||
    isSubscriptionCancelled.value,
);

const initialValue =
  !isConnectorTier.value || !billingStore.isMonthly
    ? options[0]?.value
    : options[1]?.value;
const option = ref<BillingPeriod>(initialValue as BillingPeriod);
</script>

<style lang="pcss" scoped>
.c-explore-billing-plans {
  @apply flex justify-between items-center mb-16;
  .c-explore-billing-plans__title {
    @apply text-neutral-text-strong text-lg font-bold;
  }
}
</style>
