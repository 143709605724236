<template>
  <div
    v-for="({ mention_link, user_name, sentence }, idx) in mentions"
    :key="activity?.uuid + '__' + idx"
    class="gong-call-context"
  >
    <div class="mention-info" v-if="mention_link && user_name && sentence">
      <span class="text-neutral-text-strong">{{ user_name }}</span>
      <span>•</span>
      <button
        class="text-secondary-text"
        target="_blank"
        @click="onClickDeepLink(mention_link)"
        type="button"
      >
        {{ buildTimestamp(mention_link) }}
        <FontAwesomeIcon
          :icon="['fak', 'open-link']"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          class="text-secondary-text"
        />
      </button>
    </div>
    <p
      class="text-neutral-text"
      data-testid="mention-sentence"
      v-html="buildHighlightedSentence(sentence)"
    />
  </div>
</template>

<script setup lang="ts">
import { ATTRIBUTION_CTAS, EVENT_SITES } from '@crossbeam/itly';

import { Duration } from 'luxon';
import { computed } from 'vue';

import useIteratively from '@/composables/useIteratively';
import { usePartnersStore } from '@/stores';
import { Partner } from '@/types/partners';
import { TimelineEvent } from '@/types/timeline';

const { activity } = defineProps<{
  activity?: TimelineEvent;
}>();

const partnerStore = usePartnersStore();
const partner = computed(
  () =>
    partnerStore.getPartnerOrgByUuid(
      activity?.partner_organization_uuid ?? '',
    ) as Partner,
);

const mentions = computed(
  () =>
    (activity?.activity_source === 'gong' && 'mentions' in activity.data
      ? activity.data.mentions
      : []) as unknown as {
      mention_link: string;
      user_name: string;
      user_email: string;
      sentence: string;
    }[],
);

const buildTimestamp = (link?: string) => {
  const highlights = new URLSearchParams(link).get('highlights') ?? '';
  const seconds = JSON.parse(decodeURIComponent(highlights))?.at(-1)?.from;
  if (!seconds) return '--:--';

  const duration = Duration.fromObject({ seconds });

  if (duration.seconds > 3600) return duration.toFormat('hh:mm:ss');
  return duration?.toFormat('mm:ss');
};

const partnerWithoutLLCOrInc = computed(() =>
  partner.value.name
    .replace(/(,?\s+(LLC|Inc)\.?)$|/gi, '')
    .replace(/\s+/gi, ''),
);

/* case insensitive and global to highlight all instances, also matching if white spaces are present.
  example... for DC Comics: dccomics, dc comics, and d c comics will match */
const partnerRegex = computed(
  () =>
    new RegExp(
      `${partnerWithoutLLCOrInc.value
        .split('')
        .map((char) => {
          if (char === ',') return '\\s*,?\\s*'; // replace comma with optional comma and white spaces
          if (char === '.') return '\\s*\\.?\\s*'; // replace dot with optional dot and white spaces
          return char;
        })
        .join('(\\.\\s*|\\s*)')}(\\s*,?\\s+(LLC|Inc)\\.?\\s*?)?`, // match LLC or Inc at the end, we're replacing with the partner name so we want that as optional
      'gi',
    ),
);

const buildHighlightedSentence = (sentence: string) => {
  // truncate sentence if it's too long
  const maxSentenceLength = 300;

  const truncatedString =
    sentence.length > maxSentenceLength
      ? `"${sentence.substring(0, maxSentenceLength)}..."`
      : `"${sentence}"`;

  const newSentence = truncatedString.replace(
    partnerRegex.value,
    `<strong class="highlight-me">${partner.value.name}</strong>`,
  );
  return newSentence;
};

const { iteratively } = useIteratively();

function onClickDeepLink(url: string) {
  iteratively.userInteractedWithTimeline({
    cta: ATTRIBUTION_CTAS.GONG_MENTIONS,
    cta_2: partner.value.name,
    event_site: EVENT_SITES.GONG_MENTIONS,
  } as never);

  window.open(url, '_blank');
}
</script>

<style scoped lang="pcss">
.gong-call-context {
  @apply text-sm text-neutral-text mt-16 ml-18;
}

.mention-info {
  @apply flex items-center gap-4 mb-4;
}

:deep() .highlight-me {
  @apply bg-info-background-weak px-4 rounded-lg font-bold text-info-text-strong;
}
</style>
