<template>
  <Notification v-if="partnerPop" :item="item">
    <template #logo>
      <BittsAvatar :org="partnerOrg" :show-initials="true" size="medium" />
    </template>
    <template #message> Population Revealed </template>
    <template #description>
      {{ partnerOrg.name }} has revealed their {{ partnerPop.name }} Population
      to you
    </template>
    <template #action>
      <BittsButton
        text="Request Data"
        type="neutral"
        variant="outline"
        size="x-small"
        @click="requestData"
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fak', 'visible']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script>
import { BittsAvatar, BittsButton } from '@crossbeam/bitts';

import { mapActions } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import {
  useDataRequestsStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

export default {
  name: 'PopRevealNotification',
  components: {
    BittsAvatar,
    BittsButton,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  computed: {
    partnerOrg() {
      if (!this.partnerPop) return {};
      return this.getPartnerOrgById(this.partnerPop?.organization_id) ?? {};
    },
    partnerPop() {
      return this.getPartnerPopulationById(this.item.data.population_id);
    },
    shareRequestExists() {
      return !!this.getOutboundShareRequestByPartnerAndPop(
        this.partnerOrg.id,
        this.partnerPop?.id,
      );
    },
  },
  methods: {
    ...mapActions(usePartnersStore, ['getPartnerOrgById']),
    ...mapActions(usePopulationsStore, ['getPartnerPopulationById']),
    ...mapActions(useDataRequestsStore, [
      'getOutboundShareRequestByPartnerAndPop',
    ]),
    ...mapActions(useNotificationsStore, ['markRead']),
    async requestData() {
      this.$emit('action-clicked', 'Request Data');
      await this.markRead({ id: this.item.id, isRead: true });
      const query = { tab: 'shared_with_you' };
      if (!this.shareRequestExists) query.partnerPopId = this.partnerPop.id;
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.partnerOrg.id },
        query,
      });
    },
  },
};
</script>
