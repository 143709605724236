<template>
  <div class="c-feed-list">
    <div v-if="feeds.length > 0" class="c-feed-list__items">
      <component
        :is="feedListComponent(feed.integration.type)"
        v-for="feed in feeds"
        :key="'integration_' + feed.id"
        :feed="feed"
      >
        <template #left-content="{ noGrow, multipleSources }">
          <div
            class="flex items-center gap-8"
            :class="{
              'flex-1': !noGrow,
              'ml-8': multipleSources,
            }"
          >
            <BittsSvg class="w-24 h-24" :svg="feed.integration.type + 'Icon'" />
            <div class="font-medium align-center text-neutral-900 text-m">
              <span>{{ feed.integration.friendly_name }}</span>
            </div>
          </div>
        </template>
        <template #status="{ text, iconClass }">
          <span :class="iconClass" class="status-circle" />
          <span class="mr-8 text-neutral-500 text-base">
            {{ text }}
          </span>
        </template>
        <template #feed-deletion-failed>
          <BittsAlert
            class="mb-12 mx-12"
            message="We were unable to delete your data source"
            description="Try again or contact"
            color="warning"
          >
            <template #link>
              <a target="_blank" href="mailto:support@crossbeam.com"
                >support@crossbeam.com</a
              >
            </template>
            .
          </BittsAlert>
        </template>
        <template #feed-error>
          <FeedListItemError :feed="feed" class="mx-12" />
        </template>
        <template #feed-settings-popover="{ showNickname }">
          <BittsPopover
            v-if="hasPermission('manage:data-sources')"
            trigger="hover"
            placement="bottomRight"
          >
            <template #content>
              <div class="flex flex-col text-brand-navy text-sm">
                <router-link :to="feedListLink(feed)" class="overflow-item">
                  Settings
                </router-link>
              </div>
              <div
                v-if="showNickname"
                class="flex flex-col text-brand-navy text-sm"
              >
                <router-link
                  :to="`data-sources/feed/${feed.id}/rename?nickname=${feed.nickname || ''}`"
                  class="overflow-item"
                >
                  Edit Name
                </router-link>
              </div>
            </template>
          </BittsPopover>
        </template>
      </component>
    </div>
    <div v-else class="background-transparent">
      <div class="c-feed-list__empty-state-title">
        No data sources connected
      </div>
      <div
        v-if="hasPermission('manage:data-sources')"
        class="c-feed-list__empty-state-subtitle"
      >
        Choose a data source to connect from the list below.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BittsAlert, BittsPopover, BittsSvg } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import CsvFeed from '@/components/data-sources/CsvFeed.vue';
import FeedListItemError from '@/components/data-sources/FeedListItemError.vue';
import GenericFeed from '@/components/data-sources/GenericFeed.vue';
import GoogleSheetsFeed from '@/components/data-sources/GoogleSheetsFeed.vue';
import SnowflakeFeed from '@/components/data-sources/SnowflakeFeed.vue';

import useAuth from '@/composables/useAuth';
import useFeedsDeleting from '@/composables/useFeedsDeleting';
import {
  FILE_UPLOAD_DATA_SOURCE_TYPE,
  GOOGLE_SHEETS_DATA_SOURCE_TYPE,
  HS3_DATA_SOURCE_TYPE,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  SNOWFLAKE_DATA_SOURCE_TYPE,
} from '@/constants/data_sources';
import { TEMP_NEW_DATASHARING } from '@/constants/feature_flags';
import {
  useFeatureFlagStore,
  useFeedsStore,
  useFileUploadsStore,
} from '@/stores';
import { Feed } from '@/types/feeds';

import { DataSourceSettingsTabType } from './SettingsModal/constants';

const feedsStore = useFeedsStore();
const fileUploadsStore = useFileUploadsStore();
const featureFlagStore = useFeatureFlagStore();

const { hasPermission } = useAuth();
const { feeds } = storeToRefs(feedsStore);
const hasNewDataSourceSettings =
  featureFlagStore.hasFeatureFlag(TEMP_NEW_DATASHARING);

onMounted(async () => {
  await fileUploadsStore.refreshFileUploadsStore();
});

/* Poll feeds if they go into a deleting state */
useFeedsDeleting();

function feedListLink(feed: Feed) {
  if (!hasNewDataSourceSettings) {
    return { name: 'data-sources-settings', params: { id: feed.id } };
  }
  return {
    name: 'data-sources-settings-new',
    params: { id: feed.id, tab: DataSourceSettingsTabType.GENERAL },
  };
}

type FeedComponent =
  | typeof GoogleSheetsFeed
  | typeof CsvFeed
  | typeof GenericFeed
  | typeof SnowflakeFeed;

function feedListComponent(type: string): FeedComponent | undefined {
  const feedlistItemMap: Record<string, FeedComponent> = {
    [GOOGLE_SHEETS_DATA_SOURCE_TYPE]: GoogleSheetsFeed,
    [FILE_UPLOAD_DATA_SOURCE_TYPE]: CsvFeed,
    [HS3_DATA_SOURCE_TYPE]: GenericFeed,
    [PIPEDRIVE_DATA_SOURCE_TYPE]: GenericFeed,
    [SNOWFLAKE_DATA_SOURCE_TYPE]: SnowflakeFeed,
    [SALESFORCE_DATA_SOURCE_TYPE]: GenericFeed,
    [MD_DATA_SOURCE_TYPE]: GenericFeed,
  };
  return feedlistItemMap[type];
}
</script>

<style lang="pcss" scoped>
.c-feed-list {
  @apply flex flex-col text-base mb-32;
}

.c-feed-list__empty-state-title {
  @apply mb-8 text-lg text-center text-neutral-500;
}

.c-feed-list__empty-state-subtitle {
  @apply text-sm text-center text-neutral-500 pb-24;
}

.status-circle {
  @apply w-8 h-8 rounded-3 inline-block;
}

/* Styles for settings elipses */
.overflow-item {
  @apply cursor-pointer px-16 py-10;
  &:hover {
    @apply bg-primary-background-weak;
  }
}
</style>

<style lang="pcss">
.c-feed-list {
  .c-feed-list__feed {
    @apply bg-white overflow-auto border-neutral-border shadow-component border-l border-r border-t;

    &:first-of-type {
      @apply rounded-t-8;
    }

    &:last-of-type {
      @apply rounded-b-8;
    }

    &:last-child {
      @apply border-b;
    }
  }
}
</style>
