import { EVENT_SITES } from '@crossbeam/itly';

import {
  ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP,
  ATTRIBUTION_EVENT_TYPES,
} from '@/constants/attribution';
import { useCollaborateStore, usePartnersStore, useTeamStore } from '@/stores';
import { TimelineEvent } from '@/types/timeline';

import useAuth from './useAuth';
import useIteratively from './useIteratively';

export default function useTimelineContentDeux() {
  const { iteratively } = useIteratively();
  const partnersStore = usePartnersStore();
  const { currentOrg } = useAuth();
  const { getAuthorizationByUserId } = useTeamStore();
  const { getListById } = useCollaborateStore();

  const ACTION_COPY_BY_SOURCE_AND_EVENT = {
    [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'received',
    [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'sent',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: 'is no longer',
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: 'became',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'is',
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'is no longer',
    [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'sent',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: 'helped you',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]: 'was removed from',
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: 'helped you',
    [ATTRIBUTION_EVENT_TYPES.LIST_NOTE_ADDED]: 'added a note to shared list',
    [ATTRIBUTION_EVENT_TYPES.LIST_NOTE_UPDATED]:
      'updated a note in shared list',
  };
  const ATTRIBUTION_TYPE_TO_FRIENDLY_NAME: Record<string, string> = {
    sourced: 'source',
    influenced: 'with',
  };

  const createTextElement = (
    text: string,
    props: unknown = {},
    tooltip: string | undefined = undefined,
  ) => ({
    element: 'span',
    props,
    text,
    tooltip,
    icon: undefined as string[] | undefined,
  });

  const bold = (text: string) =>
    createTextElement(text, { class: 'text-neutral-text-strong font-bold' });

  const boldWeak = (text: string) =>
    createTextElement(text, { class: 'text-neutral-text-weak font-bold' });

  const normal = (text: string) => createTextElement(text);

  const italicUnderline = (text: string, tooltip?: string) =>
    createTextElement(
      text,
      { class: 'italic underline decoration-dashed' },
      tooltip,
    );

  const normalWeak = (text: string) =>
    createTextElement(text, { class: 'text-neutral-text-weak' });

  const link = (
    text: string,
    href: string,
    onClick: () => void,
    tooltip?: string,
    icon?: string[],
    openInNewTab = true,
  ) => ({
    element: 'a',
    icon,
    props: {
      href,
      ...(openInNewTab && { target: '_blank', rel: 'noopener noreferrer' }),
      class: 'text-secondary-text font-bold',
      'data-testid': 'activity-link',
      onClick,
    },
    text,
    tooltip,
  });

  const buildSimplePhrase = (
    firstVal: string,
    action: string,
    secondVal: string,
  ) => [bold(firstVal), normal(action), normal(secondVal)];

  const buildPopMovementPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
  ) => {
    if (!('population_name' in activity.data))
      throw new Error('No population name found');

    const { population_name: populationName } = activity.data;

    const phrase = [
      normalWeak(account),
      normalWeak(action),
      boldWeak(populationName),
    ];

    return phrase;
  };

  const buildOverlapPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('partner_population_name' in activity.data))
      throw new Error('No partner population name found');

    const { partner_population_name: partnerPopulationName } = activity.data;

    const phrase = [
      normal(account),
      normal(action),
      partnerPopulationName
        ? normal(partnerPopulationName)
        : italicUnderline(
            'Unavailable',
            'This population is hidden or deleted',
          ),
      normal('of'),
      bold(partnerName),
    ];

    return phrase;
  };

  const buildAttributionPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('attribution_type' in activity.data)) return;
    const { attribution_type: attributionType } = activity.data;

    const friendlyAttributionType =
      ATTRIBUTION_TYPE_TO_FRIENDLY_NAME[attributionType] ?? attributionType;

    const phrase = [
      boldWeak(partnerName),
      normalWeak(action),
      normalWeak(friendlyAttributionType),
      normalWeak(account),
    ];

    return phrase;
  };

  const buildListNotePhrase = (
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('user_id' in activity.data) || !('list_id' in activity.data)) return;
    const { user_id, list_id, action_organization_uuid } = activity.data;
    const isOwnOrg = action_organization_uuid === currentOrg.value.uuid;
    const userName = getUserName(user_id);
    const list = getListById(list_id);

    const boldFunc = activity.type === 'list_note_added' ? bold : boldWeak;
    const normalFunc =
      activity.type === 'list_note_added' ? normal : normalWeak;

    const phrase = [
      isOwnOrg
        ? userName
          ? boldFunc(userName)
          : italicUnderline(
              'Unavailable',
              'This user is not in your organization anymore',
            )
        : boldFunc(partnerName),
      normalFunc(action),
      list?.name
        ? link(
            list.name,
            `/collaborate/${list_id}`,
            () => onClickDeepLink(activity),
            undefined,
            ['fas', 'arrow-up-right'],
            false,
          )
        : italicUnderline('Unavailable', 'This list is hidden or deleted'),
    ];

    return phrase;
  };

  const buildListRecordPhrase = (
    account: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('user_id' in activity.data) || !('list_id' in activity.data)) return;
    const { user_id, list_id, action_organization_uuid } = activity.data;
    const isOwnOrg = action_organization_uuid === currentOrg.value.uuid;
    const userName = getUserName(user_id);
    const list = getListById(list_id);

    const phrase = [
      isOwnOrg
        ? userName
          ? boldWeak(userName)
          : italicUnderline(
              'Unavailable',
              'This user is not in your organization anymore',
            )
        : boldWeak(partnerName),
      normalWeak(activity.type === 'list_record_added' ? 'added' : 'removed'),
      normalWeak(account),
      normalWeak(activity.type === 'list_record_added' ? 'to' : 'from'),
      normalWeak('shared list'),
      list?.name
        ? link(
            list.name,
            `/collaborate/${list_id}`,
            () => onClickDeepLink(activity),
            undefined,
            ['fas', 'arrow-up-right'],
            false,
          )
        : italicUnderline('Unavailable', 'This list is hidden or deleted'),
    ];

    return phrase;
  };

  const buildAttributionDeletedPhrase = (
    _account: string,
    action: string,
    _activity: TimelineEvent,
    partnerName: string,
  ) => {
    const phrase = [
      normalWeak('Attribution'),
      normalWeak(action),
      boldWeak(partnerName),
    ];

    return phrase;
  };

  const buildGongPhrase = (
    account: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    if (!('mentions' in activity.data)) return;

    const { deep_link_url, mentions } = activity.data;
    const numMentions = mentions?.length ?? 0;

    let phrase = [
      bold(partnerName),
      normal('was mentioned on a call with'),
      normal(account),
    ];
    if (numMentions > 0 && deep_link_url) {
      phrase = [
        ...phrase,
        link(
          `(${numMentions})`,
          deep_link_url,
          () => onClickDeepLink(activity),
          undefined,
          ['fak', 'open-link'],
        ),
      ];
    }

    return phrase;
  };

  const buildPartnerStackPhrase = (
    account: string,
    activity: TimelineEvent,
  ) => {
    if (!('submitter_name' in activity.data)) return;

    const { submitter_name, partnerstack_partner } = activity.data;

    const phrase = [
      boldWeak(submitter_name),
      normalWeak('sent'),
      normalWeak(account),
      normalWeak('to'),
      boldWeak(partnerstack_partner),
      normalWeak('on PartnerStack'),
    ];

    return phrase;
  };

  const buildRequestPhrase = (
    account: string,
    action: string,
    activity: TimelineEvent,
    partnerName: string,
  ) => {
    let user_name: string | undefined, deep_link_url: string | undefined;
    if ('user_name' in activity.data) {
      user_name = activity.data.user_name;
      deep_link_url = activity.data.deep_link_url;
    }

    const phrase = [
      bold(user_name ?? partnerName),
      normal(action),
      normal('a'),
      deep_link_url
        ? link('message', deep_link_url, () => onClickDeepLink(activity))
        : normal('message'),
      normal('about'),
      normal(account),
    ];

    return phrase;
  };

  const buildContactAddedPhrase = (
    account: string,
    activity: TimelineEvent,
  ) => {
    if (!('contact_name' in activity.data)) return;
    const { user_id, contact_name } = activity.data;
    const userName = getUserName(user_id);

    const phrase = [
      userName
        ? boldWeak(userName)
        : italicUnderline(
            'Unavailable',
            'This user is not in your organization anymore',
          ),
      normalWeak('added'),
      boldWeak(contact_name),
      normalWeak('as a contact to'),
      normalWeak(account),
    ];

    return phrase;
  };

  const buildResearchedContactPhrase = (
    account: string,
    activity: TimelineEvent,
  ) => {
    if (!('user_id' in activity.data)) return;
    const { user_id } = activity.data;
    const userName = getUserName(user_id);

    const phrase = [
      userName
        ? boldWeak(userName)
        : italicUnderline(
            'Unavailable',
            'This user is not in your organization anymore',
          ),
      normalWeak('researched contact on Copilot'),
    ];

    return phrase;
  };

  const buildResearchedAccountPhrase = (
    account: string,
    activity: TimelineEvent,
  ) => {
    if (!('user_id' in activity.data)) return;
    const { user_id } = activity.data;
    const userName = getUserName(user_id);

    const phrase = [
      userName
        ? boldWeak(userName)
        : italicUnderline(
            'Unavailable',
            'This user is not in your organization anymore',
          ),
      normalWeak('researched account on Copilot'),
    ];

    return phrase;
  };

  const getUserName = (user_id: number) => {
    const {
      user: { first_name, last_name },
    } = getAuthorizationByUserId(user_id) ?? { user: {} };

    return first_name && last_name
      ? `${first_name} ${last_name}`
      : (first_name ?? last_name);
  };

  const phraseByActivityType = {
    [ATTRIBUTION_EVENT_TYPES.POPULATION_EXIT]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.POPULATION_ENTRY]: buildPopMovementPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: buildOverlapPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_CREATED]: buildAttributionPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_DELETED]:
      buildAttributionDeletedPhrase,
    [ATTRIBUTION_EVENT_TYPES.ATTRIBUTION_UPDATED]: buildAttributionPhrase,
  };

  const buildMarkupByActivity = (
    activity: TimelineEvent,
    accountName: string,
  ) => {
    const partner =
      (activity.partner_organization_uuid &&
        partnersStore.getPartnerOrgByUuid(
          activity.partner_organization_uuid,
        )) ||
      undefined;

    const partnerName = partner?.name ?? 'Deactivated Partner';
    const accountNameOrDeactivated = accountName ?? 'Deactivated Account';

    const { type: activityType, activity_source: activitySource } = activity;

    const phraseSubject =
      ('submitter_name' in activity.data && activity.data.submitter_name) ||
      partnerName;
    const phraseDirectObject =
      ('partnerstack_partner' in activity.data &&
        activity.data.partnerstack_partner) ||
      accountNameOrDeactivated;

    const action = ACTION_COPY_BY_SOURCE_AND_EVENT[activityType] ?? '';

    switch (activitySource) {
      case 'gong':
        return buildGongPhrase(accountName, activity, partnerName);
      case 'partnerstack':
        return buildPartnerStackPhrase(accountName, activity);
      case 'salesedge':
        return buildRequestPhrase(accountName, action, activity, partnerName);
      case 'copilot':
        if (activityType === 'user_added_contact_to_crm')
          return buildContactAddedPhrase(accountName, activity);
        if (
          [
            'user_clicked_contacts_tab',
            'user_clicked_linkedin',
            'user_copied_email',
            'user_copied_phone',
          ].includes(activityType)
        )
          return buildResearchedContactPhrase(accountName, activity);
        if (
          ['user_clicked_account_tab', 'user_clicked_play_details'].includes(
            activityType,
          )
        )
          return buildResearchedAccountPhrase(accountName, activity);
        break;
      case 'crossbeam-core':
        if (['list_note_added', 'list_note_updated'].includes(activityType))
          return buildListNotePhrase(action, activity, partnerName);
        if (['list_record_added', 'list_record_removed'].includes(activityType))
          return buildListRecordPhrase(accountName, activity, partnerName);
    }

    const phrase =
      activitySource === 'crossbeam-core' && phraseByActivityType[activityType]
        ? phraseByActivityType[activityType](
            phraseDirectObject,
            action,
            activity,
            partnerName,
          )
        : buildSimplePhrase(phraseSubject, action, phraseDirectObject);

    return action ? phrase : [normal('All event information not found')];
  };

  const friendlySourceText = (activity: TimelineEvent) => {
    const source =
      {
        [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: 'Gong',
        [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: 'Crossbeam for Sales',
        [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: 'Crossbeam for Sales',
        [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: 'Partner CRM',
        [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: 'Partner CRM',
        [ATTRIBUTION_EVENT_TYPES.PARTNERSTACK_LEAD_CREATED]: 'PartnerStack',
      }[activity.type] || 'Crossbeam';
    return `${source} Activity`;
  };

  const orgInActivity = (activity: TimelineEvent) =>
    activity.partner_organization_uuid &&
    ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP[activity.type]
      ? partnersStore.getPartnerOrgByUuid(
          activity.partner_organization_uuid,
        ) || { name: null }
      : currentOrg.value;

  const onClickDeepLink = (activity: TimelineEvent) => {
    if (!('deep_link_url' in activity.data)) return;
    iteratively.userInteractedWithTimeline({
      cta: friendlySourceText(activity),
      cta_2: orgInActivity(activity).name,
      event_site: EVENT_SITES.ATTRIBUTION_TIMELINE_EVENT,
    } as never);
  };

  return {
    buildMarkupByActivity,
    bold,
    normal,
  };
}
