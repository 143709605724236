<template>
  <div class="c-bitts-timeline">
    <template v-if="showActivities">
      <div
        v-for="[
          dateCopy,
          daysEvents,
        ] in activitiesGroupedByRelativeDateNoDupes.entries()"
        :key="dateCopy"
        class="c-bitts-timeline__date-grouping"
      >
        <div class="flex items-center mb-[-1px]">
          <h2
            class="text-neutral-text font-bold text-xs bg-neutral-background-weak px-8 py-4 rounded-12"
            data-testid="date-group-title"
          >
            {{ dateCopy }}
          </h2>
        </div>
        <AttributionTimelineEventDeux
          v-for="event in daysEvents"
          :key="event.uuid"
          :activity="event"
          :account-name="accountName"
          class="w-full ml-8"
        >
          <template #footer>
            <GongMentionsDeux v-if="isGongEvent(event)" :activity="event" />
          </template>
        </AttributionTimelineEventDeux>
      </div>
    </template>
    <div ref="load-more" class="load-more">
      <template v-if="events.length > numInitialEvents">
        <FontAwesomeIcon
          :icon="['fad', 'party-horn']"
          class="text-info-accent mr-8"
        />
        <p>You've reached the end of this timeline</p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  useTemplateRef,
} from 'vue';

import AttributionTimelineEventDeux from '@/components/attribution/AttributionTimelineEventDeux.vue';

import { ATTRIBUTION_EVENT_TYPES } from '@/constants/attribution';
import { todayYesterdayTomorrowOrCustom } from '@/date_time_utils';
import { useCollaborateStore } from '@/stores';
import { TimelineEvent } from '@/types/timeline';

import GongMentionsDeux from './records/Timeline/GongMentionsDeux.vue';

const {
  events = [],
  numInitialEvents = 15,
  accountName = '',
} = defineProps<{
  events?: TimelineEvent[];
  numInitialEvents?: number;
  accountName?: string;
}>();

const loadUntil = ref(numInitialEvents);
const showActivities = ref(false);
const scrollTemplateRef = useTemplateRef<HTMLDivElement>('load-more');
const loadMoreValue = 10;

const collaborateStore = useCollaborateStore();

const GROUPED_EVENT_LOOKUP: Record<string, string> = {
  user_clicked_contacts_tab: 'researchedContact',
  user_clicked_linkedin: 'researchedContact',
  user_copied_email: 'researchedContact',
  user_copied_phone: 'researchedContact',
  user_clicked_account_tab: 'researchedAccount',
  user_clicked_play_details: 'researchedAccount',
};

const formatTimelineDate = (luxonDate: DateTime) => {
  return luxonDate.toRelative({ unit: 'days' });
};
let intersectionObserver: IntersectionObserver | null = null;

onBeforeMount(async () => {
  await collaborateStore.readySync;
});
onMounted(() => {
  const options = {
    rootMargin: '30px',
    threshold: 0,
  };
  intersectionObserver = new IntersectionObserver(onIntersection, options);
  if (!scrollTemplateRef.value) return;

  intersectionObserver.observe(scrollTemplateRef.value);
  const rect = scrollTemplateRef.value.getBoundingClientRect();
  const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

  if (isVisible) {
    triggerLoadMore();
  }
});

const triggerLoadMore = () => {
  loadUntil.value += loadMoreValue;
  showActivities.value = true;
};

onBeforeUnmount(() => {
  intersectionObserver?.disconnect();
});

const onIntersection = (entries: IntersectionObserverEntry[]) => {
  const [loadMoreElement] = entries;
  if (loadMoreElement?.isIntersecting) {
    triggerLoadMore();
  }
};

const groupedEvents = computed(() => {
  return events?.reduce((acc, currentEvent) => {
    const eventDate = DateTime.fromISO(currentEvent.triggered_at);
    const formattedDate: string = todayYesterdayTomorrowOrCustom(
      eventDate,
      false,
      formatTimelineDate,
    );

    const currentEvents = acc.get(formattedDate) ?? [];
    const previousEvent = currentEvents.at(-1);

    const virtualType = GROUPED_EVENT_LOOKUP[currentEvent.type];

    if (virtualType) {
      // Check if a similar virtual type event has already been added for the day
      const alreadyVisitedVirtualType = currentEvents.some(
        (event) => GROUPED_EVENT_LOOKUP[event.type] === virtualType,
      );

      if (alreadyVisitedVirtualType) return acc;
    }
    const isSameEventType = previousEvent?.type === currentEvent.type;

    const isSamePartner =
      previousEvent?.partner_organization_uuid ===
      currentEvent.partner_organization_uuid;

    const isSameData = isEqual(previousEvent?.data, currentEvent.data);

    const isSamePopulationName =
      previousEvent?.data &&
      currentEvent.data &&
      'partner_population_name' in previousEvent.data &&
      'partner_population_name' in currentEvent.data &&
      previousEvent.data.partner_population_name ===
        currentEvent.data.partner_population_name;

    const isOverlapMovement =
      previousEvent?.type &&
      [
        ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY,
        ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT,
      ].includes(previousEvent.type);

    const isDuplicate =
      (isSameEventType && isSamePartner && isSameData) ||
      (isOverlapMovement &&
        isSameEventType &&
        isSamePartner &&
        isSamePopulationName);

    if (!isDuplicate) {
      currentEvents.push(currentEvent);
    }

    acc.set(formattedDate, currentEvents);
    return acc;
  }, new Map<string, TimelineEvent[]>());
});

/* using a Map to guarantee insertion order */
const activitiesGroupedByRelativeDateNoDupes = computed(() => {
  // Flatten the Map into an array and apply slicing
  const allEvents = Array.from(groupedEvents.value.values()).flat();
  const slicedEvents = allEvents.slice(0, loadUntil.value);

  // Rebuild the Map with sliced events
  const slicedGroupedEvents = slicedEvents.reduce((acc, currentEvent) => {
    const eventDate = DateTime.fromISO(currentEvent.triggered_at);
    const formattedDate: string = todayYesterdayTomorrowOrCustom(
      eventDate,
      false,
      formatTimelineDate,
    );

    const currentEvents = acc.get(formattedDate) ?? [];
    currentEvents.push(currentEvent);

    acc.set(formattedDate, currentEvents);
    return acc;
  }, new Map<string, TimelineEvent[]>());

  return slicedGroupedEvents;
});

const isGongEvent = (event: TimelineEvent) => event.activity_source === 'gong';
</script>

<style scoped lang="pcss">
.c-bitts-timeline {
  @apply pb-24;
}

.c-bitts-timeline__date-grouping {
  & :nth-child(1 of .timeline-event) {
    @apply pt-12;
  }

  & .timeline-event:not(:nth-last-of-type(1)) {
    @apply pb-16;
  }

  & .timeline-event:nth-last-of-type(1) {
    @apply pb-12;
  }
}
.load-more {
  @apply flex items-center justify-center mt-8 text-neutral-text;
}
</style>
