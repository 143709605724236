<template>
  <ReportCellWrapper
    class="pl-0 pr-36"
    :to="params.value.reportLink"
    :trial-over="params.value.trialOver"
  >
    <ReportOverflowMenu
      v-if="params.value.hasWritePermissions"
      :errors="params.value.report.status?.errors || []"
      :hide-export="params.value.hideExport"
      :is-on-reports-page="params.value.isOnReportsPage"
      :report="params.value.report"
      :report-type="params.value.reportType"
      :partner-org-ids="partnerOrgIds"
      :trial-over="params.value.trialOver"
    />
  </ReportCellWrapper>
</template>

<script>
import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';
import ReportOverflowMenu from '@/components/reports/ReportOverflowMenu.vue';

export default {
  name: 'ReportOverflowMenuCell',
  components: { ReportOverflowMenu, ReportCellWrapper },
  computed: {
    partnerOrgIds() {
      if (!this.params.value.partnersInReport) return [];
      return this.params.value.partnersInReport.map((partner) => partner.id);
    },
  },
};
</script>
