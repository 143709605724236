import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { orderBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useIteratively from '@/composables/useIteratively';
import useOfflinePartner from '@/composables/useOfflinePartner';
import usePopulationShares from '@/composables/usePopulationShares';
import { validateAndConvertOperandDataTypes } from '@/constants/populations';
import {
  STANDARD_POPULATION_TYPES,
  emptyPopulation,
  getPopulationByStandardType,
} from '@/constants/standard_populations';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useDataRequestsStore,
  useDataSharesStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';
import urls from '@/urls';

export default function usePopulations(
  partnerOrgId = null,
  offlinePartnerUuid = null,
) {
  const populationsStore = usePopulationsStore();
  const sourcesStore = useSourcesStore();
  const dataSharesStore = useDataSharesStore();
  const billingStore = useBillingStore();
  const dataRequestsStore = useDataRequestsStore();
  const flashesStore = useFlashesStore();
  const router = useRouter();
  const route = useRoute();

  /* Offline partner state */
  const { offlineSources, refreshOfflineData } = useOfflinePartner();

  const { iteratively } = useIteratively();

  const {
    populations,
    populationStats,
    customPopulations,
    inactivePopulations,
  } = storeToRefs(populationsStore);
  const { isFreeTier, customPopulationLimit } = storeToRefs(billingStore);

  onMounted(async () => {
    await Promise.all([
      populationsStore.readySync,
      sourcesStore.readySync,
      dataSharesStore.readySync,
      dataRequestsStore.readySync,
    ]);
    if (offlinePartnerUuid) await refreshOfflineData(offlinePartnerUuid);
  });

  const { addMetadataToPopulation } = usePopulationShares();

  const allPopulations = computed(() => [
    customersPopulation.value,
    prospectsPopulation.value,
    openOppsPopulation.value,
  ]);

  /* Standard populations */
  const openOppsPopulation = computed(() =>
    buildPopulation('open_opportunities'),
  );
  const customersPopulation = computed(() => buildPopulation('customers'));
  const prospectsPopulation = computed(() => buildPopulation('prospects'));
  const standardPopulations = computed(() =>
    allPopulations.value.filter((population) => !population.isEmpty),
  );

  /* Custom populations */
  const customPopsWithStatsAndSharing = computed(() => {
    if (populations.value) {
      const base = customPopulations.value;
      if (isFreeTier.value) base.push(...inactiveCustomPops.value);
      return orderBy(
        base.map((pop) => addMetadataToPopulation(pop, partnerOrgId)),
        [(pop) => pop.name.toLowerCase()],
      );
    }
    return [];
  });

  const inactiveCustomPops = computed(() => {
    return inactivePopulations.value.filter(
      (pop) => !STANDARD_POPULATION_TYPES.includes(pop.standard_type),
    );
  });

  const atOrAboveCap = computed(
    () => customPopulations.value.length >= customPopulationLimit.value,
  );
  const isEarlyAdopter = computed(
    () => customPopulations.value.length > customPopulationLimit.value,
  );

  /* CRUD Operations for Populations */
  async function previewPopulation(population, filterGroupList) {
    try {
      const payload = makePreviewPayload(population, filterGroupList);
      const { data } = await axios.post(urls.populations.preview_v3, payload);
      return data;
    } catch (err) {
      captureException(err);
      flashesStore.addUnhandledError(err);
      return INITIAL_DATA;
    }
  }

  async function previewPopulationCount(population, filterGroupList) {
    try {
      const payload = makePreviewPayload(population, filterGroupList);
      const { data } = await axios.post(
        urls.populations.previewCount_v3,
        payload,
      );
      return { count: data.count, timeout: false };
    } catch (err) {
      const timeoutError = err.status === 504;
      if (!timeoutError) {
        flashesStore.addUnhandledError(err);
      }
      captureException(err);
      return { count: 0, timeout: timeoutError };
    }
  }

  async function savePopulation(population, payload) {
    const isPatch = !route.path.includes('create');
    const axiosOperation = isPatch ? axios.patch : axios.post;
    let url = '';

    if (offlinePartnerUuid)
      url = isPatch
        ? urls.offlinePartners.populations.patch(
            population.id,
            offlinePartnerUuid,
          )
        : urls.offlinePartners.populations.create(offlinePartnerUuid);
    else
      url = isPatch
        ? urls.populations.patch_v3(population.id)
        : urls.populations.create_v3;

    const response = await axiosOperation(url, payload);
    const parseResponse = (x) =>
      isPatch ? { ...x, meta: population.meta } : { ...x, meta: [] };
    const newPopulation = parseResponse(response.data);

    if (!isPatch) {
      const iterativelyPayload = {
        population_name: newPopulation.name,
        population_id: newPopulation.id.toString(),
        event_site: EVENT_SITES.POPULATION_DETAIL,
      };
      iteratively.userCreatedPopulation(iterativelyPayload);
    }

    const scrubbedPopName = scrubString(population.name);
    const message = `${scrubbedPopName} has been ${isPatch ? 'updated' : 'created'}`;
    flashesStore.addSuccessFlash({
      message,
      description: 'It will take some time for these changes to be reflected.',
    });
    populationsStore.upsertPopulation(newPopulation);
    await dataSharesStore.refreshDataSharesStore();
    return newPopulation;
  }

  async function deletePopulation(population) {
    const name = population.name;
    const id = population.id;
    const url = urls.populations.delete(id);
    await axios.delete(url);
    populationsStore.removePopulation(id);
    dataSharesStore.removeDataShareByPopulationId(id);
    dataRequestsStore.removeDataRequestByPopulationId(id);
    flashesStore.addSuccessFlash({ message: `${name} Deleted` });
    await router.push({ name: 'populations' });
  }

  /* Helpers 🤝 */

  function buildPopulation(type) {
    const population = getPopulationByStandardType(type, populations.value);
    if (!population) return emptyPopulation(type);
    return addMetadataToPopulation(population, partnerOrgId);
  }

  function makePopulationPayload(
    population,
    populationName,
    filterGroupList,
    standardType = null,
  ) {
    const isPatch = !route.path.includes('create');
    const payload = {
      name: populationName,
      description: population.description || null,
      standard_type: standardType,
      source_filter_expressions: formatFiltersForPayload(
        population.name,
        filterGroupList,
      ),
    };

    if (!isPatch) payload.source_id = population.source_id;

    return payload;
  }

  const scrubString = (string) => {
    const lt = /</g;
    const gt = />/g;
    const sq = /'/g;
    const dq = /"/g;
    return string
      .replace(lt, '&lt;')
      .replace(gt, '&gt;')
      .replace(sq, '&#39;')
      .replace(dq, '&#34;');
  };

  /* Saving + Editing Population */
  function formatFiltersForPayload(populationName, groupList = []) {
    const filters = {};

    const allSources = offlinePartnerUuid
      ? offlineSources.value
      : sourcesStore.sources;

    groupList.forEach((group) => {
      const completedFilterParts = group.filter_parts.filter(
        (item) => item && !item.isEdit,
      );
      const convertedFilterParts = completedFilterParts.map((filterPart) => {
        const sourceField = allSources
          .find((s) =>
            s.fields.find((f) => filterPart.source_field_id === f.id),
          )
          .fields.find((f) => filterPart.source_field_id === f.id);
        sourcesStore.getSourceFieldById(filterPart.source_field_id);
        if (sourceField) {
          const { convertedOperands, operandValidationErrors } =
            validateAndConvertOperandDataTypes(
              sourceField.data_type,
              filterPart.operand,
              filterPart.operator,
            );
          if (operandValidationErrors.length) {
            const error = new Error(
              `Operand Validation Errors: ${populationName}`,
            );
            captureException(error);
          }
          return {
            ...filterPart,
            operand: convertedOperands,
          };
        }
      });
      filters[group.tableName] = {
        filter_expression: group.filter_expression,
        filter_parts: convertedFilterParts,
      };
    });
    return filters;
  }

  function makePreviewPayload(population, filterGroupList) {
    const payload = {
      base_schema: population.base_schema,
      base_table: population.base_table,
      source_filter_expressions: formatFiltersForPayload(
        population.name,
        filterGroupList,
      ),
      source_id: population.source_id,
    };

    if (offlinePartnerUuid)
      payload.offline_partner_org_uuid = offlinePartnerUuid;

    return payload;
  }

  const INITIAL_DATA = {
    fields: [],
    rows: [],
  };

  function getRecordNumber(population) {
    // TODO: Change w/ offline partner data
    if (!population) return;
    return populationStats.value.find(
      (stat) => stat.population_id === population.id,
    )?.total_size;
  }

  return {
    INITIAL_DATA,
    populations,
    inactivePopulations,
    allPopulations,
    customersPopulation,
    standardPopulations,
    getRecordNumber,
    /* Custom Pops */
    customPopsWithStatsAndSharing,
    customPopulations,
    atOrAboveCap,
    isEarlyAdopter,
    /* CRUD */
    deletePopulation,
    savePopulation,
    previewPopulation,
    previewPopulationCount,
    makePopulationPayload,
  };
}
