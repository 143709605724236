import add from '../assets/svg/add.svg';
import alertTriangle from '../assets/svg/alert-triangle.svg';
import armLine from '../assets/svg/arm-line.svg';
import connectorLogo from '../assets/svg/billing/connector-logo.svg';
import downgrade from '../assets/svg/billing/downgrade.svg';
import explorerLogo from '../assets/svg/billing/explorer-logo.svg';
import removeSeats from '../assets/svg/billing/remove-seats.svg';
import supernodeLogo from '../assets/svg/billing/supernode-logo.svg';
import bittsEmptyStateReports from '../assets/svg/bitts-empty-state-reports.svg';
import buySeats from '../assets/svg/buy-seats.svg';
import chromeIcon from '../assets/svg/chrome-icon.svg';
import close from '../assets/svg/close.svg';
import coSellGrey from '../assets/svg/co-sell-grey.svg';
import computer from '../assets/svg/computer.svg';
import connectorSummitLogo from '../assets/svg/connector-summit-logo.svg';
import companyIcon from '../assets/svg/crossbeam-company-icon.svg';
import companyRecord from '../assets/svg/crossbeam-company-record.svg';
import crossbeamCoreLogo from '../assets/svg/crossbeam-core-logo.svg';
import crossbeamCore from '../assets/svg/crossbeam-core.svg';
import dataSourceIcon from '../assets/svg/crossbeam-data-source.svg';
import crossbeamForSalesLogo from '../assets/svg/crossbeam-for-sales-logo.svg';
import crossbeamForSalesNoText from '../assets/svg/crossbeam-for-sales-no-text.svg';
import crossbeamForSalesTag from '../assets/svg/crossbeam-for-sales-tag.svg';
import personIcon from '../assets/svg/crossbeam-person-icon.svg';
import personRecord from '../assets/svg/crossbeam-person-record.svg';
import closeIoIcon from '../assets/svg/data-sources/close-io-icon.svg';
import closeIo from '../assets/svg/data-sources/close-io.svg';
import copperIcon from '../assets/svg/data-sources/copper-icon.svg';
import copper from '../assets/svg/data-sources/copper.svg';
import fileUploadIcon from '../assets/svg/data-sources/file_upload-icon.svg';
import fileUpload from '../assets/svg/data-sources/file_upload.svg';
import freshdeskIcon from '../assets/svg/data-sources/freshdesk-icon.svg';
import freshdesk from '../assets/svg/data-sources/freshdesk.svg';
import googleSheetsIcon from '../assets/svg/data-sources/google_sheets-icon.svg';
import googleSheets from '../assets/svg/data-sources/google_sheets.svg';
import hubspotIcon from '../assets/svg/data-sources/hubspot-icon.svg';
import hubspot from '../assets/svg/data-sources/hubspot.svg';
import microsoftDynamicsIcon from '../assets/svg/data-sources/microsoft_dynamics-icon.svg';
import microsoftDynamics from '../assets/svg/data-sources/microsoft_dynamics.svg';
import netsuiteIcon from '../assets/svg/data-sources/netsuite-icon.svg';
import netsuite from '../assets/svg/data-sources/netsuite.svg';
import nutshellIcon from '../assets/svg/data-sources/nutshell-icon.svg';
import nutshell from '../assets/svg/data-sources/nutshell.svg';
import oracleIcon from '../assets/svg/data-sources/oracle-icon.svg';
import oracle from '../assets/svg/data-sources/oracle.svg';
import pipedriveIcon from '../assets/svg/data-sources/pipedrive-icon.svg';
import pipedrive from '../assets/svg/data-sources/pipedrive.svg';
import salesforceIcon from '../assets/svg/data-sources/salesforce-icon.svg';
import salesforce from '../assets/svg/data-sources/salesforce.svg';
import snowflakeIcon from '../assets/svg/data-sources/snowflake-icon.svg';
import snowflake from '../assets/svg/data-sources/snowflake.svg';
import zendeskSellIcon from '../assets/svg/data-sources/zendesk-sell-icon.svg';
import zendeskSell from '../assets/svg/data-sources/zendesk-sell.svg';
import zohoIcon from '../assets/svg/data-sources/zoho-icon.svg';
import zoho from '../assets/svg/data-sources/zoho.svg';
import doubleDots from '../assets/svg/double-dots.svg';
import dummyCompany1 from '../assets/svg/dummy-company-1.svg';
import dummyCompany2 from '../assets/svg/dummy-company-2.svg';
import dummyCompany3 from '../assets/svg/dummy-company-3.svg';
import emptyStateFreeAccount from '../assets/svg/empty-state-free-account.svg';
import emptyStateHome from '../assets/svg/empty-state-home.svg';
import emptyStateNoOrg from '../assets/svg/empty-state-no-org.svg';
import emptyStatePartnerNotSharing from '../assets/svg/empty-state-partner-not-sharing.svg';
import emptyStatePartnersBitts from '../assets/svg/empty-state-partners-bitts.svg';
import emptyStatePartners from '../assets/svg/empty-state-partners.svg';
import emptyStatePopulationsCustomers from '../assets/svg/empty-state-populations-customers.svg';
import emptyStateOpenOpps from '../assets/svg/empty-state-populations-open-opportunities.svg';
import emptyStatePopulations from '../assets/svg/empty-state-populations.svg';
import emptyStateReportsBlue from '../assets/svg/empty-state-reports-blue.svg';
import emptyStateReports from '../assets/svg/empty-state-reports.svg';
import emptyStateRequestData from '../assets/svg/empty-state-request-data.svg';
import emptyStateSharing from '../assets/svg/empty-state-sharing.svg';
import ghostTable from '../assets/svg/ghost-table.svg';
import gong from '../assets/svg/gong.svg';
import marketo from '../assets/svg/integrations/marketo.svg';
import partnerStackAllBlueLogo from '../assets/svg/integrations/partner-stack-all-blue.svg';
import partnerStackAltLogo from '../assets/svg/integrations/partner-stack-new-logo.svg';
import partnerStack from '../assets/svg/integrations/partner-stack.svg';
import linkedinInLogo from '../assets/svg/linkedin-in-logo.svg';
import lockedOut from '../assets/svg/locked-out.svg';
import logoWhite from '../assets/svg/logo-white.svg';
import logo from '../assets/svg/logo.svg';
import logoNoText from '../assets/svg/logo_no_text.svg';
import missingPartnerPopulationInfo from '../assets/svg/missing-partner-population-info.svg';
import notSharingData from '../assets/svg/not-sharing-data.svg';
import confetti from '../assets/svg/onboarding/confetti.svg';
import csvIcon from '../assets/svg/onboarding/csv-icon.svg';
import csv from '../assets/svg/onboarding/csv.svg';
import database from '../assets/svg/onboarding/database.svg';
import pamFace from '../assets/svg/onboarding/pam-face.svg';
import onboardingChecklistDoneGrey from '../assets/svg/onboarding-checklist-done-grey.svg';
import onboardingChecklistDone from '../assets/svg/onboarding-checklist-done.svg';
import onboardingChecklistItem from '../assets/svg/onboarding-checklist-item.svg';
import onboardingLoading from '../assets/svg/onboarding-loading.svg';
import onboardingLock from '../assets/svg/onboarding-lock.svg';
import opportunityHighlight from '../assets/svg/opportunity-highlight.svg';
import orgLogo01 from '../assets/svg/org-01.svg';
import organization from '../assets/svg/organization.svg';
import pam from '../assets/svg/PAM.svg';
import partnerbaseLogoTextLight from '../assets/svg/partnerbase-logo-text-light.svg';
import partnerbaseLogoText from '../assets/svg/partnerbase-logo-text.svg';
import partnerbaseLogo from '../assets/svg/partnerbase-logo.svg';
import partners from '../assets/svg/partners.svg';
import partnerStackGrey from '../assets/svg/partnerstack-grey-logo.svg';
import pricingConnectorTier from '../assets/svg/pricing-connector-tier.svg';
import pricingExplorerTier from '../assets/svg/pricing-explorer-tier.svg';
import pricingSupernodeTier from '../assets/svg/pricing-supernode-tier.svg';
import prideFlag from '../assets/svg/pride-flag.svg';
import qrMask from '../assets/svg/qr-mask.svg';
import request from '../assets/svg/request.svg';
import revealLogoNamePurple from '../assets/svg/reveal-logo-name-purple.svg';
import revealLogoPurple from '../assets/svg/reveal-logo-purple.svg';
import salesEdge from '../assets/svg/sales-edge.svg';
import salesloftGrey from '../assets/svg/salesloft-grey.svg';
import slackChannel from '../assets/svg/slack-channel.svg';
import slackIcon from '../assets/svg/slack-icon.svg';
import slackPrivate from '../assets/svg/slack-private.svg';
import slackShared from '../assets/svg/slack-shared.svg';
import spinner from '../assets/svg/spinner.svg';
import threads from '../assets/svg/threads.svg';
import timelineNoEventsYet from '../assets/svg/timeline-no-events-yet.svg';
import upgrade from '../assets/svg/upgrade.svg';
import userLogo05 from '../assets/svg/user-05.svg';
import xRed from '../assets/svg/x-red.svg';
import x from '../assets/svg/x.svg';

export const TAG_MAPPING = {
  add,
  bittsEmptyStateReports,
  logo,
  logoWhite,
  logoNoText,
  organization,
  slackIcon,
  slackChannel,
  slackPrivate,
  slackShared,
  chromeIcon,
  alertTriangle,
  pricingExplorerTier,
  pricingConnectorTier,
  pricingSupernodeTier,
  linkedinInLogo,
  marketo,
  emptyStateHome,
  emptyStateFreeAccount,
  emptyStateNoOrg,
  emptyStateOpenOpps,
  emptyStatePartners,
  emptyStatePartnersBitts,
  emptyStatePopulations,
  emptyStatePopulationsCustomers,
  emptyStatePartnerNotSharing,
  emptyStateReports,
  emptyStateReportsBlue,
  emptyStateRequestData,
  emptyStateSharing,
  missingPartnerPopulationInfo,
  notSharingData,
  ghostTable,
  companyRecord,
  personRecord,
  personIcon,
  companyIcon,
  dataSourceIcon,
  request,
  partners,
  threads,
  close,
  orgLogo01,
  userLogo05,
  doubleDots,
  spinner,
  qrMask,
  computer,
  salesEdge,
  crossbeamCore,
  crossbeamForSalesLogo,
  crossbeamForSalesTag,
  crossbeamCoreLogo,
  buySeats,
  lockedOut,
  dummyCompany1,
  dummyCompany2,
  dummyCompany3,
  partnerbaseLogoText,
  partnerbaseLogoTextLight,
  partnerbaseLogo,
  armLine,
  prideFlag,
  explorerLogo,
  connectorLogo,
  supernodeLogo,
  pam,
  connectorSummitLogo,
  removeSeats,
  downgrade,
  gong,
  onboardingChecklistItem,
  onboardingChecklistDone,
  onboardingChecklistDoneGrey,
  onboardingLoading,
  onboardingLock,
  closeIo,
  closeIoIcon,
  copper,
  copperIcon,
  freshdesk,
  freshdeskIcon,
  netsuite,
  netsuiteIcon,
  nutshell,
  nutshellIcon,
  oracle,
  oracleIcon,
  pipedrive,
  pipedriveIcon,
  zendeskSell,
  zendeskSellIcon,
  zoho,
  zohoIcon,
  fileUpload,
  fileUploadIcon,
  googleSheets,
  googleSheetsIcon,
  hubspot,
  hubspotIcon,
  hubspotV3: hubspot,
  hubspotV3Icon: hubspotIcon,
  microsoftDynamics,
  microsoftDynamicsIcon,
  salesforce,
  salesforceIcon,
  snowflake,
  snowflakeIcon,
  pamFace,
  database,
  confetti,
  csv,
  csvIcon,
  partnerStackAllBlueLogo,
  partnerStackAltLogo,
  partnerStack,
  partnerStackGrey,
  coSellGrey,
  salesloftGrey,
  timelineNoEventsYet,
  opportunityHighlight,
  crossbeamForSalesNoText,
  revealLogoNamePurple,
  revealLogoPurple,
  upgrade,
  x,
  xRed,
};

export type SvgNames = keyof typeof TAG_MAPPING;
