<template>
  <div class="c-population-rule">
    <div class="c-population-rule_item">
      <div class="font-bold text-m">{{ title }}</div>
      <BittsTag v-if="isRequired" color="info" size="small" variant="rounded">
        Required
      </BittsTag>
    </div>
    <div v-if="recordCount !== null" class="c-population-rule_item">
      <BittsTag color="secondary" size="small" variant="rounded">
        {{ `${recordCount} ${pluralize(recordCount, 'record')}` }}
      </BittsTag>
      <BittsButton
        type="neutral"
        variant="outline"
        size="small"
        :center-icon="['fak', 'edit']"
        @click="handleEditPopulationRow"
      />
    </div>
    <BittsButton
      v-else
      :left-icon="['far', 'plus']"
      text="Create"
      size="small"
      @click="handleCreatePopulationRow"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsTag } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  PopulationType,
  isRequiredMapping,
} from '@//components/data-sources/SettingsModal/constants';

const { population, recordCount = null } = defineProps<{
  population: PopulationType;
  recordCount?: number; // TODO: Remove this prop
}>();
const router = useRouter();
const route = useRoute();

const isRequired = computed(() => {
  return isRequiredMapping[population];
});

const title = computed(() => {
  switch (population) {
    case PopulationType.CUSTOMERS:
      return 'Customers';
    case PopulationType.PROSPECTS:
      return 'Prospects';
    case PopulationType.OPEN_OPPS:
      return 'Open Opportunities';
    case PopulationType.STRATEGICS:
      return 'Strategic Accounts';
    default:
      return '';
  }
});

async function handleCreatePopulationRow() {
  await router.push({ name: 'create-population', params: route.params });
}

function handleEditPopulationRow() {
  console.log('Edit population rule');
}
</script>

<style scoped lang="pcss">
.c-population-rule {
  @apply flex items-center justify-between p-16;
}
.c-population-rule_item {
  @apply flex gap-10 items-center;
}
</style>
