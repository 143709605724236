<template>
  <BittsButton
    text="Log in"
    class="w-full"
    :loading="popupOpen"
    :disabled="popupOpen"
    @click="standardLogin"
  />
  <BittsButton
    data-testid="sso-login-button"
    text="Continue with SSO"
    class="w-full"
    variant="outline"
    :disabled="popupOpen"
    @click="ssoLogin"
  />
  <BittsAlert
    v-if="!!errorMessage"
    :message="errorMessage"
    class="w-full my-8"
    color="error"
  />
</template>

<script setup>
import { BittsAlert, BittsButton } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import appConfig from '@/config';

const props = defineProps({
  login: { type: Function, required: true },
  toggleSso: { type: Function, required: true },
  popupOpen: { type: Boolean, required: true },
});

useHead({ title: 'Login - Crossbeam' });

const xbLoginLink = `https://${appConfig.host}/login`;

const route = useRoute();
const ssoId = computed(() => route.query.sso ?? null);

const standardLogin = () => {
  props.login(`${xbLoginLink}?`);
};

function ssoLogin() {
  if (ssoId.value === null || ssoId.value === '') {
    props.toggleSso();
    return;
  }

  return props.login(`https://${appConfig.host}/login?sso=${ssoId.value}&`);
}

const attemptedAuth = computed(() => route.query.attempted_auth);
const errorMessage = computed(
  () => emailAlreadyExists.value || route.query?.errorDescription,
);
const emailAlreadyExists = computed(() => {
  if (!route.query.email_already_exists) {
    return null;
  }

  const signInOption =
    attemptedAuth.value === 'auth0' ? 'Google' : 'an Email and Password';
  return `Please try signing in using ${signInOption}.`;
});
</script>
