import { computed, onBeforeMount, onUnmounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useFlashesStore, usePartnersStore, useTeamStore } from '@/stores';
import {
  RoleAccess,
  UserAccess,
  UserAccessWithAuthorization,
} from '@/types/roles';
import { User } from '@/types/root';

const searchUserInputValue = ref('');
const roles = ref<RoleAccess[]>([]);
const people = ref<UserAccess[]>([]);
const showUserDropdown = ref(false);
const roleToggles = reactive<Record<string, boolean>>({});
const authorizedUserList = ref<UserAccessWithAuthorization[]>([]);

const isUserAlreadyAdded = (user: User) =>
  authorizedUserList.value.some(
    (authorizedUser) => authorizedUser.authorization?.user.id === user.id,
  );
export function usePartnershipTeamAccess(
  partnerUuid: string | undefined,
  shouldTriggerOnMounted = false,
) {
  const { coreUsers } = useTeamStore();
  const { fetchVisibility, updateVisibility } = usePartnersStore();
  const flashesStore = useFlashesStore();
  const router = useRouter();
  const route = useRoute();

  const loading = ref(true);

  const filteredUsers = computed(() =>
    coreUsers.filter((coreUser) => {
      const userName = `${coreUser.user.first_name} ${coreUser.user.last_name}`;
      const regex = new RegExp(searchUserInputValue.value.trim(), 'i'); // case-insensitive regex
      return regex.test(userName);
    }),
  );

  const hasChangesRoles = computed(() =>
    roles.value.some(
      (role: RoleAccess) => role.has_access !== roleToggles[role.role_name],
    ),
  );
  const hasChangesPeople = computed(() => {
    const peopleIds = people.value.map((item) => item.user_id);
    const authorizedUserIds = authorizedUserList.value.map(
      (item) => item.user_id,
    );
    return (
      peopleIds.length !== authorizedUserIds.length ||
      peopleIds.some((id) => !authorizedUserIds.includes(id))
    );
  });
  const hasChanges = computed(
    () => hasChangesRoles.value || hasChangesPeople.value,
  );

  onBeforeMount(async () => {
    if (!shouldTriggerOnMounted) return;
    if (!partnerUuid) {
      flashesStore.addErrorFlash({
        message: 'Unable to load roles access.',
      });
      loading.value = false;
      return;
    }

    const data = await fetchVisibility(partnerUuid);

    // Storing roles and people twice:
    // - one to keep track of actual BE data
    // - second to track user updates (and formatted for display purposes)
    if (data) {
      // Roles
      roles.value = data.roles;
      data.roles.forEach((role: RoleAccess) => {
        roleToggles[role.role_name] = role.has_access;
      });

      // People
      people.value = data.users;
      authorizedUserList.value = data.users.map((p: UserAccess) => ({
        ...p,
        authorization: coreUsers.find(
          (coreUser) => coreUser.user.id === Number(p.user_id),
        ),
      }));
    }

    loading.value = false;
  });

  function onSelectPeople(user: User) {
    if (isUserAlreadyAdded(user)) return;
    authorizedUserList.value.unshift({
      authorization: coreUsers.find(
        (coreUser) => coreUser.user.id === Number(user.id),
      ),
      id: '',
      user_id: user.id,
      has_access: true,
      permissions: [],
      permission_removable: true,
    });
    showUserDropdown.value = false;
    searchUserInputValue.value = '';
  }

  function onRemovePeople(index: number) {
    authorizedUserList.value.splice(index, 1);
  }

  async function handleSave() {
    loading.value = true;

    const payload = {
      users: authorizedUserList.value.map((authorizedUser) => ({
        user_id: authorizedUser.user_id,
        has_access: authorizedUser.has_access,
      })),
      roles: roles.value.map((role) => ({
        role_id: role.role_id,
        has_access: Boolean(roleToggles[role.role_name]),
      })),
    };
    await updateVisibility(partnerUuid as string, payload, close);

    loading.value = false;
  }

  onUnmounted(() => {
    Object.keys(roleToggles).forEach((key) => {
      roleToggles[key] = false;
    });
  });

  async function close() {
    // Redirect to the page where the modal was open,
    // or to the partner details page
    await router.push(
      (router.options.history.state.back as string) || {
        name: 'partner_details',
        params: { partner_org_id: route.params.partner_org_id },
      },
    );
  }

  return {
    authorizedUserList,
    close,
    filteredUsers,
    handleSave,
    hasChanges,
    isUserAlreadyAdded,
    loading,
    onRemovePeople,
    onSelectPeople,
    roles,
    roleToggles,
    searchUserInputValue,
    showUserDropdown,
  };
}
