<template>
  <div
    :class="{
      open: visible,
      [`bitts-modal--${variant}`]: true,
    }"
    class="o-bitts-modal"
    @click="closeModal(true)"
  >
    <dialog
      ref="dialog"
      :class="{
        mobile: isMobile,
        [containerClass]: true,
        flex: hasSidebar,
        'overflow-hidden': hasSidebar,
      }"
      :open="visible"
      @click.stop
    >
      <div class="sidebar" v-if="hasSidebar">
        <div class="sidebar--header">
          <slot name="sidebar-header">
            <slot name="sidebar-header-prefix" />
            <h3 class="o-bitts-modal__sidebar-title">{{ sidebarTitle }}</h3>
          </slot>
        </div>
        <div class="sidebar--content">
          <slot name="sidebar-content" />
        </div>
        <div class="sidebar--footer">
          <slot name="sidebar-footer" />
        </div>
      </div>
      <div class="flex flex-col flex-1">
        <BittsButton
          v-if="showCloseButton && !confirmationModal"
          :disabled="actionLoading"
          class="close-icon"
          type="neutral"
          variant="ghost"
          size="large"
          data-testid="bitts-modal-close-button"
          :center-icon="['fas', 'xmark']"
          center-icon-classes="w-24 h-24"
          @click.stop="closeModal()"
        />
        <div class="o-bitts-modal__header">
          <div class="o-bitts-modal__header__image">
            <!-- This slot may also be used for SVGS, components, etc -->
            <slot name="image">
              <div v-if="icon" :class="[iconColor]" class="icon-background">
                <FontAwesomeIcon class="icon" :icon="icon" />
              </div>
            </slot>
          </div>
          <slot name="header">
            <div>
              <h3
                class="o-bitts-modal__title"
                data-testid="bitts-modal-title"
                :class="{
                  'pt-0': !icon,
                  'mb-8': !!description,
                }"
              >
                {{ title }}
              </h3>
              <p
                v-if="description"
                class="o-bitts-modal__description"
                data-testid="bitts-modal-description"
              >
                {{ description }}
              </p>
            </div>
          </slot>
        </div>
        <div class="o-bitts-modal__content">
          <BittsLoading :is-loading="loading">
            <div class="o-bitts-modal__content--background">
              <slot />
            </div>
          </BittsLoading>
        </div>
        <div
          v-if="!hideFooter"
          class="o-bitts-modal__footer"
          :class="[
            !showPrimaryButton && 'o-bitts-modal__footer-no-secondary-button',
          ]"
        >
          <slot name="footer">
            <slot name="secondary-button">
              <BittsButton
                v-if="backButton"
                data-testid="bitts-modal--back-button"
                class="o-bitts-modal__back-button"
                text="Go Back"
                size="large"
                type="neutral"
                variant="ghost"
                :left-icon="['fas', 'arrow-left']"
                :disabled="actionLoading"
                @click.stop="emit('back')"
              />
              <BittsButton
                v-else
                data-testid="bitts-modal--secondary-button"
                class="o-bitts-modal__secondary-button"
                :text="secondaryButtonText"
                size="large"
                type="neutral"
                variant="fill"
                :disabled="actionLoading"
                @click.stop="handleSecondaryClick"
              />
            </slot>
            <slot name="primary-button">
              <BittsButton
                v-if="showPrimaryButton"
                data-testid="bitts-modal--primary-button"
                :loading="actionLoading"
                :type="primaryButtonType"
                class="o-bitts-modal__primary-button"
                :text="primaryButtonText"
                :disabled="disabled"
                size="large"
                @click.stop="emit('action')"
              />
            </slot>
          </slot>
        </div>
      </div>
    </dialog>
  </div>
</template>

<script lang="ts">
import { useScreenSize } from '@crossbeam/pointbreak';
import { StrictExclude } from '@crossbeam/types';

import { computed, onMounted, onUnmounted, ref } from 'vue';

import BittsButton, { BittsButtonType } from '../BittsButton/BittsButton.vue';
import BittsLoading from '../BittsLoading/BittsLoading.vue';
import { BittsIcon } from '../types';

export type BittsModalTwoVariant =
  | 'primary'
  | 'data'
  | 'tall'
  | 'confirm'
  | 'info'
  | 'sidebar';
export interface BittsModalTwoProps {
  title?: string;
  description?: string;
  showCloseButton?: boolean;
  width?: number | 'max-content' | 'auto';
  variant?: BittsModalTwoVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  showPrimaryButton?: boolean;
  primaryButtonType?: StrictExclude<BittsButtonType, 'upsell' | 'white'>;
  icon?: BittsIcon;
  iconColor?: 'neutral' | 'info';
  backButton?: boolean;
  /** Controls whether the modal can be closed by clicking outside of it. */
  useMaskToClose?: boolean;
  loading?: boolean;
  actionLoading?: boolean;
  hideFooter?: boolean;
  noPadding?: boolean;
  /* additional classes to add to the dialog container, mainly to be used for responsive helpers */
  containerClass?: string;
  disabled?: boolean;
  /* Sidebar props */
  sidebarTitle?: string;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsModalTwoProps>(), {
  title: undefined,
  description: undefined,
  showCloseButton: true,
  hideFooter: false,
  width: 800,
  variant: 'primary',
  primaryButtonText: 'Save',
  secondaryButtonText: 'Cancel',
  showPrimaryButton: true,
  primaryButtonType: 'primary',
  icon: undefined,
  iconColor: 'neutral',
  backButton: false,
  useMaskToClose: false,
  loading: false,
  actionLoading: false,
  noPadding: false,
  containerClass: '',
  disabled: false,
  sidebarTitle: '',
});

const emit = defineEmits(['closed', 'action', 'secondary-action', 'back']);

const dialog = ref<HTMLDialogElement | null>(null);
const { isMobile } = useScreenSize();

const visible = defineModel({ type: Boolean, default: true });

function handleSecondaryClick() {
  emit('secondary-action');
  closeModal(false);
}

function closeModal(fromMask = false) {
  if (props.actionLoading || (fromMask && !props.useMaskToClose)) return;
  if (dialog.value) {
    dialog.value.close?.();
    visible.value = false;
  }
  emit('closed');
}

const hasSidebar = computed(() => props.variant === 'sidebar');

let originalPaddingRight = '';

onMounted(() => {
  // Save the original padding-right to restore it later
  originalPaddingRight = document.body.style.paddingRight || '';

  // Prevent body scrolling
  // Also calculate the scrollbar width and add it as padding-right to prevent layout shift
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  document.body.classList.add('overflow-hidden');
  document.body.style.paddingRight = scrollbarWidth
    ? `${scrollbarWidth}px`
    : originalPaddingRight;
});

onUnmounted(() => {
  // Restore the original padding-right and allow body scrolling
  document.body.classList.remove('overflow-hidden');
  document.body.style.paddingRight = originalPaddingRight;
});

const computedWidth = computed(() =>
  props.width === 'max-content' ? 'max-content' : `${props.width}px`,
);
const confirmationModal = computed(() => props.variant === 'confirm');

const contentPadding = computed(() => (props.noPadding ? '0' : '0 16px'));
const contentMargin = computed(() => (props.noPadding ? '0' : '24px'));
const dialogPadding = computed(() => (props.noPadding ? '0' : '16px'));
</script>
<style lang="pcss" scoped>
.o-bitts-modal {
  @apply fixed left-0 top-0 flex items-center h-[100vh] w-[100vw];
  &.open {
    @apply bg-black/30 z-[1040]; /* Higher than everything except for other Ant-D dropdowns, etc. */
  }
  &:not(.open) {
    @apply z-[-1];
  }
  dialog {
    @apply rounded-16 opacity-100 border-neutral-border border shadow-overlay overflow-auto;
    width: v-bind(computedWidth);
    padding-bottom: v-bind(dialogPadding);

    &:not(.mobile) {
      max-height: calc(100% - 24px);
      max-width: calc(100vw - (36px));
    }

    &.mobile {
      @apply w-full h-full rounded-none flex flex-col;

      .o-bitts-modal__footer {
        @apply mt-auto;
      }
    }

    .close-icon {
      @apply absolute top-16 right-16 cursor-pointer text-neutral-text;
      &:hover {
        @apply opacity-70;
      }
    }

    .o-bitts-modal__header {
      @apply flex-none px-24 pb-24 pt-40;
      .o-bitts-modal__title {
        @apply text-neutral-text-strong font-bold text-xl text-center w-full;
      }

      .o-bitts-modal__description {
        @apply text-neutral-text text-m text-center ml-24 mb-24;
      }

      .icon-background {
        @apply rounded-full w-120 h-120 px-24 pt-12 mb-8 bg-top-gradient from-neutral-accent/20 to-neutral-accent/0  text-neutral-accent;
        .icon {
          @apply mt-12 text-[64px];
        }
        &.neutral {
          @apply text-neutral-accent from-neutral-accent/20 to-neutral-accent/0;
        }
        &.info {
          @apply text-info-accent from-info-accent/20 to-info-accent/0;
        }
      }
    }

    .o-bitts-modal__content {
      @apply flex-1 overflow-y-auto;
      padding: v-bind(contentPadding);
    }

    .o-bitts-modal__footer {
      @apply flex flex-none justify-between pt-16 px-24 gap-24;
    }
    .o-bitts-modal__footer-no-secondary-button {
      @apply justify-end border-none p-16;
    }
  }

  /* The different types of modals */
  &.bitts-modal--primary {
    dialog {
      .o-bitts-modal__header {
        @apply px-24 pb-16 bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
      }
    }

    .o-bitts-modal__footer {
      @apply border-t border-neutral-border;
    }
    .o-bitts-modal__header {
      @apply flex flex-col items-center pb-24;
    }
    .o-bitts-modal__content {
      @apply mb-24 rounded-8;
      margin-left: v-bind(contentMargin);
      margin-right: v-bind(contentMargin);
    }
  }

  &.bitts-modal--data {
    .o-bitts-modal__content {
      margin-left: v-bind(contentMargin);
      margin-right: v-bind(contentMargin);
    }
    .o-bitts-modal__header {
      @apply pt-24;
      .o-bitts-modal__title {
        @apply text-left;
      }
      .o-bitts-modal__description {
        @apply text-left;
      }
    }
    .o-bitts-modal__header {
      @apply border-b border-neutral-border;
    }
    .o-bitts-modal__footer {
      @apply border-t border-neutral-border;
    }
  }

  &.bitts-modal--tall {
    .o-bitts-modal__header {
      @apply flex flex-col items-center;
    }
    .o-bitts-modal__content--background {
      @apply pb-24 px-24 pt-16 bg-neutral-50 rounded-8;
    }
    .o-bitts-modal__content {
      @apply pb-24 px-40 bg-neutral-background;
    }
    .o-bitts-modal__footer {
      @apply bg-neutral-background pb-16 rounded-b-16;
      .c-bitts-btn {
        @apply flex-1;
      }
    }
    dialog {
      @apply pb-0;
    }
  }

  &.bitts-modal--sidebar {
    .sidebar--header {
      @apply flex items-center border-b border-neutral-border pb-28 justify-start pl-16;
    }

    .o-bitts-modal__header {
      @apply border-b border-neutral-border flex flex-row p-0 pb-4;
    }
    .o-bitts-modal__header {
      .o-bitts-modal__title {
        @apply p-0 my-24 text-left ml-24;
      }
    }
    .o-bitts-modal__sidebar-title {
      @apply text-neutral-text-strong font-bold text-xl text-center;
    }
    .sidebar--content {
      @apply flex flex-col gap-4 px-16 py-16;
    }
    .sidebar {
      @apply bg-neutral-background min-w-[280px] pt-24 border-r border-neutral-border mb-[-16px] flex flex-col;
    }
    .o-bitts-modal__footer {
      @apply justify-end gap-8 border-t border-neutral-border;
    }
    .sidebar--footer {
      @apply mt-auto pl-16 pb-24;
    }
  }

  &.bitts-modal--info,
  &.bitts-modal--confirm {
    dialog {
      @apply w-[400px];
      &.mobile {
        @apply w-full h-full rounded-none;
      }
    }
    .o-bitts-modal__header {
      @apply flex flex-col items-center;
    }
    .o-bitts-modal__content {
      @apply hidden;
    }
    .o-bitts-modal__footer {
      @apply pb-16 border-t border-neutral-border mt-auto;
      .c-bitts-btn {
        @apply flex-1;
      }
    }
    dialog {
      @apply pb-0;
    }
  }

  &.bitts-modal--info {
    .o-bitts-modal__secondary-button {
      @apply hidden;
    }
    .o-bitts-modal__footer {
      @apply border-none;
    }
  }
}
</style>
