<template>
  <BittsModalTwo
    :use-mask-to-close="true"
    @closed="handleCloseModal"
    :class="[pointbreak]"
    class="c-onboarding-choose-data-modal"
    :width="modalWidth"
    title="Connect a Data Source"
    data-testid="onboarding-choose-data-modal"
    :description="description"
  >
    <ConnectDataSourceCard variant="crm" />
    <div class="g-sheets-and-csv">
      <ConnectDataSourceCard variant="googleSheets" />
      <ConnectDataSourceCard variant="csv" />
    </div>
    <template #footer>
      <div class="w-full flex flex-col items-center">
        <p class="text-neutral-text-weak text-center">
          Crossbeam is SOC II and ISO 27001 certified. It also complies with
          Data Regulations, including GDPR.
        </p>
        <p class="flex items-center">
          <BittsButton
            text="Start a security review"
            variant="ghost"
            type="neutral"
            @click="console.log('TODO: Security review link')"
            size="small"
            class="px-4"
          />
          <span class="text-neutral-text-weak text-sm mt-1">or</span>
          <BittsButton
            text="Book a meeting with us"
            variant="ghost"
            type="neutral"
            @click="handleClickBookMeeting"
            size="small"
            class="px-4"
          />
        </p>
      </div>
    </template>
  </BittsModalTwo>
</template>

<script setup>
import { BittsButton, BittsModalTwo } from '@crossbeam/bitts';
import { usePointbreak } from '@crossbeam/pointbreak';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useTeamStore } from '@/stores';

import ConnectDataSourceCard from './ConnectDataSourceCard.vue';

const router = useRouter();
const teamStore = useTeamStore();
const { hasCrmAdmin } = storeToRefs(teamStore);

const description = hasCrmAdmin.value
  ? `Google Spreadsheet is the best alternative for you to test Crossbeam until your CRM Admin connects your company’s CRM`
  : 'Salesforce is the recommended data source for you to use Crossbeam';

async function handleCloseModal() {
  await router.push({ name: 'home' });
}

async function handleClickBookMeeting() {
  await router.push({ name: 'onboarding_book_meeting_modal' });
}

/* Mobile rendering */
const pointbreak = usePointbreak();
const modalWidth = computed(() =>
  !['xl', 'xxl'].includes(pointbreak.value) ? null : 1200,
);
</script>

<style lang="pcss">
.c-onboarding-choose-data-modal {
  &.o-bitts-modal.bitts-modal--primary dialog .o-bitts-modal__header {
    @apply border-none pt-[88px];
  }
  &.o-bitts-modal.bitts-modal--primary dialog .o-bitts-modal__footer {
    @apply border-none pb-[88px];
  }
  .o-bitts-modal__header {
    @apply pt-64;
  }
  .o-bitts-modal__description {
    @apply max-w-[660px] mt-4;
  }
  .o-bitts-modal__content--background {
    @apply w-[660px] h-[376px] flex flex-row gap-16 mx-auto my-48;
  }
  .g-sheets-and-csv {
    @apply flex gap-16 flex-col;
  }

  &.sm,
  &.xs {
    .o-bitts-modal__content--background {
      @apply w-full h-auto flex flex-col items-center gap-16;
    }
    .g-sheets-and-csv {
      @apply w-full max-w-[420px];
    }
  }
}
</style>
