<template>
  <BittsCollapse
    expand-icon-position="end"
    :is-default-open="true"
    :show-underline="false"
    class="px-12 py-8 bg-neutral-50/50 rounded-lg"
  >
    <template #header="{ isOpen }">
      <div class="flex items justify-between">
        <h6 class="text-info-text text-base/6 font-bold">
          Partner Populations
        </h6>
        <p class="text-neutral-text-weak text-base/6 font-normal">{{
          isOpen ? 'Collapse' : 'Expand'
        }}</p>
      </div>
    </template>
    <h6 class="text-neutral-text-strong text-base/6 font-bold">
      Default settings
    </h6>
    <p class="text-neutral-text text-sm"
      >Editing this setting will affect the partners below</p
    >
    <PartnerPopulationsStandardSettings
      :integration-profile-settings="integrationProfileSettings"
      :is-disabled
      :setting-type="settingType"
      @update-settings="updateDefaultPartnerSettings"
    />
    <IntegrationSettingsPartnerDefaultsSwitch
      class="my-16"
      :is-checked="
        !integrationProfileSettings?.exclude_partnerships_created_since ?? true
      "
      :is-disabled
      @update:exclude-partnerships-created-since="
        updateExcludePartnershipsCreatedSince
      "
    />
    <IntegrationSettingsPartnerSpecificTree
      :integration-profile-settings="integrationProfileSettings"
      :is-disabled
      :setting-type="settingType"
      @update-settings="updatePartnerOverrides"
    />
  </BittsCollapse>
</template>

<script setup lang="ts">
import { BittsCollapse } from '@crossbeam/bitts';

import { MinimalIntegrationProfile } from '@/types/integration_profiles';

import IntegrationSettingsPartnerDefaultsSwitch from './IntegrationSettingsPartnerDefaultsSwitch.vue';
import IntegrationSettingsPartnerSpecificTree from './IntegrationSettingsPartnerSpecificTree.vue';
import PartnerPopulationsStandardSettings from './IntegrationSettingsPartnerStandardPopulations.vue';

withDefaults(
  defineProps<{
    integrationProfileSettings?: MinimalIntegrationProfile | null;
    isDisabled?: boolean;
    settingType: string;
  }>(),
  { integrationProfileSettings: undefined, isDisabled: false },
);
const emit = defineEmits<{
  (
    e: 'update:partner-default-settings' | 'update:partner-overrides',
    payload: MinimalIntegrationProfile,
  ): void;
  (e: 'update:exclude-partnerships-created-since', isChecked: boolean): void;
}>();

const updateDefaultPartnerSettings = (payload: MinimalIntegrationProfile) => {
  emit('update:partner-default-settings', payload);
};
const updatePartnerOverrides = (payload: MinimalIntegrationProfile) => {
  emit('update:partner-overrides', payload);
};
const updateExcludePartnershipsCreatedSince = (isChecked: boolean) => {
  emit('update:exclude-partnerships-created-since', isChecked);
};
</script>
