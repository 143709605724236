<template>
  <div class="timeline-event" :data-testid="`timeline-event-${activity?.type}`">
    <div class="w-full">
      <button
        v-if="
          activity.activity_source === 'gong' &&
          'mentions' in activity.data &&
          activity.data.mentions?.length
        "
        class="mr-4 text-neutral-accent"
        @click="showAll = !showAll"
        type="button"
      >
        <FontAwesomeIcon
          :icon="['fak', 'chevron-right']"
          :class="{ 'rotate-90': showAll }"
          class="transition-all"
        />
      </button>
      <AttributionTimelineDynamicContentDeux
        :activity="activity"
        :account-name="accountName"
      />
      <slot name="footer" v-if="showAll" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { TimelineEvent } from '@/types/timeline';

import AttributionTimelineDynamicContentDeux from './AttributionTimelineDynamicContentDeux.vue';

const { activity, accountName = '' } = defineProps<{
  activity: TimelineEvent;
  accountName?: string;
}>();

defineSlots<{
  footer: object;
}>();

const showAll = ref(false);
</script>

<style scoped lang="pcss">
.timeline-event {
  @apply flex border-l border-neutral-border pl-12;
}
</style>
