<template>
  <div class="c-new-and-recurring-business-settings">
    <BittsSelect
      v-model="selectedFeedId"
      :options="feedOptions"
      class="w-260 mb-16"
      :searchable="false"
      form-label="Data Source"
      placeholder="Select data source"
      option-type="svg"
      :disabled="isDataSourceInputDisabled"
      data-testid="data-source-select"
    />
    <BittsSelect
      v-if="selectedFeedId"
      v-model="selectedOpportunityFieldId"
      :options="opportunityFieldOptions"
      class="w-260 mb-16"
      form-label="Opportunity Field"
      placeholder="Select opportunity field"
      data-testid="opportunity-field-select"
    />

    <div class="filter-groups" v-if="operandOptions.length">
      <BusinessSettingsFilterGroup
        v-model="selectedOperandsLookup.newBusiness"
        title="New Business"
        :operand-options="newBusinessOptions"
        :is-multi-select
        class="mb-16"
      />
      <BusinessSettingsFilterGroup
        v-model="selectedOperandsLookup.recurringBusiness"
        title="Recurring Business"
        :operand-options="recurringBusinessOptions"
        :is-multi-select
      />
    </div>
    <BittsButton
      text="Save changes"
      size="small"
      data-testid="save-settings-button"
      @click="saveSettings"
      :disabled="!isValidSettings"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsSelect } from '@crossbeam/bitts';

import { watch } from 'vue';

import useBusinessSettings from '@/composables/useBusinessSettings';

import BusinessSettingsFilterGroup from './BusinessSettingsFilterGroup.vue';

const {
  selectedFeedId,
  selectedOperandsLookup,
  selectedOpportunityFieldId,
  opportunityFieldOptions,
  dealSourceFromSelectedFeed,
  feedOptions,
  operandOptions,
  newBusinessOptions,
  recurringBusinessOptions,
  isValidSettings,
  isDataSourceInputDisabled,
  isMultiSelect,
  resetAll,
  resetOperands,
  fetchFilterableSources,
  fetchOperandOptions,
  buildPayload, // just for exposing to tests
  saveSettings,
} = useBusinessSettings();

/* root input - if this is reset we should hydrate everything */
watch(
  selectedFeedId,
  async (newId, oldId) => {
    const dealSourceId = dealSourceFromSelectedFeed.value?.id;
    if (newId === oldId || !dealSourceId) return;

    resetAll();

    opportunityFieldOptions.value = await fetchFilterableSources(dealSourceId);
  },
  { immediate: true },
);

watch(selectedOpportunityFieldId, async (newFieldId, oldFieldId) => {
  if (!newFieldId) return;

  if (newFieldId !== oldFieldId) resetOperands();

  operandOptions.value = await fetchOperandOptions(newFieldId);
});

defineExpose({
  buildPayload,
});
</script>

<style scoped lang="pcss">
.filter-groups {
  @apply border-l border-neutral-border pl-24 mb-16;
}
</style>
