<template>
  <BittsLoading :is-loading="!allStoresReady">
    <BittsCard :no-padding="false" class="c-connector-upgrade-card">
      <div class="card-content">
        <div class="flex justify-between items-center">
          <h3 class="title"> Connector </h3>
          <BittsLink
            :show-arrow="true"
            text="View all features"
            url="https://www.crossbeam.com/pricing/"
            :open-in-new-tab="true"
          />
        </div>
        <p class="description">
          {{ upgradeCardDescription }}
        </p>
      </div>
      <ul class="feature-list">
        <li
          v-for="(feature, i) in features"
          :key="`feature__${i}`"
          class="feature-list__item"
        >
          <FontAwesomeIcon
            :icon="['fak', 'check']"
            class="feature-list__check"
          />
          <component
            :is="feature.tooltip ? BittsTooltip : 'span'"
            placement="right"
          >
            <span
              :class="{
                'cursor-pointer': feature.tooltip,
              }"
              class="feature-list__text"
              v-html="
                injectStrings(feature.text, {
                  values: [feature.injection],
                  underline: true,
                })
              "
            />
            <template #title>
              {{ feature.tooltip }}
            </template>
          </component>
        </li>
      </ul>
      <BillingPlanComparisonTag class="mt-24" />
    </BittsCard>
  </BittsLoading>
</template>
<script setup>
import {
  BittsCard,
  BittsLink,
  BittsLoading,
  BittsTooltip,
} from '@crossbeam/bitts';

import { computed } from 'vue';

import BillingPlanComparisonTag from '@/components/billing/BillingPlanComparisonTag.vue';

import useBilling from '@/composables/useBilling';
import { BILLING_IMPROVEMENTS_COPY } from '@/constants/billing';
import { injectStrings } from '@/utils';

const { allStoresReady } = useBilling();

const upgradeCardDescription = computed(
  () => BILLING_IMPROVEMENTS_COPY.connector.description,
);

const features = computed(
  () => BILLING_IMPROVEMENTS_COPY.connector.featuresLongDescription,
);
</script>
<style lang="pcss">
.c-connector-upgrade-card {
  @apply p-24 mb-24;
  .title {
    @apply text-neutral-text-strong font-bold text-m mb-4;
  }
  .description {
    @apply text-neutral-text max-w-[90%];
  }
  .feature-list {
    @apply grid gap-8 mt-24 items-start;

    .feature-list__item {
      @apply flex items-center gap-4 justify-start;
    }
    .feature-list__check {
      @apply text-primary-background-medium w-16 h-16 mr-2;
    }
    .feature-list__text {
      @apply text-neutral-text;
    }
  }
  .c-connector-upgrade-card__cta {
    background: var(
      --background-upsell-gradient-weak,
      linear-gradient(
        92deg,
        theme(colors.upsell.background-weak) 0%,
        theme(colors.upsell.background-weak / 0.2) 100%
      )
    );
    @apply mb-16 p-8 rounded-8 text-upsell-text text-sm;
    .c-connector-upgrade-card__cta__icon {
      @apply text-upsell-accent text-16  mr-2 ml-6;
    }
  }
}
</style>
