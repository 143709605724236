<template>
  <div class="c-partnerships-settings">
    <BittsModalTwo
      :width="1400"
      sidebar-title="Settings"
      :title="title"
      :description="description"
      variant="sidebar"
      :loading="loading"
      @closed="close"
      @action="handleAction"
      :disabled="saving || loading || !hasChanges"
      :action-loading="saving"
      :hide-footer="!hasManageVisibilityPermission"
    >
      <template #sidebar-header-prefix>
        <PartnerIcon :partner-uuid="partner?.uuid" class="mr-12" />
      </template>
      <template #sidebar-footer>
        <BittsButton
          v-if="hasManagePartnerInvitePermission"
          :disabled="saving"
          type="danger"
          text="Delete partnership"
          variant="ghost"
          :left-icon="['fak', 'delete']"
          data-testid="c-settings-delete"
          @click="showDeleteModal"
        />
      </template>
      <template #sidebar-content>
        <ModalSidebarLink
          route-name="partnership-settings"
          v-for="(item, i) in sidebarItems"
          :item="item"
          :key="item.tab"
          default-tab="team-access"
          :class="{
            'mt-auto': i === sidebarItems.length - 1,
          }"
        />
      </template>
      <div class="c-partnerships-settings__content py-12">
        <component :is="TabComponent" class="min-h-[600px]" v-bind="tabProps" />
      </div>
    </BittsModalTwo>
    <router-view-wrapper />
  </div>
</template>

<script setup lang="ts">
import { BittsButton, BittsModalTwo } from '@crossbeam/bitts';

import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ModalSidebarLink, {
  ModalSidebarItem,
} from '@/components/ModalSidebarLink.vue';
import TeamAccessTab from '@/components/partners/DataSharingSettings/SettingsModal/TeamAccessTab.vue';
import PartnerIcon from '@/components/partners/PartnerIcon.vue';

import useAuth from '@/composables/useAuth';
import { usePartnershipTeamAccess } from '@/composables/usePartnershipTeamAccess';
import { usePartnersStore } from '@/stores';

export type PartnershipSettingsTabType = 'team-access';

const router = useRouter();
const route = useRoute();
const partnersStore = usePartnersStore();

const { hasPermission } = useAuth();
const hasManageVisibilityPermission = computed(() =>
  hasPermission('manage:partner-visibility'),
);

const hasManagePartnerInvitePermission = computed(() =>
  hasPermission('manage:partnerships'),
);

const tab = computed(() => route.query.tab as PartnershipSettingsTabType);
const partnerId = computed(() => Number(route.params.partner_org_id as string));
const partner = computed(
  () => partnersStore.getPartnerOrgById(partnerId.value, false) || null,
);

const {
  close,
  handleSave: handleSaveTeamAccess,
  hasChanges,
} = usePartnershipTeamAccess(partner.value?.uuid);

const loading = ref(false);
const saving = ref(false);

const sidebarItems = [
  {
    label: 'Team Access',
    icon: ['fad', 'user-group'],
    tab: 'team-access',
  },
] as ModalSidebarItem<PartnershipSettingsTabType>[];

const TabComponent = computed(() => {
  switch (tab.value) {
    case 'team-access':
    default:
      return TeamAccessTab;
  }
});

const tabProps = computed(() => {
  switch (tab.value) {
    case 'team-access':
    default:
      return { partnerUuid: partner.value?.uuid };
  }
});

const title = computed(() => {
  switch (tab.value) {
    case 'team-access':
    default:
      return `${hasManageVisibilityPermission.value ? 'Manage' : 'View'} Access`;
  }
});

const description = computed(() => {
  switch (tab.value) {
    case 'team-access':
    default:
      return `${hasManageVisibilityPermission.value ? 'Edit' : 'View'} roles and people who have access to ${partner.value?.name} in Crossbeam`;
  }
});

// The action will be different depending on which tab is focused. The actions
// should be written in composables that share the state and handlers across each
// tab and this main component.
async function handleAction() {
  switch (tab.value) {
    case 'team-access':
      await handleSaveTeamAccess();
      break;
    default:
      await console.log('handleAction');
  }
}

async function showDeleteModal() {
  await router.push({ name: 'delete_partner' });
}
</script>

<style lang="pcss" scoped>
.c-partnerships-settings {
  @apply py-24 px-0;
}

.c-partnerships-settings__content {
  @apply flex flex-col;
}
</style>
