<template>
  <ReportCellWrapper
    :trial-over="params.value.trialOver"
    :to="params.value.reportLink"
    :use-tooltip="true"
    :class="{ 'cursor-not-allowed': !!params.value.trialOver }"
    class="flex flex-row justify-between items-center max-w-full"
  >
    <div
      :class="{
        'w-[91.5%]': isInErrorState,
        'w-full': !isInErrorState,
        'pointer-events-none': !!params.value.trialOver,
      }"
      class="flex flex-col justify-center pl-8 gap-8"
    >
      <component
        :is="tooltipOrDiv"
        v-bind="tooltipProps"
        class="flex items-center"
      >
        <div
          :draggable="isDraggable"
          class="report-name-cell__report-name"
          :class="{ 'report-name-cell__report-name-draggable': isDraggable }"
          @drag="onDrag"
          @dragstart="onDragStart"
          @dragend="onDragEnd"
        >
          {{ reportName }}
        </div>
        <template #title>
          {{ reportName }}
        </template>
      </component>
      <div v-if="!folderId" class="report-name-cell__unfiled"> Unfiled </div>
      <div v-else class="flex items-center">
        <span class="text-neutral-500 text-base leading-none"> in </span>
        <FontAwesomeIcon
          :icon="['fak', 'folder']"
          :style="{ height: '16px', width: '16px', color: 'currentColor' }"
          class="text-info-accent mx-4"
        />
        <span class="report-name-cell__folder-name">
          {{ getFolderName(folderId) }}
        </span>
      </div>
    </div>
    <BittsTooltip
      v-if="isInErrorState"
      :class="{ 'pointer-events-none': !!params.value.trialOver }"
      :mount-to-body="true"
      placement="bottom"
    >
      <FontAwesomeIcon
        :icon="['fas', 'diamond-exclamation']"
        class="text-danger-accent h-16 w-24"
      />
      <template #title>
        {{ ERROR_STATE_TO_TOOLTIP_TEXT[errorState] }}
      </template>
    </BittsTooltip>
  </ReportCellWrapper>
</template>

<script>
import { BittsTooltip } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapState } from 'pinia';

import ReportCellWrapper from '@/components/reports/ReportCellWrapper.vue';

import {
  INVALID_OWN_FILTERS,
  INVALID_PARTNER_FILTERS,
  NO_OWN_POPULATIONS,
  NO_PARTNER_POPULATIONS,
} from '@/constants/reports';
import { useReportsStore } from '@/stores';

const MAX_REPORT_NAME_LENGTH = 27;
const BOTH_FILTERS_INVALID = 'both-filters-invalid';

const ERROR_STATE_TO_TOOLTIP_TEXT = {
  [NO_OWN_POPULATIONS]:
    'You have no populations in this report. Add populations to this report to view records',
  [NO_PARTNER_POPULATIONS]:
    'There are no partner’s populations in this report. Edit this report and add partner populations to view records',
  [BOTH_FILTERS_INVALID]:
    "Some of your filters were removed because your partner's data sharing settings and the fields you are syncing changed",
  [INVALID_OWN_FILTERS]:
    'Some of your filters were removed because the fields you are syncing from your data source changed',
  [INVALID_PARTNER_FILTERS]:
    "Some of your filters were removed because your partner's data sharing settings changed",
};

export default {
  name: 'ReportsNameAndFolderCell',
  components: { BittsTooltip, FontAwesomeIcon, ReportCellWrapper },
  data() {
    return { ERROR_STATE_TO_TOOLTIP_TEXT };
  },
  computed: {
    ...mapState(useReportsStore, ['folders']),
    errors() {
      if (!this.isInErrorState) return [];
      return this.params.value.report.status.errors.map((err) => err.type);
    },
    errorState() {
      if (!this.isInErrorState) return null;
      if (this.errors.includes(NO_OWN_POPULATIONS)) return NO_OWN_POPULATIONS;
      if (this.errors.includes(NO_PARTNER_POPULATIONS))
        return NO_PARTNER_POPULATIONS;
      const invalidOwnFilters = this.errors.includes(INVALID_OWN_FILTERS);
      if (invalidOwnFilters && this.errors.includes(INVALID_PARTNER_FILTERS))
        return BOTH_FILTERS_INVALID;
      return invalidOwnFilters ? INVALID_OWN_FILTERS : INVALID_PARTNER_FILTERS;
    },
    foldersElement() {
      if (document) return document.getElementById('report-folders');
      return {};
    },
    folderId() {
      return this.params.value.report.folder_uuid;
    },
    isDraggable() {
      return (
        this.params.value.isOnReportsPage &&
        this.params.value.hasWritePermissions
      );
    },
    isInErrorState() {
      return !!this.params.value.report.status?.errors.length;
    },
    reportName() {
      return this.params.value.report.name;
    },
    showTooltip() {
      if (this.isInErrorState) return false;
      if (this.reportName)
        return this.reportName.length > MAX_REPORT_NAME_LENGTH;
      return false;
    },
    tooltipOrDiv() {
      return this.showTooltip ? 'BittsTooltip' : 'div';
    },
    tooltipProps() {
      const props = {};
      if (this.showTooltip) {
        props.trigger = 'hover';
        props.placement = 'bottom';
        props.mountToBody = true;
        props.defaultSlotClasses =
          'report-name-cell-report-name-tooltip-trigger';
      }
      return props;
    },
  },
  methods: {
    onDragStart(ev) {
      ev.dataTransfer.setData('text', this.params.value.report.id);
    },
    onDrag() {
      this.foldersElement.scrollIntoView({
        behavior: 'smooth',
      });
    },
    getFolderName(uuid) {
      const folder = this.folders.find((folder) => folder.id === uuid);
      // this shouldn't happen but I am just being defensive
      return folder ? folder.name : 'Unfiled';
    },
  },
};
</script>

<style lang="pcss" scoped>
.report-name-cell__folder-name {
  @apply text-neutral-500 text-base leading-none truncate;
  max-width: 140px;
}

.report-name-cell__report-name {
  @apply rounded-bts-md leading-none max-w-full truncate;
}

.report-name-cell__report-name-draggable {
  cursor: grab;
}

:deep(.report-name-cell-report-name-tooltip-trigger) {
  @apply max-w-full;
}

.report-name-cell__unfiled {
  @apply text-neutral-500 text-base opacity-50 leading-none;
}
</style>
