import { uniq } from 'lodash';

export default function useDataTemplates() {
  const accountMapping = {
    type: 'account_mapping',
    friendly_name: 'Account Map',
    description:
      'Compare your data with partners to identify overlapping prospects, opportunities, and customers',
    summary: 'Unlocks Account Mapping',
    salesforce: {
      Account: [
        'Id',
        'OwnerId',
        'Name',
        'Type',
        'Website',
        'BillingCountry',
        'Industry',
        'NumberOfEmployees',
        'CreatedDate',
      ],
      Opportunity: ['Id', 'AccountId', 'Name', 'StageName'],
    },
    hubspot_v3: {
      companies: [
        'id',
        'hubspot_owner_id',
        'name',
        'type',
        'website',
        'country',
        'closedate',
        'numberofemployees',
        'industry',
        'createdate',
      ],
      deals: [
        'id',
        'dealstage',
        'dealname',
        'pipeline',
        'dealstage_label',
        'pipeline_label',
      ],
    },
    microsoft_dynamics: {
      accounts: [
        'accountid',
        'ownerid',
        'name',
        'customertypecode',
        'websiteurl',
        'address1_country',
        'industrycode',
        'numberofemployees',
        'createdon',
      ],
      opportunities: ['opportunityid', 'parentaccountid', 'name', 'salesstage'],
    },
    pipedrive: {
      organization: ['id', 'owner_id', 'name', 'add_time', 'address_country'],
      deal: ['id', 'org_id', 'creator_user_id', 'title', 'stage_id'],
      person: ['id', 'org_id', 'email'],
    },
  };

  const coMarket = {
    type: 'co_market',
    friendly_name: 'Co-Market',
    description:
      'Target overlapping prospects, opportunities, and customers for your better together stories and integration outreach',
    summary: 'Unlocks Opportunity Reporting',
    salesforce: {
      Account: [...accountMapping.salesforce.Account],
      Opportunity: uniq([
        ...accountMapping.salesforce.Opportunity,
        'Amount',
        'IsClosed',
        'IsWon',
        'CreatedDate',
        'CloseDate',
      ]),
    },
    hubspot_v3: {
      companies: [...accountMapping.hubspot_v3.companies],
      deals: uniq([
        ...accountMapping.hubspot_v3.deals,
        'amount',
        'days_to_close',
        'hs_is_closed_won',
        'hs_date_entered_closedwon',
        'hs_date_exited_closedwon',
        'hs_is_closed',
        'createdate',
        'closedate',
      ]),
    },
    microsoft_dynamics: {
      accounts: [...accountMapping.microsoft_dynamics.accounts],
      opportunities: uniq([
        ...accountMapping.microsoft_dynamics.opportunities,
        'budgetamount',
        'createdon',
        'actualclosedate',
        'statecode',
      ]),
    },
    pipedrive: {
      organization: [...accountMapping.pipedrive.organization],
      deal: uniq([
        ...accountMapping.pipedrive.deal,
        'value',
        'add_time',
        'update_time',
        'close_time',
        'won_time',
        'lost_time',
        '_xbeam_is_won',
        '_xbeam_is_closed',
      ]),
      person: [...accountMapping.pipedrive.person],
    },
  };

  const pipelineMapping = {
    type: 'pipeline_mapping',
    friendly_name: 'Pipeline Map',
    description:
      'Accelerate opportunities and forecast revenue more accurately with more visibility into your partner’s pipeline',
    summary: 'Unlocks Potential Revenue',
    salesforce: {
      Account: uniq([...accountMapping.salesforce.Account]),
      Opportunity: [...coMarket.salesforce.Opportunity],
      User: ['Id', 'Name', 'Email'],
    },
    hubspot_v3: {
      companies: uniq([...accountMapping.hubspot_v3.companies]),
      deals: [...coMarket.hubspot_v3.deals],
      owners: ['id', 'email', 'firstName', 'lastName'],
    },
    microsoft_dynamics: {
      accounts: uniq([...accountMapping.microsoft_dynamics.accounts]),
      opportunities: [...coMarket.microsoft_dynamics.opportunities],
      systemusers: ['systemuserid', 'internalemailaddress', 'fullname'],
    },
    pipedrive: {
      organization: [...accountMapping.pipedrive.organization],
      deal: [...coMarket.pipedrive.deal],
      user: ['id', 'email', 'name'],
      person: [...accountMapping.pipedrive.person],
    },
  };

  const coSelling = {
    type: 'co_selling',
    friendly_name: 'Co-Sell',
    description:
      'Use your partner ecosystem to surface pre-vetted overlapping accounts that can shorten sales cycles and increase opportunity sizes',
    summary:
      'Unlocks Crossbeam for Sales, Potential Revenue, Opportunity Reporting, and Attribution',
    salesforce: {
      Account: [...pipelineMapping.salesforce.Account],
      Opportunity: uniq([
        ...coMarket.salesforce.Opportunity,
        'LastActivityDate',
        'OwnerId',
        'LastStageChangeDate',
      ]),
      User: uniq([...pipelineMapping.salesforce.User, 'Phone', 'Title']),
      Contact: uniq([
        'Id',
        'AccountId',
        'Name',
        'Email',
        'Phone',
        'Title',
        'LastActivityDate',
        'CreatedDate',
      ]),
      OpportunityContactRole: [
        'Id',
        'ContactId',
        'OpportunityId',
        'IsPrimary',
        'Role',
      ],
    },
    hubspot_v3: {
      companies: uniq([
        ...pipelineMapping.hubspot_v3.companies,
        'hubspot_owner_assigneddate',
      ]),
      deals: uniq([
        ...coMarket.hubspot_v3.deals,
        'notes_last_updated',
        'hubspot_owner_id',
        '_xb_last_stage_change_date',
        'deal_currency_code',
      ]),
      owners: [...pipelineMapping.hubspot_v3.owners],
      contacts: [
        'id',
        'associatedcompanyid',
        'firstname',
        'lastname',
        'email',
        'jobtitle',
        'phone',
        'notes_last_updated',
        'createdate',
      ],
      deal_contact_associations: ['id', 'contact_id', 'deal_id', 'label'],
    },
    microsoft_dynamics: {
      accounts: [...pipelineMapping.microsoft_dynamics.accounts],
      opportunities: [...coMarket.microsoft_dynamics.opportunities],
      systemusers: uniq([
        ...pipelineMapping.microsoft_dynamics.systemusers,
        'jobtitle',
        'address1_telephone1',
      ]),
      contacts: uniq([
        'contactid',
        'parentcustomerid',
        'fullname',
        'emailaddress1',
        'jobtitle',
        'telephone1',
        'createdon',
      ]),
    },
    pipedrive: {
      organization: [
        ...accountMapping.pipedrive.organization,
        'label',
        'label_ids',
      ],
      deal: uniq([...coMarket.pipedrive.deal, 'currency', 'label']),
      user: [...pipelineMapping.pipedrive.user],
      person: uniq([
        ...accountMapping.pipedrive.person,
        'owner_id',
        'name',
        'phone',
        'add_time',
      ]),
    },
  };

  const referrals = {
    type: 'referrals',
    friendly_name: 'Refer',
    description:
      'Give and get warm introductions into your target accounts with insights into partner overlaps',
    salesforce: {
      Account: [...pipelineMapping.salesforce.Account],
      Opportunity: [...coMarket.salesforce.Opportunity],
      User: [...pipelineMapping.salesforce.User],
      Contact: [...coSelling.salesforce.Contact],
      Lead: ['Id', 'OwnerId', 'Name', 'Email', 'Phone', 'CreatedDate'],
    },
    hubspot_v3: {}, // Hubspot does not have leads, this template is useless for them
    microsoft_dynamics: {
      accounts: [...pipelineMapping.microsoft_dynamics.accounts],
      opportunities: [...coMarket.microsoft_dynamics.opportunities],
      systemusers: [...pipelineMapping.microsoft_dynamics.systemusers],
      contacts: [...coSelling.microsoft_dynamics.contacts],
      leads: [
        'leadid',
        'ownerid',
        'fullname',
        'emailaddress1',
        'telephone1',
        'createdon',
      ],
    },
    pipedrive: {}, // Pipedrive does not support leads currently
  };

  const TEMPLATES = [
    accountMapping,
    coMarket,
    pipelineMapping,
    coSelling,
    referrals,
  ];

  /* These are preselections, they are for the EasySelectFields.vue file */
  const CUSTOM = 'custom';
  const RECOMMENDED_TEMPLATE = {
    type: 'recommended',
    friendly_name: 'Recommended',
    description:
      'The best option for unlocking value from our full range of features',
    summary: coSelling.summary,
    salesforce: {
      ...coSelling.salesforce,
      Account: uniq([...coSelling.salesforce.Account, 'CurrencyIsoCode']),
      Opportunity: uniq([
        ...coSelling.salesforce.Opportunity,
        'CurrencyIsoCode',
      ]),
    },
    hubspot_v3: coSelling.hubspot_v3,
    microsoft_dynamics: coSelling.microsoft_dynamics,
    pipedrive: coSelling.pipedrive,
  };

  /* It's possible for Microsoft Dynamics to be missing objects, we need
  to remove them from the tooltip logic */
  function presentObjects({ info, columnToFriendlyNameMap }) {
    let newInfo = { ...info };
    for (const object of Object.keys(info)) {
      if (!columnToFriendlyNameMap[object]) {
        const { [object]: _, ...rest } = newInfo;
        newInfo = rest;
      }
    }

    return Object.entries(newInfo).sort((a, b) => (a[0] > b[0] ? 1 : -1));
  }

  function objectColumnsSortedByNickname({
    object,
    columns,
    columnToFriendlyNameMap,
  }) {
    return columns?.sort((a, b) =>
      columnToFriendlyNameMap[object][a]?.localeCompare(
        columnToFriendlyNameMap[object][b],
      ),
    );
  }

  return {
    accountMapping,
    coMarket,
    pipelineMapping,
    coSelling,
    referrals,
    TEMPLATES,
    CUSTOM,
    RECOMMENDED_TEMPLATE,
    presentObjects,
    objectColumnsSortedByNickname,
  };
}
