<template>
  <component
    :is="rootComponent"
    v-bind="rootProps"
    class="reports-table-cell__wrapper"
  >
    <slot />
    <template #title>
      Your free trial has ended. You do not have access to this feature
    </template>
  </component>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';
import { LocationQueryRaw } from 'vue-router';

const {
  to,
  trialOver = false,
  useTooltip = false,
} = defineProps<{
  to: LocationQueryRaw;
  trialOver?: boolean;
  useTooltip?: boolean;
}>();

const rootComponent = computed(() => {
  if (!trialOver) return 'RouterLink';
  return useTooltip ? BittsTooltip : 'div';
});

const rootProps = computed(() => {
  if (!trialOver) return { to };
  return useTooltip ? { placement: 'topRight', mountToBody: true } : {};
});
</script>
