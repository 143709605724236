<template>
  <BittsModalTwo
    class="c-invite-crm-admins-modal"
    title="Invite your CRM Admins"
    description="Inviting your Salesforce Administrators might be required to connect your Salesforce, which allows an optimal use of Crossbeam."
    :loading="!ready"
    secondary-button-text="Skip"
    @closed="handleCloseModal"
    @action="handleSendInvite"
    @secondary-action="skipInviteCrmAdmin"
    :disabled="isSendInviteDisabled || sending"
    :action-loading="sending"
    :width="560"
  >
    <template #image>
      <BittsAvatarStack
        :org-list="avatarStackOrgList"
        size="medium"
        :transparent="false"
      />
    </template>
    <div class="c-invite-crm-admins-modal__content">
      <BittsInput
        v-model.trim="email1"
        name="email1"
        placeholder="Work email"
        :status="isValidEmail1 ? 'default' : 'danger'"
        danger-text="Incorrect email address"
      />
      <BittsInput
        v-model.trim="email2"
        name="email2"
        placeholder="Work email"
        :status="isValidEmail2 ? 'default' : 'danger'"
        danger-text="Incorrect email address"
      />
      <div class="text-neutral-text-weak text-center mt-28">
        You can skip this step if you have permissions to connect your company’s
        CRM - or if you want to get started with another data source such as
        Google Sheets or CSV files
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsAvatarStack, BittsInput, BittsModalTwo } from '@crossbeam/bitts';
import { EVENT_SITES } from '@crossbeam/itly';

import axios from 'axios';
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import useNewOnboarding from '@/composables/useNewOnboarding';
import { captureException } from '@/errors';
import {
  allReady,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { Role } from '@/types/roles';
import urls from '@/urls';
import { isEmailValid, useConditionalDebouncedValidation } from '@/utils';

const rolesStore = useRolesStore();
const flashesStore = useFlashesStore();
const { allRoles } = storeToRefs(rolesStore);

const teamStore = useTeamStore();
const ready = allReady(teamStore, rolesStore);

const { currentOrg } = useAuth();
const { skipInviteCrmAdmin } = useNewOnboarding();
const { iteratively } = useIteratively();

const router = useRouter();
const route = useRoute();

const email1 = ref('');
const email2 = ref('');

const avatarStackOrgList = computed(() => [
  currentOrg.value,
  {
    customSvg: 'add',
  },
]);

const isValidEmail1 = useConditionalDebouncedValidation(email1, isValid, 700);
const isValidEmail2 = useConditionalDebouncedValidation(email2, isValid, 700);

const validEmails = computed(() =>
  [email1.value, email2.value].filter((email) => isValid(email)),
);

const isSendInviteDisabled = computed(() => validEmails.value.length === 0);

async function handleCloseModal() {
  await router.push({ name: 'home' });
}

const sending = ref(false);
async function handleSendInvite() {
  if (isSendInviteDisabled.value) return;
  sending.value = true;

  const adminRole = allRoles.value.find((role) => {
    return role.name === 'Admin';
  });

  const invitesToSend = validEmails.value.map((email) => ({
    email,
    role_id: (adminRole as Role).id,
    sales_edge_role: null,
    purpose: 'user',
  }));

  try {
    sending.value = true;
    await axios.post(urls.team.invite, { invites: invitesToSend });
    flashesStore.addSuccessFlash({
      message: `${pluralize(invitesToSend.length, 'Invitation')} sent`,
    });
    invitesToSend.forEach(({ email }) => {
      const iterativelyPayload = {
        teammate_email: email,
        role: (adminRole as Role).id,
        seat_type: 'core',
        invite_location: route.name,
        event_site: EVENT_SITES.ONBOARDING_INVITE_MODAL,
      };
      // @ts-expect-error - missing values are inserted via setTrackingOptions() in app.js
      iteratively.userInvitesTeammate(iterativelyPayload);
    });
    teamStore.refreshTeamStore();
  } catch (err) {
    captureException(err as Error);
    flashesStore.addUnhandledError(err as Error);
  } finally {
    await router.push({ name: 'home' });
    sending.value = false;
  }
}

/* Helpers 🤝 */
function isValid(emailValue: string) {
  return emailValue.length && isEmailValid(emailValue);
}
</script>

<style lang="pcss">
.c-invite-crm-admins-modal {
  .bitts-avatar-stack-medium {
    .bitts-avatar-stack__org-list {
      @apply justify-center p-0 mb-10;
      & > div {
        @apply w-[52px];
      }
      img {
        @apply bg-denim-200;
      }
    }
  }
  .c-invite-crm-admins-modal__content {
    @apply flex flex-col items-center gap-12 mt-24 mb-40;
    .c-bitts-input {
      @apply w-[400px];
    }
  }
}
</style>
