import pointbreak from '@crossbeam/pointbreak';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createHead } from '@unhead/vue';
import axios from 'axios';
import EventEmitter from 'eventemitter3';
import { createPinia } from 'pinia';
import { createApp, h, resolveComponent } from 'vue';

import Crossbeam from '@/components/Crossbeam.vue';

import { setCustomHeader } from '@/api';
import { AuthService } from '@/auth';
import useIteratively from '@/composables/useIteratively';
import appConfig from '@/config';
import * as errors from '@/errors';
import initMinimalView from '@/initMinimalView';
import { ls } from '@/local_storage';
import { createRouter } from '@/router';
import {
  useBillingStore,
  useFeatureFlagStore,
  useFlashesStore,
  useNotificationsStore,
} from '@/stores';
import injectScripts from '@/third-party-scripts';
import { domainsToBlock, enableFullstory } from '@/trackers/fullstory';
import { maybeEnableIntercom } from '@/trackers/intercom';
import { configureGlobalSettings, setIsLoginPopup } from '@/utils';

import { initializeMockServer } from './mocks';
import { createRootStore } from './stores/RootStore';

import '@/css/main.css';
configureGlobalSettings(window.CSRF_TOKEN);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = appConfig.apiBaseUrl;

const setAxiosOrgHeader = (headerVal) => {
  if (headerVal) {
    setCustomHeader('Xbeam-Organization', headerVal);
    axios.defaults.headers.common['XBeam-Organization'] = headerVal;
  } else {
    setCustomHeader('Xbeam-Organization', null);
    delete axios.defaults.headers.common['XBeam-Organization'];
  }
};

/* Enable oauth-redirect for copilot login */
setIsLoginPopup();
initMinimalView();

const startApp = async () => {
  await initializeMockServer();

  errors.initClient();
  const authService = new AuthService();

  const {
    iteratively,
    loadIteratively,
    setTrackingOptions,
    captureLoggedInUser,
  } = useIteratively();
  loadIteratively();

  const orgNotifier = new EventEmitter();
  orgNotifier.on('organizationFinalized', (org) => setAxiosOrgHeader(org.uuid));
  orgNotifier.on('organizationFinalized', (org) => ls.orgId.set(org.id));

  const app = createApp(Crossbeam);
  app.use(createPinia());

  const store = createRootStore(orgNotifier, iteratively);

  await store.loadUserProfile({ initialLogin: true });
  setTrackingOptions(store.currentUser, store.currentOrg, false);
  captureLoggedInUser(store.currentUser, store.currentOrg);

  if (store.hasProfile) {
    errors.setUser({
      email: store.currentUser.email,
      orgId: store.currentOrg.id,
      orgName: store.currentOrg.name,
    });
    const billingStore = useBillingStore();
    await billingStore.readySync;
  }

  if (
    appConfig.enableFullstory &&
    store.currentUser.email &&
    domainsToBlock.indexOf(store.currentUser.email.split('@')[1]) === -1
  ) {
    enableFullstory(store.currentUser, store.currentOrg);
  }

  const featureFlagStore = useFeatureFlagStore();
  await featureFlagStore.readySync;

  const notificationsStore = useNotificationsStore();
  notificationsStore.startPolling(30000);

  const router = createRouter(store);
  app.use(router);

  const head = createHead();
  app.use(head);
  app.use(pointbreak);

  app.config.globalProperties.$auth = authService;
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$iteratively = iteratively;

  app.component('FontAwesomeIcon', FontAwesomeIcon);

  /* workaround for https://github.com/vuejs/router/issues/1306 */
  app.component('RouterViewWrapper', (props, { slots }) =>
    h(resolveComponent('router-view'), props, slots),
  );

  const flashesStore = useFlashesStore();
  errors.configureHandlers(app, flashesStore);

  maybeEnableIntercom(store.currentUser, store.currentOrg, store.currentAuth);

  app.mount('#app');
};

startApp();

injectScripts();
