<template>
  <div
    v-if="hasDataSyncCustomization"
    class="c-data-import-tab"
    data-testid="c-datasource-settings__data-sync"
  >
    <div class="c-data-import-tab_header">
      <component :is="isSyncing ? 'div' : BittsTooltip" placement="left">
        <div>
          <BittsButton
            text="Edit"
            variant="outline"
            :left-icon="['fak', 'edit']"
            :disabled="!isSyncing"
            @click="customizeFields"
          />
        </div>
        <template #title>
          Syncing must be turned on in order to edit this
        </template>
      </component>
      <span class="flex items-center gap-4">
        <FontAwesomeIcon
          v-if="allCheckedFieldIds.length > fieldLimit"
          :icon="['fak', 'warning']"
          :style="{ height: '16px', width: '16px' }"
          class="text-warning-accent"
        />
        <BittsTag
          :color="
            allCheckedFieldIds.length > fieldLimit ? 'danger' : 'secondary'
          "
          size="small"
          variant="rounded"
        >
          {{ allCheckedFieldIds.length }}<span class="mx-2">/</span
          >{{ `${fieldLimit} ${pluralize(fieldLimit, 'field')}` }} selected
        </BittsTag>
      </span>
    </div>
    <ReviewFields
      :all-checked-field-ids="allCheckedFieldIds"
      :feed="feed"
      :sources="sources"
    />
    <p class="text-neutral-text-weak text-sm mt-4">
      Changes to your data sync may take up to an hour to take effect
    </p>
  </div>
</template>

<script setup>
import { BittsButton, BittsTag, BittsTooltip } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ReviewFields from '@/components/data-sources/data-templates/ReviewFields.vue';

import {
  HS3_DATA_SOURCE_TYPE,
  MAX_FIELDS,
  MD_DATA_SOURCE_TYPE,
  PIPEDRIVE_DATA_SOURCE_TYPE,
  SALESFORCE_DATA_SOURCE_TYPE,
  isValidSourceField,
} from '@/constants/data_sources';
import { useFeedsStore, useSourcesStore } from '@/stores';

const router = useRouter();
const feed = ref(null);
const sources = ref([]);
const allCheckedFieldIds = ref([]);
const isSyncing = ref(true);
const route = useRoute();
const fieldLimit = ref(MAX_FIELDS);

const feedId = Number(route.params.id);

const feedsStore = useFeedsStore();
const sourcesStore = useSourcesStore();

onBeforeMount(async () => {
  await Promise.all([feedsStore.readySync, sourcesStore.readySync]);
  feed.value = feedsStore.getFeedById(feedId);
  isSyncing.value = !feed.value.is_paused;
  sources.value = sourcesStore.getSourcesByFeedId(feedId);

  /* This is necessary for the review tree, it needs the IDs
  of all the checked fields for the data source */
  allCheckedFieldIds.value = sources.value.reduce((acc, source) => {
    const fields = source.fields
      .filter((sourceField) => {
        return (
          isValidSourceField(sourceField) &&
          sourceField.is_visible &&
          sourceField.is_filterable
        );
      })
      .map((sourceField) => sourceField.id);

    acc.push(...fields);

    return acc;
  }, []);
  loading.value = false;
});

const loading = ref(true);

const hasDataSyncCustomization = computed(() => {
  return [
    SALESFORCE_DATA_SOURCE_TYPE,
    MD_DATA_SOURCE_TYPE,
    HS3_DATA_SOURCE_TYPE,
    PIPEDRIVE_DATA_SOURCE_TYPE,
  ].includes(feed.value?.integration.type);
});

async function customizeFields() {
  await router.push({
    name: 'edit-fields',
    params: route.params,
    query: { customizing: true },
  });
}
</script>
<style lang="pcss" scoped>
.c-data-import-tab {
  @apply flex flex-col items-center gap-20;
}
.c-data-import-tab_header {
  @apply flex items-center justify-between w-full;
}
</style>
