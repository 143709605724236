<template>
  <div class="bitts-multiselect" :class="{ error }">
    <BittsFormLabel v-if="formLabel" :label="formLabel" :disabled="disabled" />
    <ASelect
      v-bind="dynamicProps()"
      v-model:value="localValue"
      size="large"
      :popup-class-name="dropDownClasses"
      :mode="mode"
      :allow-clear="allowClear"
      :autofocus="autoFocus"
      :show-search="searchable"
      :disabled="disabled"
      :options="availableOptions"
      :dropdown-match-select-width="dropdownMatchSelectWidth"
      :default-open="defaultOpen"
      @deselect="(opt) => selectOption(opt, false)"
      @search="(val) => emit('search-changed', val)"
      @select="(opt) => selectOption(opt, true)"
      @change="handleChange"
    >
      <template #placeholder>
        <div class="bitts-select__placeholder">
          {{ placeholder }}
        </div>
      </template>

      <template #option="option">
        <div class="bitts-multiselect-option">
          <div
            v-if="hasOptionGroups && !option.value"
            class="flex items-center px-12 text-neutral-text leading-6"
          >
            {{ option.label }}
          </div>
          <div v-else>
            <div class="flex items-center mb-2">
              <BittsCheckbox
                v-if="mode === 'multiple' && props.showCheckboxes"
                class="mr-8"
                :checked="option.checked"
                @click.prevent
              />
              <FontAwesomeIcon
                v-if="option.icon"
                :icon="option.icon"
                :style="{ width: '16px', color: 'currentColor' }"
                :class="option.iconColor ?? 'text-neutral-accent'"
                class="mr-8"
              />
              <BittsAvatar
                v-else-if="optionType === 'company'"
                size="x-small"
                :show-initials="true"
                :org="option"
                class="mr-8"
              />
              <BittsAvatar
                v-else-if="optionType === 'entity'"
                size="x-small"
                :show-initials="true"
                :user="option"
                class="mr-8"
              />
              {{ buildOptionLabel(option) }}
            </div>
          </div>
        </div>
      </template>

      <template #tagRender="{ option, label, value }">
        <div
          v-if="!props.showSelectedOptions && !option"
          class="bitts-select-pill"
          @click="removeOptionOnClick(value)"
        >
          <BittsAvatar
            size="x-small"
            :show-initials="true"
            :user="findSelectedOption(value)"
            class="mr-8"
          />
          <span class="mr-8">{{ buildSelectedLabel(value) }}</span>
          <FontAwesomeIcon
            :icon="['fak', 'x']"
            :style="{ width: '12px', color: 'currentColor' }"
            class="text-neutral-accent"
          />
        </div>
        <div
          v-else
          class="bitts-select-pill"
          @click="removeOptionOnClick(value)"
        >
          <BittsAvatar
            v-if="optionType === 'company' && option"
            size="x-small"
            :show-initials="true"
            :org="option"
            class="mr-8"
          />
          <BittsAvatar
            v-if="optionType === 'entity' && option"
            size="x-small"
            :show-initials="true"
            :user="option"
            class="mr-8"
          />
          <FontAwesomeIcon
            v-else-if="option?.icon"
            :icon="option.icon"
            :style="{ width: '16px', color: 'currentColor' }"
            :class="option.iconColor ?? 'text-neutral-accent'"
            class="mr-8"
          />
          <span class="mr-8">{{ buildTagLabel(option, label) }}</span>
          <FontAwesomeIcon
            :icon="['fak', 'x']"
            :style="{ width: '12px', color: 'currentColor' }"
            class="text-neutral-accent"
          />
        </div>
      </template>

      <template #clearIcon>
        <FontAwesomeIcon
          :icon="['far', 'xmark-circle']"
          :style="{ width: '12px', color: 'currentColor' }"
          class="text-neutral-accent"
        />
      </template>

      <template #suffixIcon>
        <FontAwesomeIcon
          :icon="['fas', 'chevron-up']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>

      <template #removeIcon>
        <FontAwesomeIcon
          :icon="['fas', 'xmark-circle']"
          class="text-neutral-accent"
          :style="{ width: '8px', color: 'currentColor' }"
        />
      </template>

      <template #menuItemSelectedIcon>
        <FontAwesomeIcon :icon="['fak', 'check']" :style="{ width: '12px' }" />
      </template>

      <template #notFoundContent>
        <template v-if="props.noneRemainingMessage">
          {{ props.noneRemainingMessage }}
        </template>
        <slot name="emptyStateContent" />
      </template>
    </ASelect>
  </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Select as ASelect } from 'ant-design-vue';
import { computed, ref, watch } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsCheckbox from '../BittsCheckbox/BittsCheckbox.vue';
import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';

const props = defineProps({
  allowClear: {
    type: Boolean,
    default: false,
  },
  autoFocus: {
    type: Boolean,
    default: false,
  },
  customFilter: {
    type: String,
    default: null,
  },
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  dropdownClass: {
    type: String,
    default: null,
  },
  dropdownMatchSelectWidth: {
    type: Boolean,
    default: true,
  },
  formLabel: {
    type: [Object, String],
    default: null,
  },
  hideFromThirdParties: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: 'default',
    validator: (val) => ['default', 'multiple'].includes(val),
  },
  open: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: 'Select Field',
  },
  optionType: {
    type: String,
    default: 'default',
    validator: (type) => ['default', 'company', 'entity'].includes(type),
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Array, String, Number],
    default: undefined,
  },
  noneRemainingMessage: {
    type: String,
    default: null,
  },
  showCheckboxes: {
    type: Boolean,
    default: true,
  },
  showSelectedOptions: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'search-changed',
  'update:modelValue',
  'item-removed',
  'item-added',
]);

const optionsWithChecked = ref([]);

const dropDownClasses = computed(() => {
  const classes = ['bitts-select__dropdownV2'];
  if (props.hideFromThirdParties)
    classes.push('fullstory-hide dd-privacy-mask');
  props.mode === 'multiple' ? classes.push('multiple') : classes.push('single');
  if (props.dropdownClass) classes.push(props.dropdownClass);
  return classes.join(' ');
});

const hasOptionGroups = computed(() => props.options[0]?.options);

const availableOptions = computed(() => {
  if (props.mode === 'multiple' && props.showCheckboxes)
    return optionsWithChecked.value;
  if (!props.showSelectedOptions) {
    return props.options.filter((opt) => !localValue.value.includes(opt.value));
  }
  return props.options;
});

function findSelectedOption(value) {
  return props.options.find((opt) => opt.value === value);
}

function buildSelectedLabel(value) {
  return props.options.find((opt) => opt.value === value)?.label;
}

function setOptionsWithChecked() {
  if (props.mode === 'multiple' && props.showCheckboxes) {
    const prefillLookup = {};
    props.modelValue?.forEach((val) => {
      prefillLookup[val] = true;
    });

    // Otherwise we need to add checked and precheck if value is in v-model
    const prefillCheckedValue = (option) => {
      const optWithChecked = {
        ...option,
        checked: false,
      };
      if (Object.hasOwn(prefillLookup, option.value))
        optWithChecked.checked = true;
      return optWithChecked;
    };
    optionsWithChecked.value = props.options.reduce((acc, curr) => {
      // For option groups we need to add to nested options
      if (hasOptionGroups.value)
        curr.options = curr.options.map(prefillCheckedValue);
      else curr = prefillCheckedValue(curr);
      acc.push(curr);
      return acc;
    }, []);
  }
}

const checkHelper = (options, deselectedOption, checkedValue) => {
  for (const option of options) {
    if (option.value === deselectedOption) option.checked = checkedValue;
  }
};

function selectOption(selectedOption, checkedValue) {
  const emittedEvent = checkedValue ? 'item-added' : 'item-removed';
  emit(emittedEvent, selectedOption);
  optionsWithChecked.value.forEach((option) => {
    if (option?.options)
      checkHelper(option.options, selectedOption, checkedValue);
    else if (option.value === selectedOption) option.checked = checkedValue;
  });
}
const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

function handleChange(val) {
  emit('update:modelValue', val);
}

function buildOptionLabel(val) {
  const option = val.option ?? val;
  return props.optionType === 'company' ? option.name : option.label;
}

function buildTagLabel(option, label) {
  return props.optionType === 'company' ? option?.name : label;
}

function removeOptionOnClick(value) {
  // checked to false
  selectOption(value, false);
  // remove from selected vals
  localValue.value = localValue.value.filter((val) => value !== val);
}

function dynamicProps() {
  const newProps = {};
  if (props.noneRemainingMessage)
    newProps.notFoundContent = props.noneRemainingMessage;
  if (props.open) newProps.open = true;
  if (props.optionType === 'company' || props.optionType === 'entity')
    newProps.optionFilterProp = 'name';
  if (props.customFilter) {
    newProps.filterOption = true;
    newProps.optionFilterProp = props.customFilter;
  }
  return newProps;
}

watch(
  () => props.options,
  () => {
    setOptionsWithChecked();
  },
  { immediate: true },
);

defineExpose({ selectOption });
</script>

<style lang="pcss">
.bitts-multiselect {
  .bitts-select-pill {
    @apply flex items-center text-neutral-text bg-neutral-background text-base rounded-4 px-8 cursor-pointer m-4;
  }

  .ant-select {
    @apply text-base leading-6 w-full font-normal;
    font-family: inherit;
  }

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    @apply border-neutral-border;
  }

  .ant-select-selection-item {
    @apply inline-flex items-center;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    @apply text-neutral-text;
  }

  .ant-select-multiple .ant-select-selection-item {
    @apply rounded-4 border-none bg-neutral-background text-neutral-text h-24 leading-6;
    .ant-select-selection-item-content {
      @apply pr-4;
    }
  }

  .ant-select-item {
    @apply transition-none;
  }

  .ant-select-arrow svg {
    @apply transition-transform;
  }

  .ant-select-open .ant-select-arrow svg {
    @apply rotate-180;
  }

  .ant-select-multiple .ant-select-selection-search {
    @apply ms-0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-lg border-neutral-border shadow-component pl-12 transition-none max-h-100 overflow-auto;
  }

  .bitts-select__placeholder {
    @apply text-neutral-text-placeholder;
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    @apply rounded-4 border-none bg-neutral-background text-neutral-text pr-24;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply bg-neutral-background-disabled;
  }

  &.error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 1px theme(colors.danger.border-focus);
    @apply border-danger-border-focus;
  }

  .ant-select-multiple .ant-select-clear {
    @apply p-8 h-24 w-24 mt-[-14px] opacity-100 mr-[4px] bg-transparent;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    @apply left-12 right-12;
  }
}

.bitts-select__dropdownV2 {
  /* reset dropdown option item wrapper */
  .ant-select-item.ant-select-item-option {
    @apply p-0 min-h-full mb-2;
  }

  /* dropdown container */
  &.ant-select-dropdown {
    @apply rounded-8 shadow-component border border-neutral-border p-4;
  }

  /* Option Group label */
  .ant-select-item.ant-select-item-group {
    @apply min-h-full py-4;
    &:not(:first-child) {
      @apply border-t border-neutral-border mt-2;
    }
  }

  /* hover selected option */
  .ant-select-item-option-selected {
    @apply font-normal;
  }

  &.single {
    .ant-select-item-option-active {
      @apply bg-neutral-background;
    }
    .ant-select-item-option-selected {
      @apply bg-info-background-weak font-normal;
    }
    .ant-select-item-option-content .bitts-multiselect-option {
      @apply py-5 px-8 text-neutral-text-strong p-0 rounded mx-4 h-full leading-6 flex items-center;
    }
  }

  &.multiple {
    .ant-select-item-option-active {
      @apply bg-neutral-background;
    }

    .ant-select-item-option-selected:not(
        .ant-select-item-option-active.ant-select-item-option-selected
      ) {
      @apply font-normal bg-white;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      .ant-select-item-option-state {
      @apply hidden;
    }

    .ant-select-item-option-content .bitts-multiselect-option {
      @apply py-5 px-8 text-neutral-text-strong p-0 rounded mx-4 h-full leading-6 flex items-center;
    }
    div > .ant-select-item-empty {
      @apply p-12;
    }
  }

  .ant-empty {
    @apply flex justify-center items-center text-neutral-text-weak;
  }

  /* Arrow on right side of single select selected option */
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    @apply flex items-center text-info-accent mr-8;
  }
}
</style>
