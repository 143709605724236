<template>
  <BittsLoading :is-loading="loading || !ready">
    <div class="c-remove-offline-partners">
      <h3
        >Choose {{ EXPLORER_OFFLINE_PARTNER_LIMIT }} offline partner to keep</h3
      >
      <p
        >Any other offline partners (even newly created ones) will be deleted on
        <b>{{ subscriptionRenewalDate }}</b></p
      >
      <BittsTable
        :row-selection="'single'"
        :suppress-row-click-selection="true"
        :on-row-selected="handleRowSelected"
        :columns="columns"
        :rows="rows"
        :compress-columns="true"
        :row-height="64"
        :pagination="rows.length > 10"
        class="fade-in"
        @grid-ready="getGridApi"
      />
    </div>
  </BittsLoading>
</template>
<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BittsLoading, BittsTable } from '@crossbeam/bitts';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

import DebookingOfflinePartnerNameCell from '@/components/billing/DebookingOfflinePartnerNameCell.vue';
import DebookingOfflinePartnerPopsCell from '@/components/billing/DebookingOfflinePartnerPopsCell.vue';

import { EXPLORER_OFFLINE_PARTNER_LIMIT } from '@/constants/billing';
import { useBillingStore, usePartnersStore } from '@/stores';
import urls from '@/urls';
import { sortByKey } from '@/utils';

const billingStore = useBillingStore();
const partnersStore = usePartnersStore();
const { offlinePartners } = storeToRefs(partnersStore);

const { ready } = storeToRefs(billingStore);

type OfflinePartner = {
  uuid: string;
  name: string;
  pops?: number;
};

const loading = ref(true);

const columns = [
  {
    field: 'uuid',
    cellRenderer: DebookingOfflinePartnerNameCell,
    checkboxSelection: true,
    suppressMovable: true,
    autoHeight: true,
    sortable: true,
    sort: 'asc',
    headerComponentParams: { displayName: 'Partner', showArrow: true },
    comparator: (
      _: any,
      __: any,
      { data: aData }: { data: { name: string } },
      { data: bData }: { data: { name: string } },
    ) => {
      return sortByKey('name', 'desc', 'raw')(aData, bData);
    },
  },
  {
    field: 'pops',
    cellRenderer: DebookingOfflinePartnerPopsCell,
    suppressMovable: true,
    autoHeight: true,
    sortable: true,
    headerComponentParams: { displayName: 'Populations' },
    comparator: (
      _: any,
      __: any,
      { data: aData }: { data: { pops: number } },
      { data: bData }: { data: { pops: number } },
    ) => {
      return sortByKey('pops', 'desc', 'raw')(aData, bData);
    },
  },
];
type Row = OfflinePartner;
const rows = ref<Row[]>([]);
onMounted(async () => {
  await partnersStore.readySync;
  const partnerPops = await Promise.allSettled(
    offlinePartners.value.map(async ({ uuid, name }: OfflinePartner) => {
      const { data } = await axios.get(
        urls.offlinePartners.populations.list(uuid),
      );
      return { uuid, data, name };
    }),
  );
  rows.value = partnerPops.reduce((agg, res) => {
    if (res.status === 'fulfilled') {
      agg.push({
        name: res.value.name,
        uuid: res.value.uuid,
        pops: res.value.data?.items?.length,
      });
    }
    return agg;
  }, [] as Row[]);

  loading.value = false;
});

const { subscriptionRenewalDate } = storeToRefs(billingStore);

const uuidModel = defineModel({ type: String, default: '' });

type Api = { getSelectedRows: () => OfflinePartner[] } | null;
const gridApi = ref<Api>(null);
function handleRowSelected() {
  const selectedRow = gridApi.value?.getSelectedRows() as OfflinePartner[];
  if (selectedRow[0]) {
    uuidModel.value = selectedRow[0].uuid;
  } else {
    uuidModel.value = '';
  }
}
function getGridApi({ api }: { api: Api }) {
  gridApi.value = api;
}
</script>
<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-4 mt-40;
}

p {
  @apply text-neutral-text mb-24;
}
</style>
