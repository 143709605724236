import {
  ACTIVE,
  ANNUAL_SALES_SEATS,
  ANNUAL_SEATS,
  ANNUAL_SUBSCRIPTION,
  BILLING_TIERS,
  CANCELLATION,
  CANCELLED,
  MONTHLY_SALES_SEATS,
  MONTHLY_SEATS,
  MONTHLY_SUBSCRIPTION,
  PAST_DUE,
  SEAT_REMOVAL,
  UNPAID,
} from '@/constants/billing';

type SeatInfo = {
  seat_count: number;
  used_seats: number;
};

type SubscriptionHandle =
  | typeof MONTHLY_SUBSCRIPTION
  | typeof ANNUAL_SUBSCRIPTION;
type SubscriptionStatus =
  | typeof ACTIVE
  | typeof PAST_DUE
  | typeof CANCELLED
  | typeof UNPAID;
type ComponentHandle =
  | typeof ANNUAL_SEATS
  | typeof MONTHLY_SEATS
  | typeof ANNUAL_SALES_SEATS
  | typeof MONTHLY_SALES_SEATS;

type PricePoint = {
  ending_quantity: number | null;
  starting_quantity: number | null;
  unit_price_cents: number;
  allocated_quantity: number;
  component_price_tier_total_cents: number;
};

type ChargifyComponent = {
  component_handle: ComponentHandle;
  component_id: number;
  allocated_quantity: number;
  price_points: PricePoint[];
  component_total_cents: number;
};

type PaidAllocation = {
  component_handle: ComponentHandle;
  allocated_quantity: number;
};

type UpdateSummary = {
  cancellation_initiated: boolean;
  update_type: typeof SEAT_REMOVAL | typeof CANCELLATION;
  unclaimed_seats: PaidAllocation;
};

type ChargifySubscription = {
  last_paid_date: string;
  last_paid_amount_cents: number;
  subscription_active: boolean;
  open_invoice_issue_date: string | null;
  subscription_handle: SubscriptionHandle;
  components_total_cents: number;
  components: ChargifyComponent[];
  subscription_status: SubscriptionStatus;
  subscription_created: string;
  next_billing_date: string;
  next_renewal_date: string;
  product_total_cents: number;
  subscription_total_cents: number;
  subscription_sync_error_message: string | null;
  update_summary?: UpdateSummary;
  in_renewal_period?: boolean;

  // Legacy, when we had discounts + coupons
  subscription_subtotal_cents?: number;
  subscription_discount_cents?: number;
};

export type PortalInfo = {
  url: string;
};

export type PaymentInfo = {
  card_type: string;
  masked_card_number: string;
};

export type BillingInfo = {
  price_and_packaging_version: number | null;
  billing_tier:
    | typeof BILLING_TIERS.FREE
    | typeof BILLING_TIERS.CONNECTOR
    | typeof BILLING_TIERS.ENTERPRISE;
  tier_first_created_at: string;
  sales_seat?: SeatInfo;
  free_trial_requested?: boolean;
  on_free_trial?: boolean;
  contract_end_date?: string;
  core: {
    record_export_limit: number;
    custom_population_limit: number;
    partner_limit: number;
    report_export_limit: number;
    offline_partners_quota: number;
    is_partner_sponsor?: boolean;
  } & SeatInfo;
  chargify_subscription?: ChargifySubscription;
};

export type BillingInteraction = {
  cta?: string;
  cta_2?: string;
  talkToSalesReason?: string;
  event_site: string;
  seatType?: string;
  isEarlyAdopter?: boolean;
  period?: string;
  number_of_seats?: number;
  attribution_credits?: string;
};

export type BillingQuery = {
  cancelDestination: string;
  userIds?: string[];
  emails?: string[];
} | null;

export enum BillingPeriod {
  Month = 'month',
  Year = 'year',
}
