<template>
  <div class="timeline-phrase" data-testid="timeline-phrase">
    <div
      v-for="(
        { element, text, props: markupProps, tooltip, icon }, idx
      ) in builtMarkup"
      :key="idx"
    >
      <component
        :is="tooltip ? BittsTooltip : 'div'"
        class="timeline-phrase"
        :data-testid="tooltip ? 'timeline-tooltip' : undefined"
      >
        <component v-bind="markupProps ?? null" :is="element">
          <template v-if="text?.length" #default>
            {{ text }}
          </template>
        </component>
        <FontAwesomeIcon
          v-if="icon"
          :icon="icon"
          :style="{ height: '12px', width: '12px', color: 'currentColor' }"
          class="text-secondary-text"
        />
        <template #title v-if="tooltip">
          {{ tooltip }}
        </template>
      </component>
      <template v-if="idx !== (builtMarkup?.length ?? 0) - 1">&nbsp;</template>
    </div>
    <div
      class="timeline-event__dot"
      :class="{
        'ml-[-18px]':
          activity.activity_source === 'gong' &&
          'mentions' in activity.data &&
          activity.data.mentions?.length,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';

import useTimelineContentDeux from '@/composables/useTimelineContentDeux';
import { TimelineEvent } from '@/types/timeline';

const { activity, accountName = '' } = defineProps<{
  activity: TimelineEvent;
  accountName: string;
}>();

const { buildMarkupByActivity } = useTimelineContentDeux();
const builtMarkup = computed(() =>
  buildMarkupByActivity(activity, accountName),
);
</script>

<style scoped lang="pcss">
.timeline-phrase {
  @apply inline-flex items-center flex-wrap relative;
}

.timeline-event__dot {
  @apply w-4 h-4 bg-neutral-border rounded-full absolute top-1/2 left-[-12.5px] transform -translate-x-1/2 -translate-y-1/2;
}
</style>
