<template>
  <BittsModal
    :visible="showRoleDetailModal"
    :show-buttons="false"
    name="role-detail"
    :loading="loading || !ready"
    :title="modalTitle"
    class="c-edit-role"
    @closed="modalClosed"
  >
    <template #content>
      <BillingCTA
        v-if="isNotSupernode"
        description="Upgrade to Supernode to be able to edit the permission associated with this role"
        button-text="Talk to Sales"
        size="small"
        :billing-interaction="{
          cta: TEAM_CTAS.CUSTOM_ROLES,
          event_site: EVENT_SITES.ROLE_DETAIL_EDIT_PERMISSIONS_CTA,
          talkToSalesReason: 'Custom roles',
        }"
        class="mb-20 mt-0"
      />
      <BittsInput
        v-model="role.name"
        name="role-name"
        placeholder="Role name"
        :status="v$.role.name.$errors.length ? 'danger' : 'default'"
        :danger-text="v$.role.name.$errors?.at(-1)?.$message || ''"
        :disabled="isViewOnly"
        form-label="Role name"
        :class="[isViewOnly && 'c-text-input--disabled']"
      />
      <BittsTextArea
        v-model="role.description"
        class="mt-16"
        placeholder="Role description"
        :disabled="isViewOnly"
        :status="v$.role.description.$errors.length ? 'danger' : 'default'"
        form-label="Description"
        :danger-text="v$.role.description.$errors?.at(-1)?.$message || ''"
        :class="[isViewOnly && 'c-text-area--disabled']"
        :row-count="3"
      />
      <div class="text-sm text-neutral-text-weak mt-4">
        Note: Use a description to give your team context on the role
      </div>

      <BittsFormLabel
        class="permissions-label"
        :label="{
          title: 'Permissions',
          helpText: tooltipText,
        }"
      />

      <RoleDetailPermissions :role-id :view-only />

      <BittsDivider v-if="!isViewOnly" class="mb-0" />
      <div
        v-if="isEditMode"
        class="flex flex-col mt-0 flex-1 md:flex-row md:justify-between md:mt-24 gap-12 px-24"
      >
        <BittsButton
          data-testid="delete-role"
          text="Delete role"
          variant="outline"
          type="danger"
          @click="openDelete"
        />
        <BittsButton
          data-testid="save-role-changes"
          text="Save Changes"
          :disabled="isViewOnly"
          :loading="loading"
          @click="saveRole"
        />
      </div>
      <div v-else-if="isCreateMode" class="flex flex-col mt-24 mr-24">
        <BittsButton
          text="Create Role"
          class="self-end w-full md:w-auto"
          :disabled="isViewOnly"
          :loading="loading"
          @click="createRole"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script setup lang="ts">
import {
  BittsButton,
  BittsDivider,
  BittsFormLabel,
  BittsInput,
  BittsModal,
  BittsTextArea,
} from '@crossbeam/bitts';
import { EVENT_SITES, TEAM_CTAS } from '@crossbeam/itly';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { computed, onUnmounted, toRef } from 'vue';
import { useRouter } from 'vue-router';

import BillingCTA from '@/components/billing/BillingCTA.vue';

import { useRoleDetail } from '@/composables/useRoleDetail';
import { useBillingStore, useRolesStore } from '@/stores';
import { Role } from '@/types/roles';

import RoleDetailPermissions from './RoleDetailPermissions.vue';

const { roleId = undefined, viewOnly = false } = defineProps<{
  roleId?: string;
  viewOnly?: boolean;
}>();

const router = useRouter();
const rolesStore = useRolesStore();
const billingStore = useBillingStore();

const { ready } = storeToRefs(rolesStore);
const { isEnterpriseTier } = storeToRefs(billingStore);
const {
  isCreateMode,
  isEditMode,
  isViewOnlyMode,
  loading,
  role,
  showRoleDetailModal,
  v$,
  createRole,
  saveRole,
  refreshRoleDetail,
} = useRoleDetail(
  toRef(() => roleId),
  toRef(() => viewOnly),
);

useHead({
  title: computed(() => role.value.name ?? 'Crossbeam'),
});
onUnmounted(() => {
  useHead({ title: 'Crossbeam' });
});

const isNotSupernode = computed(() => {
  /* This view only mode is only based on billing tier:
  a user who has only the read:roles permission does not fall into this category.
  Theoretically it could be extended to include them, but there is more work to
  that than just editing the following line */
  return !isEnterpriseTier.value;
});
const isViewOnly = computed(() => {
  return isNotSupernode.value || isViewOnlyMode.value;
});
const modalTitle = computed(() => {
  if (isCreateMode.value) return 'Create New Role';
  return isViewOnly.value
    ? `View ${role.value.name}`
    : `Edit ${role.value.name}`;
});
const tooltipText = computed(() =>
  isViewOnly.value
    ? `${role.value.name} role can't be edited`
    : 'Define the permissions for this role',
);

async function modalClosed() {
  showRoleDetailModal.value = false;
  refreshRoleDetail();
  await router.push({ name: 'roles' });
}
function openDelete() {
  showRoleDetailModal.value = false;
  router.push({
    name: 'delete_role',
    params: { role_id: (role.value as Role).id },
  });
}
</script>
<style lang="pcss">
.u-grey-box {
  border-radius: 3px;
  @apply border border-neutral-200;
  &:focus {
    @apply border-brand-teal;
  }
  &:focus-within {
    @apply border-brand-teal;
  }
}
.c-text-input--disabled {
  & .disabled {
    @apply opacity-100;
  }
}

.c-text-area--disabled {
  & textarea.c-bitts-textarea.ant-input.disabled {
    @apply opacity-100 disabled:text-opacity-50;
  }
}

.c-edit-role {
  .c-bitts-modal__content {
    @apply flex flex-col;
  }
  .permissions-label.bitts-form-label {
    @apply mt-24 mb-12;
  }
}
</style>
