// Integration Types
export const PARTNERSTACK = 'partnerstack';
export const GONG = 'gong';

export const ATTRIBUTION_ACTIVITY = 'attribution_activity';
export const POPULATION_MOVEMENT = 'population_movement';
export const OVERLAP_MOVEMENT = 'overlap_movement';
export const SALES_EDGE_ACTIVITY = 'sales_edge_activity';
export const INTEGRATIONS = 'integrations';
export const CROSSBEAM = 'crossbeam';
export const COPILOT_ACTIVITY = 'copilot';
export const USER_RESEARCHED_CONTACT = 'user_researched_contact';
export const USER_RESEARCHED_ACCOUNT = 'user_researched_account';
export const USER_ADDED_CONTACT_TO_CRM = 'user_added_contact_to_crm';
export const LIST_EVENT = 'list_event';
export const LIST_NOTE_ADDED = 'list_note_added';
export const LIST_NOTE_UPDATED = 'list_note_updated';
export const LIST_RECORD_ADDED = 'list_record_added';
export const LIST_RECORD_REMOVED = 'list_record_removed';

export const ALL_EVENT_FILTERS = {
  [ATTRIBUTION_ACTIVITY]: 'all_attribution_activity_filters',
  [POPULATION_MOVEMENT]: 'all_population_movement_filters',
  [OVERLAP_MOVEMENT]: 'all_overlap_movement_filters',
  [SALES_EDGE_ACTIVITY]: 'all_sales_edge_activity_filters',
  [INTEGRATIONS]: 'all_integrations_filters',
  [CROSSBEAM]: 'all_crossbeam_filters',
  [COPILOT_ACTIVITY]: 'all_copilot_filters',
  [LIST_EVENT]: 'all_list_event_filters',
};
