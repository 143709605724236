import { POTENTIAL_REVENUE, TOTAL_OVERLAPS } from '@/constants/amm_grid';
import { PARTNER_SCORES } from '@/constants/partners';

// for async report polling
export const ATTEMPTS_PER_MULTIPLIER = 4;
export const DEFAULT_ASYNC_POLL_OBJ = {
  attemptsUntilMultiplierIncrement: ATTEMPTS_PER_MULTIPLIER,
  fib1: 1,
  fib2: 1,
  interval: 250,
  timeout: null,
};

export const DEALS_AMOUNT = 'deals_amount';
export const DEALS_COUNT = 'deals_count';
export const OVERLAP_TIME = 'overlap_time';
export const OVERLAPS_WITH = 'overlaps_with';
export const PARTNER_IMPACT = 'partner_impact_scores';
export const POPULATIONS = 'populations';
export const PARTNER_POPULATIONS = 'partner_populations';
export const PARTNERSTACK = 'partnerstack';

export const SOURCE_FIELD_COL = 'source_field';
export const RECORD_NAME_COL = 'record_name';

export const CROSSBEAM_COLUMNS = [
  {
    column_type: OVERLAP_TIME,
    display_name: 'Matched',
  },
  {
    column_type: DEALS_AMOUNT,
    display_name: 'Open Opportunities Amount',
  },
  {
    column_type: DEALS_COUNT,
    display_name: 'Open Opportunities Count',
  },
  {
    column_type: OVERLAPS_WITH,
    display_name: 'Overlaps With',
  },
  {
    column_type: PARTNER_IMPACT,
    display_name: 'Partner Impact',
  },
  {
    column_type: POPULATIONS,
    display_name: 'My Populations',
  },
  {
    column_type: PARTNER_POPULATIONS,
    display_name: 'Partner Populations',
  },
  {
    column_type: PARTNERSTACK,
    display_name: 'PartnerStack',
  },
];

export const FILTER_OPERATORS = ['AND', 'OR'];

export const REPORT_STATE_KEY = 'REPORT_STATE';
export const OWN_TAB = 'own-filters';
export const PARTNER_TAB = 'partner-filters';

export const INITIAL_MODAL_STATE = {
  metadata: null,
  modalText: '',
  modalHeader: '',
  filtersToRemove: [],
};

export const MODAL_TYPES = {
  SAVE: 'save',
  SAVE_EARLY_ADOPTER: 'saveEarlyAdopter',
  DELETE: 'delete',
  DELETE_FILTERS: 'deleteFilters',
  UNSAVED: 'unsaved',
  CHANGE_OWN_GREENFIELD: 'changeToOwnGreenfield',
  NOTIFICATIONS: 'notifications',
};

// report cache statuses
export const CACHE_INITIALIZED = 'CACHE_INITIALIZED';
export const PARTIALLY_CACHED = 'PARTIALLY_CACHED';
export const CACHING_FAILED = 'CACHING_FAILED';
export const CACHED = 'CACHED';
export const SKIPPED = 'SKIPPED';

export const REPORT_CACHE_STATUSES = [
  CACHE_INITIALIZED,
  PARTIALLY_CACHED,
  CACHING_FAILED,
  CACHED,
  SKIPPED,
];

// filtering consts
export const ARRAY_OPERATORS = ['in', 'not in'];
export const USER_INPUT_OPERATORS = ['contains', 'does not contain'];
export const ARRAY_PAYLOAD_OPERATORS = [
  ...ARRAY_OPERATORS,
  ...USER_INPUT_OPERATORS,
];
export const EMPTY_OPERATORS = ['is empty', 'is not empty'];
export const DATETIME = 'datetime';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const BIGINT = 'bigint';
export const DOUBLE = 'double precision';
export const COMBINATORS = ['AND', 'OR'];

// consolidated report type
export const OVERLAPS = 'overlaps';
export const PIPELINE = 'pipeline';
export const ECOSYSTEM = 'ecosystem';
export const OWN_GREENFIELD = 'own_greenfield';
export const PARTNER_GREENFIELD = 'partner_greenfield';

export const GREENFIELD_CONSOLIDATED_TYPES = [
  OWN_GREENFIELD,
  PARTNER_GREENFIELD,
];

export const OWN_GREENFIELD_CROSSBEAM_COLUMNS = [
  DEALS_AMOUNT,
  DEALS_COUNT,
  POPULATIONS,
  PARTNERSTACK,
];
export const PARTNER_GREENFIELD_CROSSBEAM_COLUMNS = [PARTNER_POPULATIONS];

export const SINGLE_OVERLAPS_TYPE = 'single-partner-overlaps';
export const GREENFIELD_TYPE = 'single-partner-greenfield';
export const POTENTIAL_REVENUE_TYPE = 'single-partner-oppty';
export const ALL_PARTNERS_TYPE = 'standard';
export const CUSTOM_TYPE = 'custom';
export const PARTNER_TAGS_TYPE = 'partner-tags';

export const TYPES = [
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  GREENFIELD_TYPE,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
  SINGLE_OVERLAPS_TYPE,
];

export const OWN_ACCOUNT_EXEC = 'own-account-exec';
export const PARTNER_ACCOUNT_EXEC = 'partner-account-exec';

export const COMPATIBLE_DEFAULT_COLS = {
  [SINGLE_OVERLAPS_TYPE]: [
    OWN_ACCOUNT_EXEC,
    PARTNER_ACCOUNT_EXEC,
    OVERLAP_TIME,
    PARTNERSTACK,
    POPULATIONS,
    PARTNER_POPULATIONS,
  ],
  [POTENTIAL_REVENUE_TYPE]: [
    OWN_ACCOUNT_EXEC,
    PARTNER_ACCOUNT_EXEC,
    OVERLAP_TIME,
    PARTNERSTACK,
    POPULATIONS,
    PARTNER_POPULATIONS,
  ],
  [ALL_PARTNERS_TYPE]: [
    PARTNER_IMPACT,
    OVERLAPS_WITH,
    OWN_ACCOUNT_EXEC,
    PARTNER_ACCOUNT_EXEC,
    OVERLAP_TIME,
    PARTNERSTACK,
    POPULATIONS,
    PARTNER_POPULATIONS,
  ],
  [PARTNER_TAGS_TYPE]: [
    OVERLAPS_WITH,
    OWN_ACCOUNT_EXEC,
    PARTNER_ACCOUNT_EXEC,
    OVERLAP_TIME,
    PARTNERSTACK,
    POPULATIONS,
    PARTNER_POPULATIONS,
  ],
  [CUSTOM_TYPE]: [
    OVERLAPS_WITH,
    OWN_ACCOUNT_EXEC,
    PARTNER_ACCOUNT_EXEC,
    OVERLAP_TIME,
    PARTNERSTACK,
    POPULATIONS,
    PARTNER_POPULATIONS,
  ],
  [`${GREENFIELD_TYPE}__${OWN_GREENFIELD}`]: [
    OWN_ACCOUNT_EXEC,
    PARTNERSTACK,
    POPULATIONS,
  ],
  [`${GREENFIELD_TYPE}__${PARTNER_GREENFIELD}`]: [
    PARTNER_ACCOUNT_EXEC,
    PARTNER_POPULATIONS,
  ],
};

// report types
export const REPORT_TYPES = [
  {
    backgroundColor: 'bg-info-background-weak',
    description:
      'Get actionable insights about the state of individual partnerships',
    displayName: 'Single Partner',
    multiPartner: false,
    icon: ['fak', 'artificial-intelligence'],
    iconColor: 'text-warning-accent',
    type: SINGLE_OVERLAPS_TYPE,
    viewName: TOTAL_OVERLAPS,
  },
  {
    backgroundColor: 'bg-upsell-background-weak',
    description:
      'Leverage Potential Revenue to uncover the highest ROI opportunities',
    displayName: 'Pipeline',
    multiPartner: false,
    icon: ['fak', 'revenue'],
    iconColor: 'text-upsell-accent',
    requiresUpgrade: true,
    type: POTENTIAL_REVENUE_TYPE,
    viewName: POTENTIAL_REVENUE,
  },
  {
    backgroundColor: 'bg-success-background-weak',
    description: 'Discover all non-overlapping accounts with partners',
    displayName: 'Greenfield',
    multiPartner: false,
    icon: ['fak', 'greenfield'],
    iconColor: 'text-success-accent',
    requiresUpgrade: true,
    type: GREENFIELD_TYPE,
  },
  {
    backgroundColor: 'bg-info-background-weak',
    description: 'Track and measure insights by partner tag categories',
    displayName: 'Partner Tag',
    multiPartner: true,
    icon: ['fak', 'tag'],
    iconColor: 'text-info-accent',
    requiresUpgrade: true,
    tagText: 'Multi-Partner',
    type: PARTNER_TAGS_TYPE,
  },
  {
    backgroundColor: 'bg-info-background-weak',
    description:
      'See a 360-degree ecosystem landscape with an all-partners view',
    displayName: 'Ecosystem',
    multiPartner: true,
    icon: ['fak', 'team'],
    iconColor: 'text-info-accent',
    requiresUpgrade: true,
    tagText: 'All Partner',
    type: ALL_PARTNERS_TYPE,
  },
  {
    backgroundColor: 'bg-info-background-weak',
    description: 'Slice and dice your data with a custom build',
    displayName: 'Custom',
    multiPartner: true,
    icon: ['fak', 'wrench'],
    iconColor: 'text-neutral-accent',
    requiresUpgrade: true,
    tagText: 'Multi-Partner',
    type: CUSTOM_TYPE,
  },
];

export const MULTI_PARTNER_REPORT_TYPES = [
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  PARTNER_TAGS_TYPE,
];
export const MULTI_POPULATION_REPORT_TYPES = [
  ...MULTI_PARTNER_REPORT_TYPES,
  GREENFIELD_TYPE,
];

export const FREE_CROSSBEAM_COLUMNS = [
  OVERLAP_TIME,
  OVERLAPS_WITH,
  POPULATIONS,
  PARTNER_POPULATIONS,
];

// metrics consts
export const TOP_OPEN_OPPORTUNITIES = 'top_open_opportunities';
export const TOP_CUSTOMERS = 'top_customers';
export const TOP_DEAL_SIZE_MULTIPLIER = 'top_deal_size_multiplier';
export const TOP_WIN_RATE_MULTIPLIER = 'top_win_rate_multiplier';
export const TOP_TIME_TO_CLOSE_MULTIPLIER = 'top_time_to_close_multiplier';

export const PERCENT_MULTIPLIERS = [
  TOP_DEAL_SIZE_MULTIPLIER,
  TOP_WIN_RATE_MULTIPLIER,
  TOP_TIME_TO_CLOSE_MULTIPLIER,
];
export const METRIC_ORDER = [
  TOP_OPEN_OPPORTUNITIES,
  TOP_CUSTOMERS,
  TOP_WIN_RATE_MULTIPLIER,
  TOP_DEAL_SIZE_MULTIPLIER,
  TOP_TIME_TO_CLOSE_MULTIPLIER,
];

// partner impact
export const EXPORT_IMPACT_TO_NAME_LOOKUP = {
  [PARTNER_SCORES.HIGH]: '(High Impact)',
  [PARTNER_SCORES.MEDIUM]: '(Medium Impact)',
  [PARTNER_SCORES.LOW]: '(Low Impact)',
  [PARTNER_SCORES.NONE]: '(No Impact)',
};

// error states
export const INVALID_OWN_FILTERS = 'own_filters_changed';
export const INVALID_PARTNER_FILTERS = 'partner_filters_changed';
export const NO_OWN_POPULATIONS = 'no_own_populations';
export const NO_PARTNER_POPULATIONS = 'no_partner_populations';
