<template>
  <BittsModalTwo
    variant="data"
    :show-close-button="true"
    :width="968"
    container-class="c-billing-plan-comparison-modal"
    :hide-footer="true"
    @closed="closeModal"
  >
    <template #header>
      <div>
        <p class="text-neutral-text-strong font-bold text-xxl pb-8">
          {{ infoMappedToCTA().title }}
        </p>
        <p class="text-neutral-text-weak text-base">
          {{ infoMappedToCTA().description }}
        </p>
      </div>
    </template>
    <div class="pt-16 pb-20">
      <BittsSegment
        class="mb-24"
        :tabs
        variant="segmented_control"
        @change:segment="pickPeriod"
      >
        <template #post-title="{ id }">
          <span v-if="id === 'year'" class="text-success-text font-normal">
            (Save 10%)
          </span>
          <span v-else class="text-neutral-text-weak font-normal">
            (Requires annual commitment)
          </span>
        </template>
      </BittsSegment>
      <div class="flex gap-24">
        <BillingPlanComparisonCard
          tier="connector"
          @action="onUpgrade"
          :period="pickedPeriod"
          :highlight="billingInteraction.cta"
        />
        <BillingPlanComparisonCard
          tier="supernode"
          @action="onTalkToSales"
          :period="pickedPeriod"
        />
      </div>
    </div>
  </BittsModalTwo>
</template>

<script setup lang="ts">
import { BittsModalTwo, BittsSegment } from '@crossbeam/bitts';
import {
  ACCOUNT_MAPPING_CTAS,
  EVENT_SITES,
  INTEGRATIONS_CTAS,
  PARTNER_CTAS,
  PLAN_AND_BILLING_CTAS,
  POPULATION_CTAS,
  SHARED_LIST_CTAS,
  TEAM_CTAS,
} from '@crossbeam/itly';

import { computed, ref } from 'vue';

import BillingPlanComparisonCard from '@/components/billing/BillingPlanComparisonCard.vue';

import useBilling from '@/composables/useBilling';
import {
  ALL_PARTNERS_TYPE,
  CUSTOM_TYPE,
  GREENFIELD_TYPE,
  PARTNER_TAGS_TYPE,
  POTENTIAL_REVENUE_TYPE,
} from '@/constants/reports';
import {
  BillingInteraction,
  BillingPeriod,
  BillingQuery,
} from '@/types/billing';

const { billingInteraction, billingQuery = null } = defineProps<{
  billingInteraction: BillingInteraction;
  billingQuery: BillingQuery | null;
}>();

const emit = defineEmits<(e: 'close-modal') => void>();
const { handleBillingInteraction } = useBilling();

const tabs = computed(() => [
  {
    title: '1 annual payment',
    'post-title': '(Save 10%)',
    id: BillingPeriod.Year,
  },
  {
    title: '12 monthly payments',
    'post-title': '(Requires annual commitment)',
    id: BillingPeriod.Month,
  },
]);

const pickedPeriod = ref<BillingPeriod>(BillingPeriod.Year);
function pickPeriod(value: BillingPeriod) {
  pickedPeriod.value = value;
}

function closeModal() {
  emit('close-modal');
}

function onUpgrade() {
  const interactionData = {
    ...billingInteraction,
    period: pickedPeriod.value,
  };

  if (billingQuery) {
    handleBillingInteraction(interactionData, billingQuery, true);
  } else {
    handleBillingInteraction(interactionData, null, true);
  }
  closeModal();
}

function onTalkToSales() {
  closeModal();
  handleBillingInteraction(
    {
      talkToSalesReason: 'Upgrade to Supernode',
      cta: PLAN_AND_BILLING_CTAS.BILLING,
      event_site: EVENT_SITES.SELF_SERVE_FLOW_COMPARISON_MODAL,
    },
    null,
    true,
  );
}

function infoMappedToCTA() {
  switch (billingInteraction.cta) {
    case ACCOUNT_MAPPING_CTAS[`${POTENTIAL_REVENUE_TYPE}_COLUMNS`]:
      return {
        title: 'Access Opportunities in reports',
        description: ` Upgrade to integrate opportunity data into your reports`,
      };
    case ACCOUNT_MAPPING_CTAS[POTENTIAL_REVENUE_TYPE]:
      return {
        title: 'Create Pipeline Reports',
        description:
          'Upgrade to unlock Pipeline Reports—spot high-potential accounts and focus on deals that drive revenue',
      };
    case ACCOUNT_MAPPING_CTAS[GREENFIELD_TYPE]:
      return {
        title: 'Create Greenfield Reports',
        description:
          'Upgrade to unlock Greenfield Reports and discover untapped opportunities',
      };
    case ACCOUNT_MAPPING_CTAS[PARTNER_TAGS_TYPE]:
      return {
        title: 'Create Partner Tag Reports',
        description:
          'Upgrade to easily segment reports based on created Partner Tags',
      };
    case ACCOUNT_MAPPING_CTAS[ALL_PARTNERS_TYPE]:
      return {
        title: 'Create Ecosystem Reports',
        description:
          'Upgrade to get a 360-degree view of your entire partner network across any Population',
      };
    case ACCOUNT_MAPPING_CTAS[CUSTOM_TYPE]:
      return {
        title: 'Create Ecosystem Reports',
        description: 'Upgrade to tailor your ecosystem data to meet your needs',
      };
    case ACCOUNT_MAPPING_CTAS.DO_MORE_WITH_REPORTS:
    case ACCOUNT_MAPPING_CTAS.REPORTS:
      return {
        title: 'Unlock additional reporting functionality',
        description:
          'Upgrade to save, export, and  enable notifications for your team',
      };
    case ACCOUNT_MAPPING_CTAS.REPORT_FOLDERS:
    case ACCOUNT_MAPPING_CTAS.SAVED_REPORTS_ACCESS:
      return {
        title: 'Access Saved Reports',
        description: 'Upgrade to save and revisit your reports anytime',
      };
    case PARTNER_CTAS.OFFLINE_PARTNERS:
      return {
        title: 'Unlock unlimited Offline Partners',
        description:
          'Upgrade for unlimited Offline Partners to map accounts and uncover new opportunities',
      };
    case PARTNER_CTAS.POTENTIAL_REVENUE:
      return {
        title: 'Access Potential Revenue',
        description:
          'Upgrade to quantify overlaps and focus your efforts where they matter most',
      };
    case PARTNER_CTAS.ECOSYSTEM_INSIGHTS:
      return {
        title: 'Access Partner Impact metrics',
        description:
          'Upgrade to access ecosystem insights to help prioritize accounts, uncover opportunities, and drive revenue',
      };
    case POPULATION_CTAS.CUSTOM_POPULATIONS:
      return {
        title: 'Unlock custom segments with Custom Populations',
        description:
          'Upgrade to create custom segments tailored to your data for precise targeting and deeper insights',
      };
    case ACCOUNT_MAPPING_CTAS.SHARED_LISTS: {
      return {
        title: 'Unlock additional reporting functionality',
        description:
          'Upgrade to add your record to an existing list for collaboration',
      };
    }
    case SHARED_LIST_CTAS.SHARED_LISTS:
    case PARTNER_CTAS.SHARED_LISTS:
      return {
        title: 'Access Shared Lists',
        description:
          'Upgrade to align teams and drive collaboration with Shared Lists',
      };
    case INTEGRATIONS_CTAS.INTEGRATIONS:
      return {
        title: 'Access key integrations',
        description:
          "Upgrade to get your ecosystem data wherever it's needed with our powerful set of integrations",
      };
    case INTEGRATIONS_CTAS.COPILOT_FOR_SALESFORCE:
      return {
        title: 'Get Crossbeam Copilot for Salesforce',
        description:
          'Upgrade to give your sales team access to ecosystem insights right within Salesforce',
      };
    case INTEGRATIONS_CTAS.COPILOT_FOR_CHROME:
      return {
        title: 'Get Crossbeam Copilot for Chrome',
        description:
          'Upgrade to give your sales team access to ecosystem insights right within Chrome',
      };
    case INTEGRATIONS_CTAS.COPILOT_FOR_OUTREACH:
      return {
        title: 'Get Crossbeam Copilot for Outreach',
        description:
          'Upgrade to give your sales team access to ecosystem insights right within Outreach',
      };
    case INTEGRATIONS_CTAS.COPILOT_FOR_HUBSPOT:
      return {
        title: 'Get Crossbeam Copilot for HubSpot',
        description:
          'Upgrade to give your sales team access to ecosystem insights right within HubSpot',
      };
    case TEAM_CTAS.AT_SEAT_LIMIT:
    case TEAM_CTAS.MORE_SEATS:
    case TEAM_CTAS.FREE_NEEDS_MORE_SEATS:
    case TEAM_CTAS.ACCEPT_INVITE_REQUESTS:
    case PLAN_AND_BILLING_CTAS.AT_SEAT_LIMIT:
      return {
        title: 'Add more teammates',
        description:
          'Upgrade to add seats so you can scale co-selling and grow revenue through your ecosystem',
      };
    default:
      return {
        title: 'Unlock more with our Connector plan',
        description: 'Activate your partner ecosystem & boost GTM efficiencies',
      };
  }
}
</script>

<style lang="pcss">
.c-billing-plan-comparison-modal {
  .o-bitts-modal__header {
    @apply border-none;
  }
}
</style>
