<template>
  <div class="login-page my-48 sm:my-0 mx-auto max-w-[400px]">
    <div class="flex flex-col gap-24 items-center justify-center">
      <div class="login-illustration">
        <FontAwesomeIcon
          class="m-auto w-full"
          :icon="['fad', 'sign-in']"
          :style="{ height: '50px', width: '50px' }"
        />
      </div>
      <span class="text-center text-lg font-bold text-neutral-text-strong">
        Welcome!<br />Login to start using Crossbeam</span
      >
      <div class="flex flex-col gap-12 w-full items-center">
        <IframeDefaultLogin
          v-if="!useSSO"
          :toggle-sso="toggleUseSSO"
          :popup-open="disableButton"
          :login="login"
        />
        <IframeSSOLogin v-else :toggle-sso="toggleUseSSO" :login="login" />
        <BittsLink
          v-if="isSafari"
          text="How to log in with Safari?"
          url="safari_login_help"
          data-testid="safari-login-help"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { BittsLink } from '@crossbeam/bitts';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useHead } from '@unhead/vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import appConfig from '@/config';
import { ls } from '@/local_storage';
import urls from '@/urls';

import IframeDefaultLogin from './IframeDefaultLogin.vue';
import IframeSSOLogin from './IframeSSOLogin.vue';

const route = useRoute();
const useSSO = ref(false);

const ensureNext = () => {
  /* Set next */
  ls.nextUrl.set(route.query.next ?? '');
  ls.newSession.set(true);
};

const toggleUseSSO = () => {
  useSSO.value = !useSSO.value;
};

const popup = ref(null);
const popupIsOpen = ref(false);
const disableButton = ref(false);

const isLoginPopupQueryStr = 'isLoginPopup=1';

const login = (xbLoginLink) => {
  const origin = `${xbLoginLink}${isLoginPopupQueryStr}`;
  const h = 600;
  const w = 400;
  const y = window.outerHeight / 2 + window.screenY - h / 2;
  const x = window.outerWidth / 2 + window.screenX - w / 2;
  const windowFeats = `popup=true, width=${w}, height=${h}, top=${y}, left=${x}, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, copyhistory=no`;
  popup.value = window.open(origin, 'popup', windowFeats);
  popupIsOpen.value = true;
  disableButton.value = true;

  window.addEventListener('message', (e) => {
    if (e?.data?.type !== 'code') return;
    const nextUrl = `${appConfig.apiBaseUrl}${urls.session.callback}${e.data.code}&redirect_uri=https://${appConfig.host}`;
    popup.value.close();
    popupIsOpen.value = false;
    // Only set `next` after popup is closed, to ensure popup can't consume next from storage.
    // This causes the iframe to lose the next url and just load app.crossbeam.com in some instances.
    // Also, if set it before the oauth-redirect condition (this block) then we could accidentally set it for the main app because we aren't ready to consume it.
    // Causing app.crossbeam.com to redirect to a /copilot-view route. Not good.
    // TLDR; Calling it only here ensures the redirect happens and ensures that the main app is not likely to be affected.

    ensureNext();

    window.location.replace(nextUrl);

    window.parent.postMessage({ type: 'logged_in' }, '*');

    setTimeout(() => {
      disableButton.value = false;
    }, 1000);
  });
};

useHead({
  title: 'Login - Crossbeam',
});

const isSafari = computed(
  () =>
    window.safari !== undefined ||
    (navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome')),
);
</script>

<style lang="pcss" scoped>
.login-illustration {
  @apply flex items-center rounded-full h-100 w-100 text-info-accent bg-top-gradient from-info-accent/30 to-info-accent/0;
}
.full-centered {
  @apply w-full flex flex-col items-center gap-12;
}
</style>
